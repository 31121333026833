import ContentIcon from "../../assets/icons/ContentIcon";
import InviteIcon from "../../assets/icons/InviteIcon";
import FeedIcon from "../../assets/icons/FeedIcon";
import DesignerPageIcon from "../../assets/icons/DesignerPageIcon";
import GenerativeIcon from "../../assets/icons/GenerativeIcon";
import BoardsIcon from "../../assets/icons/BoardsIcon";
import TicketIcon from "../../assets/icons/TicketIcon";
import NewsFeedIcon from "../../assets/icons/NewsFeedIcon";
import TrendSnapshotIcon from "../../assets/icons/TrendSnapshotIcon";
const LogoMapping = {
  Content: ContentIcon,
  Invite: InviteIcon,
  Feed: FeedIcon,
  DesignerPage: DesignerPageIcon,
  Generative: GenerativeIcon,
  Boards_invited: BoardsIcon,
  "Ticket_content-reported": TicketIcon,
  "Boards_user-role-change": BoardsIcon,
  NewsFeed: NewsFeedIcon,
  Snapshot_shared: TrendSnapshotIcon,
};

export default LogoMapping;
