import React from "react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function CrossSnapshotIcon(props: any) {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.00683 10.1152L12.048 14.1518C12.2624 14.366 12.5533 14.4863 12.8566 14.4863C13.1598 14.4863 13.4507 14.366 13.6651 14.1518C13.8795 13.9376 14 13.6471 14 13.3442C14 13.0413 13.8795 12.7508 13.6651 12.5366L9.62239 8.5L13.6643 4.46343C13.7705 4.35737 13.8546 4.23147 13.9121 4.09292C13.9695 3.95437 13.999 3.80588 13.999 3.65592C13.9989 3.50597 13.9693 3.35749 13.9118 3.21897C13.8544 3.08044 13.7701 2.95458 13.6639 2.84857C13.5578 2.74257 13.4317 2.65849 13.293 2.60113C13.1543 2.54378 13.0056 2.51428 12.8555 2.51432C12.7054 2.51435 12.5567 2.54392 12.4181 2.60134C12.2794 2.65876 12.1534 2.7429 12.0472 2.84895L8.00683 6.88552L3.96566 2.84895C3.86026 2.73985 3.73417 2.65281 3.59474 2.59291C3.45531 2.533 3.30533 2.50144 3.15355 2.50005C3.00177 2.49866 2.85124 2.52748 2.71073 2.58482C2.57023 2.64216 2.44256 2.72689 2.33518 2.83404C2.2278 2.94119 2.14287 3.06863 2.08533 3.20893C2.02778 3.34922 1.99879 3.49955 2.00004 3.65116C2.00129 3.80276 2.03275 3.9526 2.09259 4.09193C2.15243 4.23126 2.23945 4.35729 2.34858 4.46267L6.39128 8.5L2.34934 12.5373C2.24021 12.6427 2.15319 12.7687 2.09335 12.9081C2.03351 13.0474 2.00205 13.1972 2.0008 13.3488C1.99955 13.5004 2.02855 13.6508 2.08609 13.7911C2.14363 13.9314 2.22857 14.0588 2.33594 14.166C2.44332 14.2731 2.57099 14.3578 2.7115 14.4152C2.852 14.4725 3.00254 14.5013 3.15431 14.5C3.30609 14.4986 3.45607 14.467 3.5955 14.4071C3.73493 14.3472 3.86102 14.2601 3.96642 14.151L8.00683 10.1152Z"
        fill="#212121"
      />
    </svg>
  );
}

export default CrossSnapshotIcon;
