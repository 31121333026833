import React from "react";
import moment from "moment";
import { ReactComponent as ExpandSnapshot } from "../../../assets/icons/snapshotExpandedView.svg";
import { useNavigate } from "react-router-dom";
import { capitalizeFirstLetter } from "../../../utils";

interface SnapshotList {
  id: number;
  name: string;
  ownerId: string;
  owner_name: string;
  generic_filters: {
    preferenceFilters: {
      gender?: string;
      source?: string;
      startDate?: string;
      endDate?: string;
      region?: string;
      genre?: string;
      product?: string;
      brands?: string[];
      category?: string;
      color?: string;
    };
    trendAnalysisFilters: {
      material: string;
      product_type: string;
      styling_details: string;
    };
  };
  images: string[];
  trendsCount: number;
  sharedCount: number;
  created_at: string;
  updatedAt: string;
}
interface SnapshotCardProps {
  data: SnapshotList;
  getImageLink: (image: string) => string;
}

const SnapshotCard: React.FC<SnapshotCardProps> = ({ data, getImageLink }) => {
  const navigate = useNavigate();

  const handleNavigate = (id: number) => {
    navigate(`/trend-snapshot/details/${id}`);
  };
  return (
    <div
      key={data.id}
      className="w-[170px] h-[275px] border border-light rounded-md cursor-pointer relative"
      onClick={() => {
        handleNavigate(data?.id);
      }}
    >
      <div className="h-[172px] w-full flex gap-2">
        <div className="h-full w-[108px]">
          <img
            src={getImageLink(data?.images?.[0] || "")}
            className="h-full w-full rounded-lg object-cover"
            alt="Snapshot"
          />
        </div>
        <div className="flex flex-col gap-2 h-[168px] w-[60px] overflow-hidden">
          {data?.images?.slice(1, 4)?.map((image, idx) => (
            <div key={idx} className="h-[56px]">
              <img
                src={getImageLink(image)}
                className="rounded-md object-cover h-full w-full"
                alt={`Snapshot ${idx + 1}`}
              />
            </div>
          ))}
        </div>
      </div>
      <div className="w-full px-2">
        <div className="text-[14px] font-poppins font-bold truncate">
          {capitalizeFirstLetter(data?.name) || "N/A"}
        </div>
        <div className="text-[12px] font-poppins truncate">
          Gender: {data?.generic_filters?.preferenceFilters?.gender}
        </div>
        <div className="text-[12px] font-poppins truncate">
          Category:{" "}
          {data?.generic_filters?.preferenceFilters?.category || "N/A"}
        </div>
        <div className="text-[12px] font-poppins truncate">
          Owner: {data?.owner_name || "N/A"}
        </div>
        <div className="text-[12px] font-poppins text-tertiary">
          Date:{" "}
          {data?.created_at
            ? moment(data?.created_at).format("DD-MM-YYYY")
            : "N/A"}
        </div>
      </div>
      <div className="absolute bottom-2 right-2">
        <ExpandSnapshot />
      </div>
    </div>
  );
};

export default SnapshotCard;
