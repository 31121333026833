import React, { useEffect, useRef, useState } from "react";
import ShareSnapshotIcon from "../../../assets/icons/ShareSnapshotIcon";
import DeleteSnapshotIcon from "../../../assets/icons/DeleteSnapshotIcon";
import DownloadSnapshotIcon from "../../../assets/icons/DownloadSnapshotIcon";
import CrossSnapshotIcon from "../../../assets/icons/CrossSnapshotIcon";
import Preferences from "../Preferences";
import TrendsSidebar from "../../DesignerPage/TrendsSidebar/TrendsSidebar";
import Skeleton from "react-loading-skeleton";
import Trends from "../Trends/Trends";
import InstaPost from "../InstaPost";
import {
  brandsParams,
  FeedItem,
  GetBrandsParams,
} from "../../../interface/DesignerInterface";
import DeleteSnapshotModal from "./DeleteSnapshotModal";
import { useNavigate, useParams } from "react-router-dom";
import SnapshotShareModal from "./SnapshotShareModal";
import { getSnapshotDetails } from "../../../app/api/trendSnapshot";
import { useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import {
  PreferenceFilters,
  snapshotDataInterface,
} from "../../../interface/SnapshotInterface";
import moment from "moment";
import { object, string } from "yup";
import Pagination from "../../Target/Pagination";
import { getStylingDetails } from "../../../app/api/trendsFilter";
import { AxiosError } from "axios";
import { toast } from "react-toastify";
import {
  defaultMaterials,
  postsPerTrend,
  TrendsPerPage,
} from "../../../constants/constants";
import { getBrandsData } from "../../../app/api/designerPage";
import { Board } from "../../../interface/BoardsInterface";
import { getUserId } from "../../../app/api/user/user";
import { getBoardsByUserId } from "../../../app/api/admin/board";
import { capitalizeFirstLetter } from "../../../utils";
interface Props {
  isSidebarOpen: boolean;
  trendsOpen: boolean;
  setTrendsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const posts: FeedItem[] = [];
const TrendSnapshotDetails: React.FC<Props> = ({
  isSidebarOpen,
  trendsOpen,
  setTrendsOpen,
}) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [isBrandsModalOpen, setBrandsModalOpen] = useState(false);
  const closeBrandsModal = () => setBrandsModalOpen(false);

  const scrollRef = useRef<HTMLDivElement | null>(null);
  const [analysisResultSuccess, setAnalysisResultSuccess] =
    useState<boolean>(true);
  const [deleteWarningModal, setDeleteWarningModal] = useState<boolean>(false);
  const [shareSnapshotModal, setShareSnapshotModal] = useState<boolean>(false);
  const [snapshotDetails, setSnapshotDetails] = useState<snapshotDataInterface>(
    {} as snapshotDataInterface
  );
  const [snapshotName, setSnapshotName] = useState<string>("");
  const [ownerName, setOwnerName] = useState<string>("");
  const [brandsList, setBrandsList] = useState<{
    [key: string]: brandsParams[];
  }>({});
  const [preferences, setPreferences] = useState<PreferenceFilters>({
    startDate: "",
    endDate: "",
    region: "",
    genre: "",
    product: "",
    gender: "",
    brands: [],
    source: "",
    category: "",
    color: "",
    ageGroup: "",
  });
  const [trendAnalysisFilters, setTrendAnalysisFilters] = useState({
    product_type: "",
    material: "",
    styling_details: "",
  });
  const [trendsTotalPages, setTrendsTotalPages] = useState(1);
  const [stylingDetails, setStylingDetails] = useState<string[]>([]);
  const user = useSelector((state: RootState) => state.user.userInfo);
  const userEmail = user?.email;
  const [userId, setUserId] = useState<string>("");
  const [trendsPage, setTrendsPage] = useState<number>(1);
  const [ownerId, setOwnerId] = useState<string>("");
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);

  //Board

  const [boardsList, setBoardsList] = useState<Board[]>([]);
  const [publicBoards, setPublicBoards] = useState<Board[]>([]);
  const [privateBoards, setPrivateBoards] = useState<Board[]>([]);

  // Snapshot deleted
  const [isDeleted, setIsDeleted] = useState<boolean>(false);

  const onScrollItems = () => {};
  const navigate = useNavigate();
  const handleDeleteSnapshot = () => {
    setDeleteWarningModal(true);
  };
  const handleGoBack = () => {
    navigate(-1);
  };
  const handleShareSnapshot = () => {
    setShareSnapshotModal(true);
  };

  const handleSidebarToggle = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const handleTrendsToggle = () => {
    setTrendsOpen(!trendsOpen);
  };
  const { id } = useParams();
  const numericSnapshotId = Number(id);
  const fetchDetails = async (page: number) => {
    const detailsParams = {
      page: page,
      numberOfTrends: TrendsPerPage,
      postsPerTrend: postsPerTrend,
    };
    try {
      setLoading(true);
      const snapDetails = await getSnapshotDetails(
        numericSnapshotId,
        user,
        detailsParams
      );
      setSnapshotDetails(snapDetails.data);
      setSnapshotName(snapDetails.data.snapshot.name);
      setOwnerName(snapDetails.data.snapshot.ownerName);
      setOwnerId(snapDetails.data.snapshot.ownerId);
      setTrendsTotalPages(
        Math.ceil(snapDetails.data.snapshot.trendsCount / TrendsPerPage)
      );

      setPreferences(
        snapDetails.data.snapshot.genericFilters.preferenceFilters
      );

      setTrendAnalysisFilters(
        snapDetails.data.snapshot.genericFilters.trendAnalysisFilters
      );
    } catch (error) {
      console.error("Error fetching snapshot details:", error);
      setIsDeleted(true);
    } finally {
      setLoading(false);
    }
  };
  const handleTrendsPageChange = (page: number) => {
    setTrendsPage(page);
  };
  useEffect(() => {
    if (Object.keys(preferences).length !== 0) {
      const params: GetBrandsParams = {};
      if (preferences.gender) params.gender = preferences.gender;
      if (preferences.genre) params.genre = preferences.genre;
      if (preferences.source) params.source = preferences.source;
      if (preferences.region) params.region = preferences.region;
      if (preferences.product) params.product = preferences.product;
      if (preferences.category) params.category = preferences.category;
      if (preferences.ageGroup) params.ageGroup = preferences.ageGroup;
      getBrandsData(params).then(res => {
        setBrandsList(res);
      });
    }
  }, [preferences]);

  //Board
  const getUserByEmail = async () => {
    if (userEmail) {
      const res = await getUserId(userEmail);
      setUserId(res?.data?.data[0]._id);
    }
  };

  const getBoardsByUser = async () => {
    if (userId) {
      const res = await getBoardsByUserId(userId);
      setPublicBoards(res?.data?.data.public.boards);
      setPrivateBoards(res?.data?.data.private.boards);
      setBoardsList(res?.data?.data);
    }
  };

  useEffect(() => {
    getUserByEmail();
    getBoardsByUser();
  }, [userId]);
  useEffect(() => {
    const getStylingDetail = async () => {
      try {
        const params = {
          category: preferences.category,
          gender: preferences.gender,
          productType: trendAnalysisFilters.product_type,
        };
        const response = await getStylingDetails(params, user);
        setStylingDetails(response.data);
      } catch (error) {
        console.error("Error Fetching Styling Details", error);
        if ((error as AxiosError)?.response?.status !== 400) {
          toast.error("Error Fetching Styling Details", {
            hideProgressBar: true,
          });
        }
      }
    };

    if (
      preferences.category &&
      preferences.gender &&
      trendAnalysisFilters.product_type &&
      (preferences.category === "Ethnic RTW" ||
        preferences.category === "Western RTW")
    ) {
      getStylingDetail();
    }
  }, [trendAnalysisFilters.product_type]);

  useEffect(() => {
    fetchDetails(trendsPage);
  }, [trendsPage]);

  if (isDeleted) {
    return (
      <div className="flex justify-center items-center h-[calc(100vh-80px)] font-poppins font-semibold ">
        This Snapshot has been deleted by the owner.
      </div>
    );
  }

  return (
    <div
      className={`flex flex-col h-[calc(100vh-80px)] ${!isSidebarOpen ? "w-[calc(100vw-265px)]" : "w-[calc(100vw-105px)]"} transition-all duration-500 ease-in-out fixed ml-1 bg-dashboard`}
    >
      <div className="flex justify-between items-center px-3 py-4 font-poppins">
        <div className="flex justify-center items-center gap-1">
          <div className="text-primary font-bold text-[24px]">
            {capitalizeFirstLetter(snapshotName)}
          </div>
          <div className="text-tertiary text-[16px]">
            |{" "}
            {moment(snapshotDetails?.snapshot?.createdAt).format(
              "DD/MM/YYYY hh:mm a"
            )}
          </div>
          <div className="text-tertiary text-[16px]">| {ownerName}</div>
        </div>
        <div className="flex gap-4">
          <div className="cursor-pointer">
            <DownloadSnapshotIcon height={24} width={24} fill={"#212121"} />
          </div>
          <div className="cursor-pointer" onClick={handleShareSnapshot}>
            <ShareSnapshotIcon height={24} width={24} fill={"#212121"} />
          </div>
          {ownerId === userId && (
            <div className="cursor-pointer" onClick={handleDeleteSnapshot}>
              <DeleteSnapshotIcon height={24} width={24} />
            </div>
          )}
          <div className="cursor-pointer" onClick={handleGoBack}>
            <CrossSnapshotIcon height={24} width={24} />
          </div>
        </div>
      </div>
      <div className="flex flex-1 overflow-hidden">
        <Preferences
          startDate={preferences?.startDate}
          endDate={preferences?.endDate}
          isCollapsed={sidebarOpen}
          onToggle={handleSidebarToggle}
          setGenre={() => {}}
          genre={preferences?.genre}
          setProduct={() => {}}
          product={preferences?.product}
          setGender={() => {}}
          gender={preferences?.gender}
          ageGroup={preferences?.ageGroup}
          setAgeGroup={() => {}}
          setBrands={() => {}}
          brands={preferences?.brands}
          setStartDate={() => {}}
          setEndDate={() => {}}
          setRegion={() => {}}
          region={preferences?.region}
          genres={[]}
          regions={[]}
          sources={[]}
          source={preferences?.source}
          setSource={() => {}}
          brandsList={brandsList}
          productList={[]}
          color={preferences?.color}
          setColor={() => {}}
          categories={[]}
          category={preferences?.category}
          setCategory={() => {}}
          isBrandsModalOpen={isBrandsModalOpen}
          setBrandsModalOpen={setBrandsModalOpen}
          closeBrandsModal={closeBrandsModal}
          filterId={""}
          filterName={""}
          setFilterId={() => {}}
          setFilterName={() => {}}
          TrendSnapshotDisabled={true}
          TrendsSnapshotPreferencesView={true}
        />
        <div className="px-2 flex-1 bg-dashboard">
          <div className="flex justify-between">
            <div className=" h-10 bg-dashboard mb-2 rounded-lg flex  items-center">
              <button
                className="px-3 font-bold text-xs disabled:text-gray-400"
                disabled={true}
              >
                Check Archive
              </button>
              <button
                className="px-3 font-bold text-xs disabled:text-gray-400"
                onClick={() => {}}
                disabled={true}
              >
                Take Snapshot
              </button>
            </div>
            <div className="mr-8 flex justify-center items-center">
              <DownloadSnapshotIcon width={24} height={24} />
            </div>
          </div>
          <div
            className="h-[calc(100vh-200px)] overflow-auto bg-dashboard p-2.5 rounded-lg"
            ref={scrollRef}
            onScroll={onScrollItems}
          >
            <>
              {!loading ? (
                snapshotDetails?.trends?.length > 0 ? (
                  <>
                    <div
                      className={` overflow-y-scroll scroller ${trendsTotalPages > 1 ? "h-[calc(100%-50px)]" : "h-full"}`}
                    >
                      {snapshotDetails?.trends?.map((item, index) => {
                        return (
                          <Trends
                            key={index}
                            isSidebarOpen={isSidebarOpen}
                            sidebarOpen={sidebarOpen}
                            name={`Trend ${item.trendOrder}`}
                            selection={item.trendFilters}
                            trendsOpen={trendsOpen}
                            posts={item.posts}
                            privateBoards={privateBoards}
                            setPrivateBoards={setPrivateBoards}
                            publicBoards={publicBoards}
                            setPublicBoards={setPublicBoards}
                            userId={userId}
                            req_id={""}
                            trendSnapshotTrendsView={true}
                            snapshotId={Number(id)}
                            trendNo={item.trendOrder}
                          />
                        );
                      })}
                    </div>
                    <div
                      className={`mt-2 ${trendsTotalPages > 0 ? "block" : "hidden"}`}
                    >
                      <Pagination
                        currentPage={trendsPage}
                        totalPages={trendsTotalPages}
                        onPageChange={handleTrendsPageChange}
                      />
                    </div>
                  </>
                ) : (
                  <div className="font-poppins text-center mt-10 w-full">
                    No Trends Found
                  </div>
                )
              ) : (
                <div className="flex flex-wrap justify-around space-x-4 ">
                  {Array.from({ length: 12 }).map((_, index) => (
                    <div
                      key={index}
                      className="w-[240px] mt-4 p-0 pb-3 gap-2 border border-light rounded-2xl relative cursor-pointer"
                    >
                      <div className="relative w-full h-auto">
                        <Skeleton
                          height={200}
                          width={240}
                          className="rounded-t-2xl"
                        />
                        <div className="absolute top-0 right-0 bg-gray-300 text-white bg-gray81/50 rounded-bl-lg rounded-tr-2xl px-3 py-1 text-xs">
                          <Skeleton width={50} />
                        </div>
                        <div className="absolute bottom-2 right-2 flex space-x-1">
                          <div className="bg-white flex w-5 h-5 items-center justify-center rounded-full">
                            <Skeleton circle={true} height={20} width={20} />
                          </div>
                          <div className="bg-white flex w-5 h-5 items-center justify-center rounded-full">
                            <Skeleton circle={true} height={20} width={20} />
                          </div>
                          <div className="bg-white flex w-5 h-5 items-center justify-center rounded-full">
                            <Skeleton circle={true} height={20} width={20} />
                          </div>
                          <div className="bg-white flex w-5 h-5 items-center justify-center rounded-full">
                            <Skeleton circle={true} height={20} width={20} />
                          </div>
                        </div>
                      </div>
                      <div className="p-2 space-y-0.5">
                        <Skeleton width={100} />
                        <Skeleton width={150} />
                        <Skeleton width={120} />
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </>
          </div>
        </div>
        <TrendsSidebar
          isTrendOpen={trendsOpen}
          onTrendToggle={handleTrendsToggle}
          productType={[]}
          selectedProductType={trendAnalysisFilters.product_type}
          setSelectedProductType={() => {}}
          materials={defaultMaterials}
          selectedMaterial={trendAnalysisFilters.material.split(",")}
          setSelectedMaterial={() => {}}
          stylingDetails={stylingDetails}
          selectedStylingDetail={trendAnalysisFilters.styling_details.split(
            ","
          )}
          setSelectedStylingDetail={() => {}}
          runAnalysis={() => {}}
          setAnalysisResultSuccess={setAnalysisResultSuccess}
          refChange={() => {}}
          startDate={""}
          endDate={""}
          region={""}
          genre={""}
          product={""}
          gender={""}
          brands={[]}
          source={""}
          category={""}
          color={""}
          req_id={""}
          analysisResultSuccess={true}
          TrendsSidebarSnapshotExpandedView={true}
        />
      </div>
      {deleteWarningModal && (
        <DeleteSnapshotModal
          snapshotId={Number(id)}
          ownerId={ownerId}
          closeDeleteModal={() => {
            setDeleteWarningModal(false);
          }}
        />
      )}
      {shareSnapshotModal && (
        <SnapshotShareModal
          onCloseSnapshotShareModal={() => {
            setShareSnapshotModal(false);
          }}
          snapshotName={snapshotName}
          snapshotId={numericSnapshotId}
          ownerName={ownerName}
          ownerId={ownerId}
          postId={id}
        />
      )}
    </div>
  );
};

export default TrendSnapshotDetails;
