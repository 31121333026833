import React from "react";
import { deleteBoardById } from "../../app/api/admin/board";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { AxiosError } from "axios";

interface deleteBoardProps {
  onClose: () => void;
  boardName: string;
  boardId: string;
}
const DeleteBoard: React.FC<deleteBoardProps> = ({
  onClose,
  boardId,
  boardName,
}) => {
  const navigate = useNavigate();

  const handleDeleteBoard = async () => {
    try {
      await deleteBoardById(boardId);
      await toast.success("Successfully deleted the board", {
        hideProgressBar: true,
      });
      navigate("/boards");
    } catch (e) {
      if ((e as AxiosError).response?.status !== 400)
        toast.error("Error while deleting the board", {
          hideProgressBar: true,
        });
    }
  };
  return (
    <div className="flex items-center justify-center  bg-gray-100 w-[470px]">
      <div className="bg-white">
        <h2 className="text-2xl font-bold mb-2 text-primary">
          Are you sure you want to delete {boardName}?
        </h2>
        <p className="text-gray-600 mb-6">
          Once removed the content will be lost and cannot be recovered.
        </p>
        <div className="flex justify-center gap-x-8 ">
          <button
            className="bg-white text-tertiary font-bold border border-gray-300 rounded-full px-9 py-2"
            onClick={() => onClose()}
          >
            Cancel
          </button>
          <button
            className="bg-[#b71c1c] font-bold text-white rounded-full  px-9 py-2"
            onClick={handleDeleteBoard}
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteBoard;
