export const EVENTS_COLORS: { [key: string]: string } = {
  Festival: "#D5F5D5",
  Sports: "#FFC0CB",
  "fashion week": "#FFDAB9",
  "international holiday": "#E3E392",
  "movie release": "#DBC0D5",
  "wedding dates": "#F5F5DC",
  "n/a": "#DBC0D5",
};

export const EVENT_PERIODS = [
  { label: "J-F-M", months: [1, 2, 3] },
  { label: "A-M-J", months: [4, 5, 6] },
  { label: "J-A-S", months: [7, 8, 9] },
  { label: "O-N-D", months: [10, 11, 12] },
];

export const defaultMaterials = ["Pattern", "Embroidery", "Fabric"];

export const sidebarOpenWidth = 265;
export const sidebarClosedWidth = 105;
export const preferencesOpenWidth = 200;
export const preferencesClosedWidth = 32;
export const trendsOpenWidth = 450;
export const trendsClosedWidth = 32;
export const minSpaceBetweenItemsInCarousel = 10;
export const instaPostWidthWhenTrendsOpen = 158;
export const instaPostWidthWhenTrendsClosed = 240;
export const instaPostHeightWhenTrendsOpen = 260;
export const instaPostHeightWhenTrendsClosed = 390;

export const materialsForBackend = {
  // colour: { value: false, label: "Colour" },
  pattern: { value: false, label: "Pattern" },
  embroidery: { value: false, label: "Embroidery" },
  fabric: { value: false, label: "Fabric" },
};

export const stylingDetailsForBackend = {
  shapes: { value: false, label: "Shapes" },
  sleeves: { value: false, label: "Sleeve Details" },
  necklines_and_collars: { value: false, label: "Necklines & Collars" },
  hem_types: { value: false, label: "Hem Types" },
  length: { value: false, label: "Lengths" },
  pockets: { value: false, label: "Pockets" },
  cuffs: { value: false, label: "Cuffs" },
  details: { value: false, label: "Details" },
  placket: { value: false, label: "Placket Types" },
  waistband: { value: false, label: "Waistband" },
};

export const TrendsPerPage = 10;
export const postsPerTrend = 50;
