/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-useless-catch */

import axios from "axios";
import store from "../store";
import { clearUser } from "../slices/userSlice";

const BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;

const admin = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

const user = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

const forecaster = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

admin.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config;
    if (
      (error.response?.status === 401 || error.response?.status === 403) &&
      originalRequest &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      try {
        await admin.post(`${BASE_URL}auth/refresh-tokens`);
        return admin(originalRequest);
      } catch (err) {
        store.dispatch(clearUser());
        return Promise.reject(err);
      }
    }
    return Promise.reject(error);
  }
);

forecaster.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config;
    if (
      (error.response?.status === 401 || error.response?.status === 403) &&
      originalRequest &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      try {
        await forecaster.post(`${BASE_URL}auth/refresh-tokens`);
        return forecaster(originalRequest);
      } catch (err) {
        store.dispatch(clearUser());
        return Promise.reject(err);
      }
    }
    return Promise.reject(error);
  }
);

user.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config;
    if (
      (error.response?.status === 401 || error.response?.status === 403) &&
      originalRequest &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      try {
        await forecaster.post(`${BASE_URL}auth/refresh-tokens`);
        return forecaster(originalRequest);
      } catch (err) {
        store.dispatch(clearUser());
        return Promise.reject(err);
      }
    }
    return Promise.reject(error);
  }
);

const guest = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

export { admin, forecaster, guest, user };
