import React, { useState } from "react";
import Dropdown from "../Dropdown";
import {
  Chart as ChartJS,
  RadialLinearScale,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  PointElement,
  LineElement,
} from "chart.js";
import { Line } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import "chartjs-plugin-datalabels";

ChartJS.register(
  RadialLinearScale,
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  Title,
  ChartDataLabels,
  ChartDataLabels
);

interface TrendCurvesProps {
  analysisOpen?: boolean;
}
const TrendCurves: React.FC<TrendCurvesProps> = ({ analysisOpen }) => {
  const [filterType, setFilterType] = useState("Month on Month");

  const dummyFilterType = ["Month on Month", "Weak on Weak"];

  const lineGraphData = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],

    datasets: [
      {
        data: [12, 45, 78, 34, 56, 90, 67, 43, 21, 88, 56, 39],
        fill: false,
        borderColor: "#ff5733",
        tension: 0.1,
        borderWidth: 1,
        pointRadius: 0,
        pointHoverRadius: 2,
      },
      {
        data: [23, 56, 45, 89, 34, 12, 78, 90, 56, 34, 23, 45],
        fill: false,
        borderColor: "#33ff57",
        tension: 0.1,
        borderWidth: 1,
        pointRadius: 0,
        pointHoverRadius: 2,
      },
      {
        data: [45, 34, 23, 90, 78, 56, 12, 67, 89, 45, 34, 23],
        fill: false,
        borderColor: "#3357ff",
        tension: 0.1,
        borderWidth: 1,
        pointRadius: 0,
        pointHoverRadius: 2,
      },
      {
        data: [56, 78, 34, 23, 45, 90, 12, 56, 78, 34, 23, 56],
        fill: false,
        borderColor: "#ff33a6",
        tension: 0.1,
        borderWidth: 1,
        pointRadius: 0,
        pointHoverRadius: 2,
      },
      {
        data: [67, 12, 45, 56, 89, 23, 90, 34, 78, 56, 12, 67],
        fill: false,
        borderColor: "#a633ff",
        tension: 0.1,
        borderWidth: 1,
        pointRadius: 0,
        pointHoverRadius: 2,
      },
      {
        data: [12, 78, 56, 34, 45, 23, 67, 89, 12, 45, 78, 90],
        fill: false,
        borderColor: "#33ffa6",
        tension: 0.1,
        borderWidth: 1,
        pointRadius: 0,
        pointHoverRadius: 2,
      },
      {
        data: [78, 34, 56, 12, 89, 45, 23, 90, 67, 34, 12, 56],
        fill: false,
        borderColor: "#ff8333",
        tension: 0.1,
        borderWidth: 1,
        pointRadius: 0,
        pointHoverRadius: 2,
      },
      {
        data: [45, 67, 23, 56, 78, 90, 12, 34, 89, 45, 78, 23],
        fill: false,
        borderColor: "#3383ff",
        tension: 0.1,
        borderWidth: 1,
        pointRadius: 0,
        pointHoverRadius: 2,
      },
      {
        data: [23, 90, 78, 56, 45, 34, 12, 67, 89, 23, 56, 12],
        fill: false,
        borderColor: "#ff5733",
        tension: 0.1,
        borderWidth: 1,
        pointRadius: 0,
        pointHoverRadius: 2,
      },
      {
        data: [34, 12, 67, 45, 78, 56, 89, 23, 12, 34, 90, 45],
        fill: false,
        borderColor: "#57ff33",
        tension: 0.1,
        borderWidth: 1,
        pointRadius: 0,
        pointHoverRadius: 2,
      },
      {
        data: [90, 56, 12, 78, 45, 34, 67, 23, 45, 12, 89, 78],
        fill: false,
        borderColor: "#8333ff",
        tension: 0.1,
        borderWidth: 1,
        pointRadius: 0,
        pointHoverRadius: 2,
      },
      {
        data: [23, 67, 78, 90, 56, 12, 45, 89, 34, 23, 67, 78],
        fill: false,
        borderColor: "#ff33a6",
        tension: 0.1,
        borderWidth: 1,
        pointRadius: 0,
        pointHoverRadius: 2,
      },
      {
        data: [56, 34, 89, 12, 67, 45, 23, 90, 78, 56, 34, 12],
        fill: false,
        borderColor: "#33a6ff",
        tension: 0.1,
        borderWidth: 1,
        pointRadius: 0,
        pointHoverRadius: 2,
      },
      {
        data: [89, 45, 12, 78, 90, 56, 34, 23, 67, 89, 45, 12],
        fill: false,
        borderColor: "#ff3383",
        tension: 0.1,
        borderWidth: 1,
        pointRadius: 0,
        pointHoverRadius: 2,
      },
      {
        data: [12, 34, 23, 56, 90, 78, 45, 67, 12, 34, 89, 23],
        fill: false,
        borderColor: "#5733ff",
        tension: 0.1,
        borderWidth: 1,
        pointRadius: 0,
        pointHoverRadius: 2,
      },
      {
        data: [34, 56, 78, 45, 23, 89, 90, 12, 67, 34, 56, 78],
        fill: false,
        borderColor: "#a6ff33",
        tension: 0.1,
        borderWidth: 1,
        pointRadius: 0,
        pointHoverRadius: 2,
      },
      {
        data: [45, 90, 56, 78, 12, 34, 23, 67, 89, 45, 90, 56],
        fill: false,
        borderColor: "#3383ff",
        tension: 0.1,
        borderWidth: 1,
        pointRadius: 0,
        pointHoverRadius: 2,
      },
      {
        data: [23, 67, 78, 12, 90, 45, 56, 34, 89, 23, 67, 78],
        fill: false,
        borderColor: "#ff5733",
        tension: 0.1,
        borderWidth: 1,
        pointRadius: 0,
        pointHoverRadius: 2,
      },
      {
        data: [56, 12, 34, 90, 78, 23, 67, 89, 45, 56, 12, 34],
        fill: false,
        borderColor: "#ff8333",
        tension: 0.1,
        borderWidth: 1,
        pointRadius: 0,
        pointHoverRadius: 2,
      },
      {
        data: [12, 78, 45, 34, 56, 67, 90, 23, 12, 78, 45, 34],
        fill: false,
        borderColor: "#33ff57",
        tension: 0.1,
        borderWidth: 1,
        pointRadius: 0,
        pointHoverRadius: 2,
      },
      {
        data: [90, 34, 56, 23, 67, 78, 12, 45, 90, 34, 56, 23],
        fill: false,
        borderColor: "#ff3383",
        tension: 0.1,
        borderWidth: 1,
        pointRadius: 0,
        pointHoverRadius: 2,
      },
      {
        data: [78, 45, 12, 56, 90, 23, 67, 34, 78, 45, 12, 56],
        fill: false,
        borderColor: "#5733ff",
        tension: 0.1,
        borderWidth: 1,
        pointRadius: 0,
        pointHoverRadius: 2,
      },
      {
        data: [12, 90, 78, 34, 56, 23, 45, 89, 12, 90, 78, 34],
        fill: false,
        borderColor: "#a633ff",
        tension: 0.1,
        borderWidth: 1,
        pointRadius: 0,
        pointHoverRadius: 2,
      },
      {
        data: [56, 23, 90, 78, 12, 45, 67, 34, 56, 23, 90, 78],
        fill: false,
        borderColor: "#3383ff",
        tension: 0.1,
        borderWidth: 1,
        pointRadius: 0,
        pointHoverRadius: 2,
      },
      {
        data: [45, 78, 67, 34, 12, 23, 90, 56, 45, 78, 67, 34],
        fill: false,
        borderColor: "#ff5733",
        tension: 0.1,
        borderWidth: 1,
        pointRadius: 0,
        pointHoverRadius: 2,
      },
    ],
  };

  const lineOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      },
    },
    layout: {
      padding: {
        top: 10,
        bottom: 10,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        min: 0,
        max: 100,
        ticks: {
          stepSize: 25,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          callback: function (value: any) {
            return value;
          },
        },
      },
    },
  };
  return (
    <div className="mx-3">
      <div className="text-sm">Filter By</div>
      <Dropdown
        label={filterType || "Select type"}
        options={dummyFilterType}
        onSelect={option => {
          setFilterType(option);
        }}
      />

      <div className="w-[430px] h-[200px] mt-4">
        <Line data={lineGraphData} options={lineOptions} />
      </div>
    </div>
  );
};

export default TrendCurves;
