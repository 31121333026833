/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ChangeEvent, useState, FocusEvent } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { boardNameAvailable, createBoard } from "../../app/api/admin/board";
import { toast } from "react-toastify";

interface boardProps {
  onClose: () => void;
  userId: string;
  refresh: () => void;
}

const CreateBoard: React.FC<boardProps> = ({ onClose, userId, refresh }) => {
  const userName = useSelector((state: RootState) => state.user.userInfo?.name);

  const [boardName, setBoardName] = useState("");
  const [boardType, setBoardType] = useState("");
  const [errors, setErrors] = useState<{
    name?: string | undefined;
  }>({});

  const boardNameAvailability = async (boardName: string): Promise<void> => {
    const res = await boardNameAvailable(boardName);

    if (res?.status === 200) {
      if (res.data.data.isAvailable === false) {
        setErrors({ ...errors, name: "Board name already present" });
      }
    }
  };
  const handleFocus = async (event: FocusEvent<HTMLInputElement>) => {
    const boardName = event.target.value;
    await boardNameAvailability(boardName);
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setBoardName(event.target.value);
    setErrors({});
  };

  const handleSubmit = async () => {
    try {
      const payload = {
        board_name: boardName.charAt(0).toUpperCase() + boardName.slice(1),
        owner_id: userId,
        owner_name: userName,
        type: boardType,
      };
      await createBoard(payload);
      toast.success("Board created successfully", {
        hideProgressBar: true,
      });
      await refresh();
      onClose();
    } catch (error: any) {
      if (!errors.name) {
        toast.warn(error?.response.data.message, {
          hideProgressBar: true,
        });
      }
    }
  };

  return (
    <div>
      <div className="bg-white rounded-lg shadow-lg">
        <div className="mb-4">
          <input
            type="text"
            placeholder="Enter Board Name"
            className="w-full border border-gray-300 rounded-lg p-2 pl-8"
            onChange={handleChange}
            value={boardName}
            onBlur={handleFocus}
          />
          {errors.name && (
            <div className="text-xs	text-negative">{errors.name}</div>
          )}
        </div>

        <div className="flex justify-around mb-4">
          <label className="flex items-center">
            <input
              type="radio"
              name="visibility"
              value="private"
              className="mr-2"
              checked={boardType === "private"}
              onChange={e => setBoardType(e.target.value)}
            />
            Private
          </label>
          <label className="flex items-center">
            <input
              type="radio"
              name="visibility"
              value="public"
              className="mr-2"
              checked={boardType === "public"}
              onChange={e => setBoardType(e.target.value)}
            />
            Public
          </label>
        </div>
        <button
          className="w-full bg-black text-white rounded-lg py-2"
          onClick={handleSubmit}
        >
          Create
        </button>
      </div>
    </div>
  );
};

export default CreateBoard;
