import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import CrossIcon from "../../../assets/icons/CrossIcon";
import { DeleteFilter } from "../../../app/api/savedFilterApis";
import { useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import { toast } from "react-toastify";
import { getUserId } from "../../../app/api/user/user";
import { deleteSnapshot } from "../../../app/api/trendSnapshot";
import { useNavigate } from "react-router-dom";
interface Props {
  closeDeleteModal: () => void;
  snapshotId: number;
  ownerId: string;
}
const DeleteSnapshotModal: React.FC<Props> = ({
  closeDeleteModal,
  snapshotId,
  ownerId,
}) => {
  const user = useSelector((state: RootState) => state.user.userInfo);
  const [userId, setUserId] = useState("");
  const userEmail = user?.email;

  const getUserByEmail = async () => {
    if (userEmail) {
      try {
        const res = await getUserId(userEmail);
        const fetchedUserId = res.data.data[0]?._id;
        if (fetchedUserId) {
          setUserId(fetchedUserId); // Hardcoded for now
        } else {
          console.error("User ID not found.");
        }
      } catch (error) {
        console.error("Error fetching user ID:", error);
      }
    }
  };
  const navigate = useNavigate();
  useEffect(() => {
    getUserByEmail();
  }, []);
  const closeModal = () => {
    closeDeleteModal();
  };
  const handleDelete = async () => {
    // delete the snapshot
    if (userId !== ownerId) {
      toast.error("You are not authorized to delete this snapshot.");
      return;
    }
    try {
      await deleteSnapshot(snapshotId, user);
      toast.success("Snapshot deleted successfully.");
      navigate(-1);
      closeDeleteModal();
    } catch (error) {
      console.error("Error deleting snapshot:", error);
      toast.error("Error deleting snapshot.");
    }
  };
  return ReactDOM.createPortal(
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div
        className="fixed inset-0 bg-black opacity-50"
        onClick={closeModal}
      ></div>

      <div
        className=" relative bg-white rounded-lg shadow-sm z-60 font-poppins"
        style={{ width: "403px", height: "206px", overflow: "hidden" }}
      >
        <div className="flex flex-col p-4">
          {/* Option 1: Edit Current Filter */}
          <div className="mb-4">
            <div className="flex justify-between">
              <h1 className="text-[20px] font-bold font-poppins">
                Are you sure you want to delete the Snapshot?
              </h1>
            </div>
            <label className="text-sm font-normal font-poppins">
              Once removed the content will be lost and cannot be recovered.
            </label>
          </div>
          {/* Save Button */}
          <div className="flex justify-center gap-8">
            <div
              className="font-poppins text-sm border border-gray text-secondary font-bold  rounded-full  px-12 py-4 cursor-pointer items-center"
              onClick={closeModal}
            >
              Cancel
            </div>
            <div
              className="cursor-pointer font-poppins font-semibold text-sm bg-black rounded-full text-white px-12 py-4"
              onClick={handleDelete}
            >
              Confirm
            </div>
          </div>
        </div>
      </div>
    </div>,
    document.body
  );
};

export default DeleteSnapshotModal;
