import React, {
  ChangeEvent,
  useState,
  useRef,
  useEffect,
  useMemo,
} from "react";
import { ReactComponent as LeftArrow } from "../../assets/icons/leftArrow.svg";
import { ReactComponent as RightArrow } from "../../assets/icons/rightArrow.svg";
import { ReactComponent as DropdownArrow } from "../../assets/icons/dropdownArrow.svg";
import { ReactComponent as Search } from "../../assets/icons/search.svg";
import debounce from "lodash/debounce";
import Dropdown from "../Common/Dropdown";
import Calendar from "../Common/Calendar";
import moment from "moment";
import { SketchPicker } from "react-color";
import { brandsParams } from "../../interface/DesignerInterface";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { toast } from "react-toastify";
import Modal from "../Common/Modal";
import BrandsExpandView from "./BrandsExpandedView";
import CustomizedModal from "../Common/CustomisedModal";
import SaveFilter from "./SaveFilter";
import CrossIcon from "../../assets/icons/CrossIcon";
import { capitalizeFirstLetter } from "../../utils";

interface PreferencesProps {
  startDate: string;
  endDate: string;
  isCollapsed: boolean;
  onToggle: () => void;
  setGenre: React.Dispatch<React.SetStateAction<string>>;
  setProduct: React.Dispatch<React.SetStateAction<string>>;
  setGender: React.Dispatch<React.SetStateAction<string>>;
  setAgeGroup: React.Dispatch<React.SetStateAction<string>>;
  setRegion: React.Dispatch<React.SetStateAction<string>>;
  setBrands: React.Dispatch<React.SetStateAction<string[]>>;
  setStartDate: React.Dispatch<React.SetStateAction<string>>;
  setEndDate: React.Dispatch<React.SetStateAction<string>>;
  region: string;
  genre: string;
  ageGroup: string;
  product: string;
  gender: string;
  brands: string[];
  genres: string[];
  regions: string[];
  sources: string[];
  categories: string[];
  setSource: React.Dispatch<React.SetStateAction<string>>;
  source: string;
  brandsList: { [key: string]: brandsParams[] };
  productList: string[];
  color: string;
  setColor: React.Dispatch<React.SetStateAction<string>>;
  category: string;
  setCategory: React.Dispatch<React.SetStateAction<string>>;
  isBrandsModalOpen: boolean;
  setBrandsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  closeBrandsModal: () => void;
  filterName: string;
  setFilterId: React.Dispatch<React.SetStateAction<string>>;
  setFilterName: React.Dispatch<React.SetStateAction<string>>;
  filterId: string;
  TrendSnapshotDisabled?: boolean;
  TrendsSnapshotPreferencesView?: boolean;
  setClear?: React.Dispatch<React.SetStateAction<boolean>>;
}

const Preferences: React.FC<PreferencesProps> = ({
  startDate,
  endDate,
  isCollapsed,
  onToggle,
  setGenre,
  setProduct,
  setGender,
  setAgeGroup,
  setRegion,
  setBrands,
  setStartDate,
  setEndDate,
  region,
  genre,
  product,
  gender,
  ageGroup,
  brands,
  genres,
  regions,
  sources,
  setSource,
  source,
  brandsList,
  productList,
  color,
  setColor,
  categories,
  category,
  setCategory,
  isBrandsModalOpen,
  setBrandsModalOpen,
  closeBrandsModal,
  setFilterId,
  filterName,
  setFilterName,
  filterId,
  TrendSnapshotDisabled,
  TrendsSnapshotPreferencesView,
  setClear,
}) => {
  const [localGender, setLocalGender] = useState(gender);
  const [isStartCalendarOpen, setStartCalendarOpen] = useState(false);
  const [isEndCalendarOpen, setEndCalendarOpen] = useState(false);
  const [openColorPicker, setOpenColorPicker] = useState(false);
  const dateButtonsWrapperRef = useRef<HTMLDivElement>(null);
  const [visibleBrands, setVisibleBrands] = useState<brandsParams[]>([]);
  const [brandsSearch, setBrandsSearch] = useState<string>("");
  const [disableAfterSave, setDisableAfterSave] = useState<boolean>(false);
  const [sketchPickerColor, setSketchPickerColor] = useState(
    color || "#FF0000"
  );
  const [isColorPicker, setIsColorPicker] = useState(false);

  const customPresetColors = [
    "#FF0000",
    "#FFA500",
    "#FFFF00",
    "#008000",
    "#00FFFF",
    "#0047AB",
    "#800080",
    "#FFC0CB",
    "#A52A2A",
    "#808000",
    "#FFFFFF",
    "#F5F5DC",
    "#808080",
    "#000000",
  ];

  const handleSearch = (color: string) => {
    setColor(color.replace("#", ""));
  };

  // Memoize the debounced function to avoid re-creating it on every render
  const debouncedSearch = useMemo(() => debounce(handleSearch, 1000), []);

  const handleChangeColor = (color: string) => {
    setDisableAfterSave(false);
    setSketchPickerColor(color);
    debouncedSearch(color);
  };

  useEffect(() => {
    setLocalGender(gender);
  }, [gender]);
  // Cancel the debounce effect on component unmount
  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  const handleStartDateChange = (value: string) => {
    const selectedStartDate = moment(value);
    const selectedEndDate = moment(endDate);
    if (selectedEndDate.isBefore(selectedStartDate)) {
      toast.error("End date cannot be earlier than the start date.");
      return;
    }
    setStartDate(value);
    setStartCalendarOpen(false);
    setDisableAfterSave(false);
  };

  const handleEndDateChange = (value: string) => {
    const selectedEndDate = moment(value);
    const selectedStartDate = moment(startDate);

    if (selectedEndDate.isBefore(selectedStartDate)) {
      toast.error("End date cannot be earlier than the start date.");
      return;
    }
    setEndDate(value);
    setEndCalendarOpen(false);
    setDisableAfterSave(false);
  };

  const handleGenreChange = (value: string) => {
    if (value === "None") {
      setGenre("");
      setGender("");
      setAgeGroup("");
      setCategory("");
      setProduct("");
      setRegion("");
    } else {
      setGenre(value);
    }
    setDisableAfterSave(false);
  };

  const handleProductChange = (value: string) => {
    if (value === "None") {
      setProduct("");
    } else {
      setProduct(value);
    }
    setDisableAfterSave(false);
  };

  const handleCategoryChange = (value: string) => {
    if (value === "None") {
      setCategory("");
    } else {
      setCategory(value);
    }
    setDisableAfterSave(false);
  };

  const handleSourceChange = (value: string) => {
    if (value === "None") {
      setSource("");
    } else {
      setSource(value);
    }
    setDisableAfterSave(false);
  };

  const handleGenderChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setGender(event.target.value);
      setLocalGender(event.target.value);
    } else {
      setGender("");
      setLocalGender("");
    }
    setDisableAfterSave(false);
  };

  const handleAgeGroupChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setAgeGroup(event.target.value);
    } else {
      setAgeGroup("");
    }
  };

  const handleRegionChange = (value: string) => {
    if (value === "None") {
      setRegion("");
    } else {
      setRegion(value);
    }
    setDisableAfterSave(false);
  };

  const handleBrandsChange = (event: ChangeEvent<HTMLInputElement>) => {
    const index = brands.indexOf(event.target.value);
    if (event.target.checked) {
      brands.push(event.target.value);
    } else {
      brands.splice(index, 1);
    }
    setBrands([...brands]);
    setDisableAfterSave(false);
  };

  const handleColorChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newColor = event.target.value.replace("#", "");
    setColor(newColor);
    setDisableAfterSave(false);
  };

  const handleClearAll = () => {
    setGenre("");
    setProduct("");
    setCategory("");
    setGender("");
    setAgeGroup("");
    setRegion("");
    setBrands([]);
    setStartDate("");
    setEndDate("");
    setSource("");
    setStartDate("");
    setEndDate("");
    setLocalGender("");
    setColor("");
    setFilterName("");
    setFilterId("");
    if (setClear) {
      setClear(true);
    }
  };

  const isAnyFilterSelected = () => {
    return (
      genre ||
      product ||
      localGender ||
      region ||
      brands.length > 0 ||
      startDate ||
      endDate ||
      source
    );
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dateButtonsWrapperRef.current &&
        !dateButtonsWrapperRef.current.contains(event.target as Node)
      ) {
        setStartCalendarOpen(false);
        setEndCalendarOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleColorPicker = () => {
    setIsColorPicker(!isColorPicker);
  };

  const modifyCategoryArray = (arr: string[]) => {
    const newArr = [
      "None",
      ...arr.map(item => (item === "N/A" ? "Other" : item)),
    ];
    return newArr;
  };

  useEffect(() => {
    let count = 0;
    const tempVisibleBrands = [];
    for (const [key, value] of Object.entries(brandsList)) {
      let i = 0;
      while (i < value.length) {
        if (brandsSearch.length > 0) {
          if (
            value[i].brandName
              .toLowerCase()
              .includes(brandsSearch.toLowerCase()) ||
            value[i].handle.toLowerCase().includes(brandsSearch.toLowerCase())
          ) {
            tempVisibleBrands.push(value[i]);
            count++;
          }
        } else {
          tempVisibleBrands.push(value[i]);
          count++;
        }
        if (count === 3) {
          break;
        }
        i++;
      }
      if (count === 3) {
        break;
      }
    }
    setVisibleBrands(tempVisibleBrands);
  }, [brandsList, brandsSearch]);
  return (
    <div
      className={` flex flex-col justify-between  shadow-md transition-all overflow-scroll no-scrollbar duration-500 ease-in-out ${
        isCollapsed ? "w-8" : "w-[200px]"
      }  ${TrendsSnapshotPreferencesView ? "bg-dashboard" : "bg-white"}`}
    >
      <div>
        <div
          className={`sticky ml-1 top-0 ${TrendsSnapshotPreferencesView ? "bg-dashboard" : "bg-white"} z-10`}
        >
          <div
            className={`flex items-center text-primary rounded-2xl  cursor-pointer ${
              isCollapsed ? "justify-center" : "px-1 py-2"
            }`}
            onClick={onToggle}
          >
            {isCollapsed ? (
              <div className="flex flex-col items-center">
                <RightArrow className="text-lg mb-4" />
                <div>
                  {"SECNEREFERP".split("").map((letter, index) => (
                    <span
                      key={index}
                      className="block -rotate-90 font-semibold text-secondary"
                      style={{ marginBottom: "-8px" }}
                    >
                      {letter}
                    </span>
                  ))}
                </div>
              </div>
            ) : (
              <div className="flex w-full justify-between items-center">
                <span className="transition-all duration-500 ease-in-out opacity-100 font-semibold max-w-full ml-1 text-secondary">
                  Preferences
                </span>
                <LeftArrow className="text-lg mr-2" />
              </div>
            )}
          </div>
        </div>
        {!isCollapsed && (
          <>
            <div className={`${filterName ? "block" : "hidden"}`}>
              <div className="flex ">
                <div
                  className={` px-3  text-tertiary text-xs rounded-lg 
              h-[29px] w-full `}
                >
                  <div className="flex justify-between">
                    <div className="items-center text-[#4D4D4D] truncate font-bold">
                      {capitalizeFirstLetter(filterName)}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div> */}
            <div className="px-3 max-w-xs mx-auto">
              <div className="mb-4 ">
                <div className="flex justify-between items-center">
                  <label className="block text-secondary text-sm font-medium mb-1">
                    Date range
                  </label>
                  {isAnyFilterSelected() && (
                    <div
                      className="text-xs cursor-pointer text-negative"
                      onClick={handleClearAll}
                    >
                      clear all
                    </div>
                  )}
                </div>
                <div ref={dateButtonsWrapperRef} className="relative  ">
                  <div className="w-full relative">
                    <div className="mt-1 flex w-full justify-between items-center pl-3 pr-2 py-2 text-left text-base border border-default rounded-lg">
                      <button
                        className="w-full items-center justify-between flex text-secondary text-xs rounded"
                        disabled={TrendSnapshotDisabled}
                        onClick={() => {
                          setStartCalendarOpen(!isStartCalendarOpen);
                          if (isEndCalendarOpen) setEndCalendarOpen(false);
                        }}
                      >
                        <div>
                          {startDate
                            ? moment(startDate).format("DD/MM/YYYY")
                            : "Start Date"}
                        </div>
                        <DropdownArrow className="w-2 h-2 mr-2" />
                      </button>
                    </div>
                    {isStartCalendarOpen && (
                      <div
                        className="absolute z-50"
                        style={{
                          top: "100%",
                          left: "55%",
                          transform: "translateX(-50%)",
                          marginTop: "5px",
                        }}
                      >
                        {isStartCalendarOpen && (
                          <Calendar onChange={handleStartDateChange} />
                        )}
                      </div>
                    )}
                  </div>
                  <div className="w-full relative">
                    <div className="mt-2 flex w-full justify-between items-center pl-3 pr-2 py-2 text-left text-base border border-default rounded-lg">
                      <button
                        className="w-full flex  items-center justify-between text-secondary text-xs rounded"
                        disabled={TrendSnapshotDisabled}
                        onClick={() => {
                          setEndCalendarOpen(!isEndCalendarOpen);
                          if (isStartCalendarOpen) setStartCalendarOpen(false);
                        }}
                      >
                        <div>
                          {endDate
                            ? moment(endDate).format("DD/MM/YYYY")
                            : "End Date"}
                        </div>
                        <DropdownArrow className="w-2 h-2 mr-2" />
                      </button>
                    </div>
                    {isEndCalendarOpen && (
                      <div
                        className="absolute z-50"
                        style={{
                          top: "100%",
                          left: "55%",
                          transform: "translateX(-50%)",
                          marginTop: "5px",
                        }}
                      >
                        {isEndCalendarOpen && (
                          <Calendar onChange={handleEndDateChange} />
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="mb-4">
                <label className="block text-secondary text-sm font-medium mb-1">
                  Select Genre
                </label>
                <Dropdown
                  label={genre || "Select Genre"}
                  options={["None", ...genres]}
                  onSelect={handleGenreChange}
                  trendSnapshotView={TrendSnapshotDisabled}
                />
              </div>

              <div>
                {genre === "Fashion" && (
                  <>
                    <div className="mb-4">
                      <label className="block text-secondary text-sm font-medium mb-1">
                        Select Gender
                      </label>
                      <div className="flex space-x-4">
                        <label className="inline-flex items-center">
                          <input
                            type="checkbox"
                            className="form-radio"
                            name="gender"
                            value="Men"
                            disabled={TrendSnapshotDisabled}
                            onChange={handleGenderChange}
                            checked={localGender === "Men"}
                            style={{ accentColor: "black" }}
                          />
                          <span className="ml-2 mt-1 text-sm text-secondary">
                            Male
                          </span>
                        </label>
                        <label className="inline-flex items-center">
                          <input
                            type="checkbox"
                            className="form-radio"
                            name="gender"
                            value="Women"
                            disabled={TrendSnapshotDisabled}
                            onChange={handleGenderChange}
                            checked={localGender === "Women"}
                            style={{ accentColor: "black" }}
                          />
                          <span className="ml-2 mt-1 text-sm text-secondary">
                            Female
                          </span>
                        </label>
                      </div>
                    </div>

                    <div className="mb-4">
                      <label className="block text-secondary text-sm font-medium mb-1">
                        Select Age Group
                      </label>
                      <div className="flex space-x-4">
                        <label className="inline-flex items-center">
                          <input
                            type="checkbox"
                            className="form-radio"
                            name="ageGroup"
                            value="Adult"
                            disabled={TrendSnapshotDisabled}
                            onChange={handleAgeGroupChange}
                            checked={ageGroup === "Adult"}
                            style={{ accentColor: "black" }}
                          />
                          <span className="ml-2 mt-1 text-sm text-secondary">
                            Adult
                          </span>
                        </label>
                        <label className="inline-flex items-center">
                          <input
                            type="checkbox"
                            className="form-radio"
                            name="ageGroup"
                            value="Youth"
                            disabled={TrendSnapshotDisabled}
                            onChange={handleAgeGroupChange}
                            checked={ageGroup === "Youth"}
                            style={{ accentColor: "black" }}
                          />
                          <span className="ml-2 mt-1 text-sm text-secondary">
                            Youth
                          </span>
                        </label>
                      </div>
                    </div>

                    <div className="mb-4">
                      <label className="block text-secondary text-sm font-medium mb-1">
                        Category
                      </label>
                      <Dropdown
                        label={category || "Select Category"}
                        options={modifyCategoryArray(categories)}
                        onSelect={handleCategoryChange}
                        trendSnapshotView={TrendSnapshotDisabled}
                      />
                    </div>

                    <div className="mb-4">
                      <label className="block text-secondary text-sm font-medium mb-1">
                        Product
                      </label>
                      <Dropdown
                        label={product || "Select Product"}
                        options={["None", ...productList]}
                        onSelect={handleProductChange}
                        trendSnapshotView={TrendSnapshotDisabled}
                      />
                    </div>

                    <div className="mb-4">
                      <label className="block text-secondary text-sm font-normal mb-1">
                        Region
                      </label>
                      <Dropdown
                        label={region ? region : "Select Region"}
                        options={["None", ...regions]}
                        onSelect={handleRegionChange}
                        trendSnapshotView={TrendSnapshotDisabled}
                      />
                    </div>
                  </>
                )}
                <div className="mb-4">
                  <label className="block text-secondary text-sm font-medium mb-1">
                    Sources
                  </label>
                  <Dropdown
                    label={source || "Select Sources"}
                    options={["None", ...sources]}
                    onSelect={handleSourceChange}
                    trendSnapshotView={TrendSnapshotDisabled}
                  />
                </div>

                <div className="mb-4">
                  <div className="flex justify-between items-center">
                    <label className="block text-secondary text-sm font-medium mb-1">
                      Brands
                    </label>
                    {brands.length > 0 && (
                      <div
                        className="text-xs cursor-pointer text-negative mb-2"
                        onClick={() => setBrands([])}
                      >
                        clear all
                      </div>
                    )}
                  </div>
                  <div className="w-full text-secondary">
                    <div className="relative w-full mb-1">
                      <Search className="absolute left-2 top-1/2 transform -translate-y-1/2 w-5 h-5 text-gray-400" />
                      <input
                        type="text"
                        placeholder="Search"
                        value={brandsSearch}
                        className="w-full pl-8 pr-2 py-1.5 text-sm rounded-md border border-default bg-disabled focus:outline-none"
                        onChange={e => setBrandsSearch(e.target.value)}
                      />
                    </div>
                    <div className="max-h-32 relative no-scrollbar text-sm text-secondary">
                      {visibleBrands.map(
                        (item: brandsParams, index: number) => (
                          <label className="block" key={index}>
                            <div className="flex flex-row justify-items-center ">
                              <input
                                type="checkbox"
                                className="mr-1 mt-1"
                                value={item.handle}
                                onChange={handleBrandsChange}
                                checked={brands.includes(item.handle || "")}
                                style={{ accentColor: "black" }}
                              />
                              <div className="w-full flex justify-between gap-3">
                                <Tippy
                                  content={capitalizeFirstLetter(
                                    item?.brandName
                                  )}
                                  appendTo="parent"
                                  popperOptions={{
                                    modifiers: [
                                      {
                                        name: "preventOverflow",
                                        options: {
                                          boundary: "viewport",
                                        },
                                      },
                                      {
                                        name: "hide",
                                        enabled: true,
                                      },
                                    ],
                                  }}
                                >
                                  <div className="mt-[7px]">
                                    {item?.brandName &&
                                    item?.brandName.length > 14
                                      ? capitalizeFirstLetter(
                                          item?.brandName
                                        )?.substring(0, 14) + "..."
                                      : capitalizeFirstLetter(item?.brandName)}
                                  </div>
                                </Tippy>
                                <div className="h-[15px] rounded bg-[#f3f3f3] mt-[8px] text-xs">
                                  {item.count}
                                </div>
                              </div>
                            </div>
                          </label>
                        )
                      )}
                    </div>
                  </div>
                  <div
                    className="text-sm text-[#B18873] cursor-pointer mt-3"
                    onClick={() => {
                      setBrandsModalOpen(true);
                    }}
                  >
                    See All
                  </div>
                </div>

                <div className="mb-4">
                  <div className="flex justify-between items-center">
                    <label className=" text-secondary text-sm font-medium mb-1">
                      Color Picker
                    </label>
                    <div
                      className="text-xs cursor-pointer text-negative mb-2"
                      onClick={() => {
                        setColor("");
                        setSketchPickerColor("");
                        setIsColorPicker(false);
                      }}
                    >
                      clear all
                    </div>
                  </div>
                </div>
                {isColorPicker && (
                  <div>
                    <SketchPicker
                      width="165px"
                      onChange={color => {
                        handleChangeColor(color.hex);
                      }}
                      color={sketchPickerColor}
                      presetColors={customPresetColors}
                    />
                  </div>
                )}
                <button
                  onClick={handleColorPicker}
                  className={`font-avenir px-4 py-1 bg-[#f3f3f3] border-2 ${sketchPickerColor ? `border-[${sketchPickerColor}]` : ""} text-secondary h-9 w-[175px] rounded-[8px] mt-2 mb-10`}
                  style={{
                    borderColor:
                      sketchPickerColor === "#FF0000" ? "" : sketchPickerColor,
                  }}
                >
                  Choose Color
                </button>
                {!TrendsSnapshotPreferencesView && (
                  <SaveFilter
                    setGenre={setGenre}
                    setSource={setSource}
                    setGender={setGender}
                    setProduct={setProduct}
                    setRegion={setRegion}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                    setColor={setColor}
                    setBrands={setBrands}
                    setCategory={setCategory}
                    setAgeGroup={setAgeGroup}
                    region={region}
                    genre={genre}
                    source={source}
                    gender={gender}
                    product={product}
                    startDate={startDate}
                    endDate={endDate}
                    color={color}
                    brands={brands}
                    category={category}
                    ageGroup={ageGroup}
                    isCollapsed={isCollapsed}
                    handleClearAll={handleClearAll}
                    filterId={filterId}
                    filterName={filterName}
                    setFilterId={setFilterId}
                    setFilterName={setFilterName}
                    disableAfterSave={disableAfterSave}
                    setDisableAfterSave={setDisableAfterSave}
                  />
                )}
              </div>
            </div>
          </>
        )}
      </div>
      <CustomizedModal
        title="Choose a Brand"
        isOpen={isBrandsModalOpen}
        onClose={closeBrandsModal}
        width="840px"
      >
        <BrandsExpandView
          brands={brands}
          setBrands={setBrands}
          closeBrandsModal={closeBrandsModal}
          brandsList={brandsList}
        />
      </CustomizedModal>
    </div>
  );
};

export default Preferences;
