import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

interface SnapshotCardSkeletonProps {
  count: number;
}

const SnapshotCardSkeleton: React.FC<SnapshotCardSkeletonProps> = ({
  count,
}) => {
  return (
    <div className="flex flex-wrap gap-4 justify-between">
      {Array.from({ length: count }).map((_, idx) => (
        <div
          key={idx}
          className="w-[170px] h-[275px] border border-light rounded-md cursor-pointer relative p-2 flex flex-col gap-2 "
        >
          <div className="h-[172px] w-full flex gap-2">
            <div className="h-full w-[108px] ">
              <Skeleton className="h-full w-full rounded-lg" />
            </div>
            <div className="flex flex-col gap-1 w-[60px] overflow-hidden">
              {Array.from({ length: 3 }).map((_, index) => (
                <Skeleton key={index} className="rounded-md h-[56px] w-full" />
              ))}
            </div>
          </div>
          <div className="w-full flex flex-col gap-2 px-2">
            <Skeleton className="h-[16px] w-full" />
            <Skeleton className="h-[12px] w-3/4" />
            <Skeleton className="h-[12px] w-2/3" />
          </div>
        </div>
      ))}
    </div>
  );
};

export default SnapshotCardSkeleton;
