import React from "react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function DownloadSnapshotIcon(props: any) {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5 16.75C5.19891 16.75 5.38968 16.829 5.53033 16.9697C5.67098 17.1103 5.75 17.3011 5.75 17.5V19.5C5.75 19.638 5.862 19.75 6 19.75H18C18.0663 19.75 18.1299 19.7237 18.1768 19.6768C18.2237 19.6299 18.25 19.5663 18.25 19.5V17.5C18.25 17.3011 18.329 17.1103 18.4697 16.9697C18.6103 16.829 18.8011 16.75 19 16.75C19.1989 16.75 19.3897 16.829 19.5303 16.9697C19.671 17.1103 19.75 17.3011 19.75 17.5V19.5C19.75 19.9641 19.5656 20.4092 19.2374 20.7374C18.9092 21.0656 18.4641 21.25 18 21.25H6C5.53587 21.25 5.09075 21.0656 4.76256 20.7374C4.43437 20.4092 4.25 19.9641 4.25 19.5V17.5C4.25 17.3011 4.32902 17.1103 4.46967 16.9697C4.61032 16.829 4.80109 16.75 5 16.75Z"
        fill="#212121"
      />
      <path
        d="M10.7381 4.25C10.4899 4.25007 10.2508 4.34314 10.0679 4.51087C9.88501 4.67859 9.77162 4.90877 9.7501 5.156C9.59622 6.90842 9.56881 8.66964 9.6681 10.426C9.4211 10.439 9.1751 10.456 8.9281 10.473L7.4381 10.582C7.30688 10.5916 7.1804 10.6351 7.07104 10.7083C6.96168 10.7815 6.87319 10.8818 6.81423 10.9994C6.75526 11.117 6.72785 11.2479 6.73466 11.3793C6.74148 11.5107 6.78229 11.6381 6.8531 11.749C7.91499 13.4111 9.2833 14.8561 10.8851 16.007L11.4821 16.436C11.6331 16.5444 11.8142 16.6027 12.0001 16.6027C12.186 16.6027 12.3671 16.5444 12.5181 16.436L13.1151 16.007C14.7169 14.8561 16.0852 13.4111 17.1471 11.749C17.2179 11.6381 17.2587 11.5107 17.2655 11.3793C17.2724 11.2479 17.2449 11.117 17.186 10.9994C17.127 10.8818 17.0385 10.7815 16.9292 10.7083C16.8198 10.6351 16.6933 10.5916 16.5621 10.582L15.0721 10.473C14.8256 10.4552 14.5789 10.4395 14.3321 10.426C14.4317 8.66967 14.4046 6.90844 14.2511 5.156C14.2296 4.9086 14.116 4.67828 13.9329 4.51053C13.7498 4.34278 13.5104 4.24982 13.2621 4.25H10.7381Z"
        fill="#212121"
      />
    </svg>
  );
}

export default DownloadSnapshotIcon;
