import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { FeedItem } from "../../interface/DesignerInterface";
import SelectionChips from "../DesignerPage/Trends/SelectionChips";
import { ReactComponent as HeartIcon } from "../../assets/icons/heart.svg";
import { ReactComponent as Archive } from "../../assets/icons/archive.svg";
import { ReactComponent as Close } from "../../assets/icons/closeBold.svg";
import { ReactComponent as Unarchive } from "../../assets/icons/unarchive.svg";
import InstaPost from "../DesignerPage/InstaPost";
import { Board } from "../../interface/BoardsInterface";
import Pagination from "../Target/Pagination";
import { getPaginatedTrendsPost } from "../../app/api/trends";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import Skeleton from "react-loading-skeleton";
import { getTrendsExpandedViewSnapshot } from "../../app/api/trendSnapshot";

type ExpandedPostModalProps = {
  onClose: () => void;
  name: string;
  selections: string[];
  privateBoards: Board[];
  setPrivateBoards: React.Dispatch<React.SetStateAction<Board[]>>;
  publicBoards: Board[];
  setPublicBoards: React.Dispatch<React.SetStateAction<Board[]>>;
  userId: string;
  req_id: string;
  allPagesData: Record<string, FeedItem[]>;
  setAllPagesData: (item: Record<string, FeedItem[]>) => void;
  handleOpenSaveToBoard: () => void;
  setIsExpandViewOpen: React.Dispatch<React.SetStateAction<boolean>>;
  trendSnapshotTrendsView?: boolean;
  snapshotId?: number;
  trendNo?: number;
  setArchiveModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  archive?: boolean;
};

const ExpandedPostModal: React.FC<ExpandedPostModalProps> = ({
  name,
  onClose,
  selections,
  privateBoards,
  setPrivateBoards,
  publicBoards,
  setPublicBoards,
  userId,
  req_id,
  allPagesData,
  setAllPagesData,
  handleOpenSaveToBoard,
  setIsExpandViewOpen,
  trendSnapshotTrendsView,
  snapshotId,
  trendNo,
  setArchiveModalOpen,
  archive,
}) => {
  const [posts, setPosts] = React.useState<FeedItem[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 12;
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);

  const user = useSelector((state: RootState) => state.user.userInfo);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      if (trendSnapshotTrendsView) {
        try {
          const params = {
            page: currentPage,
            pageSize,
          };
          const response = await getTrendsExpandedViewSnapshot(
            snapshotId,
            user,
            trendNo,
            params
          );
          setPosts(response?.data?.posts);
          setTotalPages(Math.ceil(response?.data?.totalPosts / pageSize));
        } catch (error) {
          console.error("Error fetching snapshot details:", error);
        } finally {
          setLoading(false);
        }
      } else {
        try {
          const response = await getPaginatedTrendsPost(
            { page: currentPage, pageSize, trend_name: name, req_id },
            user
          );
          setPosts(response?.data?.posts);
          setTotalPages(Math.ceil(response?.data?.postCount / pageSize));
        } catch (error) {
          if ((error as AxiosError).response?.status !== 400) {
            toast.error("Error fetching posts", {
              hideProgressBar: true,
            });
          }
        } finally {
          setLoading(false);
        }
      }
    };
    getData();
  }, [currentPage]);

  // Render modal content via portal at the end of the document body
  return ReactDOM.createPortal(
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      {/* Full-screen dimmed background */}
      <div
        className="fixed inset-0 bg-black opacity-50"
        onClick={onClose}
      ></div>

      {/* Modal Content */}
      <div
        className={`relative bg-white rounded-lg shadow-lg z-60 overflow-y-auto w-[740px] ${totalPages > 1 ? "h-[690px]" : "h-[630px]"}`}
      >
        {/* Fixed Header Section */}
        <div className="sticky top-0 bg-white p-6 border-b border-gray-200 z-10">
          <div className="flex justify-between">
            <div className="flex space-x-2 items-center">
              <h1 className="font-poppins text-xl font-bold">{name}</h1>
              <div className="w-[2px] h-[18px] bg-[#4b4b4b]"></div>
              {selections.map((value, index) => (
                <SelectionChips key={index} value={value} />
              ))}
            </div>
            <div className="flex space-x-3">
              <div className="cursor-pointer">
                <HeartIcon
                  className="w-4 h-4"
                  onClick={() => {
                    handleOpenSaveToBoard();
                    setIsExpandViewOpen(false);
                  }}
                />
              </div>
              <div
                className={`${trendSnapshotTrendsView}?"":"cursor-pointer"`}
                onClick={() => {
                  if (trendSnapshotTrendsView) return;
                  setArchiveModalOpen(true);
                }}
              >
                {archive || trendSnapshotTrendsView ? (
                  <Unarchive className="w-4 h-4" />
                ) : (
                  <Archive className="w-4 h-4" />
                )}
              </div>
              <div className="cursor-pointer" onClick={onClose}>
                <Close className="w-4 h-4" />
              </div>
            </div>
          </div>
        </div>

        {/* Scrollable Posts Section */}
        <div className={`overflow-y-auto h-[550px]`}>
          <div className="flex justify-around flex-wrap p-4 gap-y-2">
            {loading
              ? Array.from({ length: 8 }).map((_, index) => (
                  <div
                    key={index}
                    className="w-[158px] p-0 gap-2 border border-light rounded-2xl relative cursor-pointer"
                  >
                    <div className="relative w-full h-auto">
                      <Skeleton
                        height={160}
                        width={158}
                        className="rounded-t-2xl"
                      />
                      <div className="absolute top-0 right-0 bg-gray-300 text-white bg-gray81/50 rounded-bl-lg rounded-tr-2xl px-3 py-1 text-xs">
                        <Skeleton width={50} />
                      </div>
                      <div className="absolute bottom-2 right-2 flex space-x-1">
                        <div className="bg-white flex w-5 h-5 items-center justify-center rounded-full">
                          <Skeleton circle={true} height={20} width={20} />
                        </div>
                        <div className="bg-white flex w-5 h-5 items-center justify-center rounded-full">
                          <Skeleton circle={true} height={20} width={20} />
                        </div>
                        <div className="bg-white flex w-5 h-5 items-center justify-center rounded-full">
                          <Skeleton circle={true} height={20} width={20} />
                        </div>
                        <div className="bg-white flex w-5 h-5 items-center justify-center rounded-full">
                          <Skeleton circle={true} height={20} width={20} />
                        </div>
                      </div>
                    </div>
                    <div className="p-2 space-y-0.5">
                      <Skeleton width={100} />
                      <Skeleton width={150} />
                      <Skeleton width={120} />
                    </div>
                  </div>
                ))
              : posts.map((post, index) => (
                  <InstaPost
                    key={index}
                    item={post}
                    trendsOpen={true}
                    privateBoards={privateBoards}
                    setPrivateBoards={setPrivateBoards}
                    publicBoards={publicBoards}
                    setPublicBoards={setPublicBoards}
                    userId={userId}
                  />
                ))}
          </div>
        </div>
        <div className={`my-2 ${totalPages > 1 ? "block" : "hidden"}`}>
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </div>
      </div>
    </div>,
    document.body
  );
};

export default ExpandedPostModal;
