import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import { RootState, useAppDispatch } from "../../app/store";
import { userLogin } from "../../app/api/auth";
import { toast, ToastContainer, Bounce } from "react-toastify";
import { ReactComponent as ICHNextLogo } from "../../assets/icons/ichNextLogo.svg";
import { ReactComponent as Eyebutton } from "../../assets/icons/EyeButton.svg";
import { useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";

type LoginValues = {
  email: string;
  password: string;
};

const Login: React.FC = () => {
  const [loginError, setLoginError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const user = useSelector((state: RootState) => state.user.userInfo);

  const initialValues: LoginValues = {
    email: "",
    password: "",
  };

  const validationSchema = Yup.object({
    email: Yup.string().email("Invalid email address").required("Required"),
    password: Yup.string().required("Required"),
  });

  const handleSubmit = async (values: LoginValues) => {
    try {
      setLoading(true);
      setLoginError(false);
      await dispatch(userLogin(values.email, values.password));

      setTimeout(() => {
        setLoading(false);
        navigate("/");
      }, 1000);
    } catch (err) {
      setLoading(false);
      setLoginError(true);
      toast.error("Incorrect Username or Password", {
        autoClose: 1500,
        hideProgressBar: true,
      });
    }
  };

  useEffect(() => {
    if (user) {
      navigate("/");
    }
  }, [user, navigate]);

  if (loading) {
    return (
      <div className="flex">
        <div className="w-64 h-screen bg-gray-100 p-4">
          <Skeleton height="100%" width="100%" className="mb-4" />
        </div>
        <div className="flex-1 flex flex-col">
          <div className="h-24 p-4">
            <Skeleton height="100%" width="100%" />
          </div>
          <div className="h-full p-4">
            <Skeleton height="100%" width="100%" />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex items-center justify-center min-h-screen">
      <div className="p-8 rounded-lg w-96">
        <ICHNextLogo className="mx-auto mb-12" />
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          <Form>
            <div className="mb-8">
              <label
                htmlFor="email"
                className="block text-sm font-semibold mb-2"
              >
                Enter Email
              </label>
              <Field
                id="email"
                name="email"
                type="email"
                placeholder="Email"
                className="border border-gray-500 rounded-md w-full py-2 px-3 focus:outline-none"
              />
              <ErrorMessage
                name="email"
                component="div"
                className="text-red-500 text-xs mt-1"
              />
            </div>
            <div className="mb-12 relative">
              <label
                htmlFor="password"
                className="block text-sm font-semibold mb-2"
              >
                Enter Password
              </label>
              <Field
                id="password"
                name="password"
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                className="border border-gray-500 rounded-md w-full py-2 px-3 focus:outline-none"
              />
              <ErrorMessage
                name="password"
                component="div"
                className="text-red-500 text-xs"
              />
              <div
                className="absolute top-9 right-3 cursor-pointer"
                onClick={() => setShowPassword(!showPassword)}
              >
                <div className="relative inline-block">
                  <Eyebutton />
                  {showPassword && (
                    <span
                      className="absolute left-1 bottom-1 inset-0 flex items-center justify-center text-xl font-bold"
                      style={{ transform: "rotate(30deg)" }}
                    >
                      /
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="flex items-center justify-center">
              <button
                type="submit"
                className="w-full bg-black text-white font-bold text-sm py-3 px-4 rounded-full focus:outline-none"
              >
                Login
              </button>
            </div>
            <div className="text-center mt-4">
              <Link
                to="/auth/forgot-password"
                className="text-sm font-semibold"
              >
                Forgot Password?
              </Link>
            </div>
          </Form>
        </Formik>
        {loginError && (
          <div className="mt-4 text-sm text-red-500 text-center font-bold">
            Incorrect Username or Password.
          </div>
        )}
      </div>
    </div>
  );
};

export default Login;
