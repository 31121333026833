import React from "react";
import { ReactComponent as LeftPagination } from "../../assets/icons/targetPaginationLeft.svg";
import { ReactComponent as RightPagination } from "../../assets/icons/targetPaginationRight.svg";

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  totalPages,
  onPageChange,
}) => {
  const generatePageNumbers = () => {
    let pages: (number | string)[] = [];

    if (totalPages <= 8) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      if (currentPage <= 6) {
        pages = [1, 2, 3, 4, 5, 6, 7, "...", totalPages];
      } else if (currentPage > 6 && currentPage < totalPages - 5) {
        pages = [
          1,
          "...",
          currentPage - 2,
          currentPage - 1,
          currentPage,
          currentPage + 1,
          currentPage + 2,
          "...",
          totalPages,
        ];
      } else {
        pages = [
          1,
          "...",
          totalPages - 6,
          totalPages - 5,
          totalPages - 4,
          totalPages - 3,
          totalPages - 2,
          totalPages - 1,
          totalPages,
        ];
      }
    }

    return pages;
  };

  return (
    <div className="flex justify-center items-center space-x-1 min-w-80">
      {totalPages && totalPages > 1 ? (
        <>
          <button
            className={`text-secondary font-bold px-2 py-1 rounded ${currentPage === 1 ? "" : "hover:bg-gray"}`}
            onClick={() => onPageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            <LeftPagination />
          </button>
          {generatePageNumbers().map((number, index) =>
            number === "..." ? (
              <span key={index} className="w-8 py-2 font-bold text-secondary">
                ....
              </span>
            ) : (
              <button
                key={index}
                className={`text-xs w-8 py-2 rounded ${number === currentPage ? "bg-active font-bold text-primary" : "hover:bg-gray font-semibold text-secondary"}`}
                onClick={() => onPageChange(Number(number))}
              >
                {number}
              </button>
            )
          )}

          <button
            className={`text-secondary font-bold px-2 py-1 rounded ${currentPage === totalPages ? "" : "hover:bg-gray"}`}
            onClick={() => onPageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            <RightPagination />
          </button>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default Pagination;
