import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import SaveFilterModal from "./SaveFilterModal";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { getFiltersByUserId } from "../../app/api/savedFilterApis";
import CrossIcon from "../../assets/icons/CrossIconModal";
import { capitalizeFirstLetter } from "../../utils";
import moment from "moment";
interface Props {
  onCloseViewFilter: () => void;
  onCloseSavedFilter: () => void;
  filterType: string;
  setFilterType: React.Dispatch<React.SetStateAction<string>>;
  setGenre: React.Dispatch<React.SetStateAction<string>>;
  setProduct: React.Dispatch<React.SetStateAction<string>>;
  setBrands: React.Dispatch<React.SetStateAction<string[]>>;
  setGender: React.Dispatch<React.SetStateAction<string>>;
  setRegion: React.Dispatch<React.SetStateAction<string>>;
  setStartDate: React.Dispatch<React.SetStateAction<string>>;
  setEndDate: React.Dispatch<React.SetStateAction<string>>;
  setSource: React.Dispatch<React.SetStateAction<string>>;
  setCategory: React.Dispatch<React.SetStateAction<string>>;
  setColor: React.Dispatch<React.SetStateAction<string>>;
  setAgeGroup: React.Dispatch<React.SetStateAction<string>>;
  handleClearAll: () => void;
  filterName: string;
  setFilterId: React.Dispatch<React.SetStateAction<string>>;
  setFilterName: React.Dispatch<React.SetStateAction<string>>;
  setFilterSaved: React.Dispatch<React.SetStateAction<boolean>>;
  setDisableAfterSave: React.Dispatch<React.SetStateAction<boolean>>;
}

interface SavedFilter {
  FilterName: string;
  _id: string;
  Brand: string[];
  Category: string;
  Color: string;
  Gender: string;
  Genre: string;
  Product: string;
  Region: string;
  Source: string;
  StartDate: string;
  EndDate: string;
  createdAt: string;
  updatedAt: string;
}

const ViewFilterModal: React.FC<Props> = ({
  onCloseViewFilter,
  onCloseSavedFilter,
  filterType,
  setFilterType,
  setGenre,
  setProduct,
  setBrands,
  setGender,
  setRegion,
  setStartDate,
  setEndDate,
  setSource,
  setCategory,
  setColor,
  setAgeGroup,
  handleClearAll,
  filterName,
  setFilterId,
  setFilterName,
  setFilterSaved,
  setDisableAfterSave,
}) => {
  const [filters, setFilters] = useState<SavedFilter[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [saveViewFilterModalOpen, setSaveViewFilterModalOpen] =
    useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<string>("");
  const user = useSelector((state: RootState) => state.user.userInfo);
  const userId = user?.userId;
  useEffect(() => {
    const fetchFilters = async () => {
      if (!userId || !user) return;
      try {
        setIsLoading(true);
        const fetchedFilters = await getFiltersByUserId(
          userId,

          user
        );
        setFilters(fetchedFilters.data);
      } catch (error) {
        console.error("Error fetching filters:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchFilters();
  }, [userId]);

  return ReactDOM.createPortal(
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      {/* Modal Background */}
      <div
        className="fixed inset-0 bg-black opacity-50"
        onClick={onCloseViewFilter}
      ></div>

      <div
        className="relative bg-white rounded-lg shadow-lg z-40 font-poppins"
        style={{ width: "400px", height: "500px" }}
      >
        <div className="flex justify-between border-b border-gray-300">
          <div className="px-4 py-2 text-[24px] font-poppins font-semibold text-[#4D4D4D]">
            Filter List
          </div>
          <div className="px-4 py-4 cursor-pointer" onClick={onCloseViewFilter}>
            <CrossIcon width="20px" height="20px" />
          </div>
        </div>

        <div className="px-4 py-2 overflow-y-auto h-[420px]">
          {isLoading ? (
            <div className="space-y-4">
              {Array(7)
                .fill(0)
                .map((_, index) => (
                  <div
                    key={index}
                    className="flex items-center justify-between bg-gray81/50 rounded-xl"
                  >
                    <Skeleton width="60%" height={20} />
                    <Skeleton width="25%" height={30} />
                  </div>
                ))}
            </div>
          ) : filters.length > 0 ? (
            filters.map((filter, index) => (
              <div
                key={filter._id}
                className="flex relative items-center justify-between border-b border-gray-300 py-2 hover:bg-gray"
              >
                <div className="flex">
                  <div className="text-sm text-primary min-w-7">
                    {index + 1}.
                  </div>
                  <div className="text-sm text-primary w-[180px] truncate">
                    {capitalizeFirstLetter(filter.FilterName)}
                  </div>
                </div>
                <div className="absolute right-[58px] text-xs text-tertiary pl-3">
                  {moment(filter.createdAt).format("DD-MM-YYYY")}
                </div>
                <button
                  className="px-4 py-1 text-xs text-[#b18873] font-semibold  rounded-lg "
                  onClick={() => {
                    setSelectedId(filter._id);
                    setSaveViewFilterModalOpen(true);
                  }}
                >
                  View
                </button>
              </div>
            ))
          ) : (
            <div className="text-gray-500 text-sm text-center mt-10">
              No saved filters found.
            </div>
          )}
        </div>
      </div>
      {saveViewFilterModalOpen && (
        <SaveFilterModal
          onCloseSavedFilter={() => {
            setSaveViewFilterModalOpen(false);
          }}
          onCloseViewFilter={onCloseViewFilter}
          filterType={filterType}
          setFilterType={setFilterType}
          id={selectedId}
          region=""
          genre=""
          product=""
          gender=""
          brands={[]}
          startDate=""
          endDate=""
          source=""
          category=""
          color=""
          ageGroup=""
          setGenre={setGenre}
          setProduct={setProduct}
          setBrands={setBrands}
          setGender={setGender}
          setRegion={setRegion}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          setSource={setSource}
          setCategory={setCategory}
          setColor={setColor}
          setAgeGroup={setAgeGroup}
          handleClearAll={handleClearAll}
          filterId=""
          filterName={filterName}
          setFilterId={setFilterId}
          setFilterName={setFilterName}
          setFilterSaved={setFilterSaved}
          setDisableAfterSave={setDisableAfterSave}
        />
      )}
    </div>,
    document.body
  );
};

export default ViewFilterModal;
