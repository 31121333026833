import React, { useState, ChangeEvent } from "react";
import { addContent, addTrendContent } from "../../app/api/admin/content";
import { FeedItem, SelectedPost } from "../../interface/DesignerInterface";
import { toast } from "react-toastify";
import Modal from "../Common/Modal";
import CreateBoard from "../Boards/CreateBoard";
import {
  getBoardsByUserId,
  getBoardsSearchByAccessLevel,
} from "../../app/api/admin/board";
import { Board } from "../../interface/BoardsInterface";
import { ReactComponent as Search } from "../../assets/icons/search.svg";
import CheckBox from "../../assets/icons/CheckBox";
import CheckBoxChecked from "../../assets/icons/CheckBoxChecked";

type Props = {
  imageUrl?: string;
  selectedPost?: SelectedPost | null;
  publicBoards: Board[];
  setPublicBoards: React.Dispatch<React.SetStateAction<Board[]>>;
  privateBoards: Board[];
  setPrivateBoards: React.Dispatch<React.SetStateAction<Board[]>>;
  onClose: () => void;
  userId: string;
  fromTrendBoard?: boolean;
  data?: FeedItem[];
  setMultiSelectContentOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  trendSnapshotTrendsView?: boolean;
};

const SaveToBoard: React.FC<Props> = ({
  imageUrl,
  selectedPost,
  onClose,
  userId,
  publicBoards,
  setPublicBoards,
  privateBoards,
  setPrivateBoards,
  fromTrendBoard,
  data,
  setMultiSelectContentOpen,
  trendSnapshotTrendsView,
}) => {
  const [selectedBoards, setSelectedBoards] = useState<string[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [notes, setNotes] = useState<string>();
  const [searchString, setSearchString] = useState<string>("");
  const [disableButton, setDisableButton] = useState(false);

  const handleCheckboxChange = (boardId: string) => {
    if (selectedBoards.includes(boardId)) {
      setSelectedBoards(selectedBoards.filter(id => id !== boardId));
    } else {
      setSelectedBoards([...selectedBoards, boardId]);
    }
  };

  const handleSubmit = async () => {
    if (fromTrendBoard) {
      setDisableButton(true);

      const trendPostIds: string[] =
        data?.map((item: { postId: string }) => item.postId) || [];
      const chunkSize = 300; // Maximum number of postIds per API call
      const chunks: string[][] = [];

      // Split trendPostIds into chunks of 300
      for (let i = 0; i < trendPostIds.length; i += chunkSize) {
        chunks.push(trendPostIds.slice(i, i + chunkSize));
      }

      try {
        for (const chunk of chunks) {
          const trendContentPayload = {
            category_name: "Trends",
            board_ids: selectedBoards,
            postIds: chunk,
            notes: notes,
          };

          const res = await addTrendContent(trendContentPayload);
          if (res?.data?.message) {
            const isSingleImage = chunk.length === 1;
            const isSingleBoard = selectedBoards.length === 1;
            const isMultipleImages = chunk.length > 1;
            const isMultipleBoards = selectedBoards.length > 1;

            if (res.data.message.includes("Contents already exists")) {
              setDisableButton(false);
              return toast.warn("Content already exists.", {
                hideProgressBar: true,
                style: { width: "300px" },
              });
            }
            if (isSingleImage && isSingleBoard) {
              // Single image added to a single board
              toast.success("Content added successfully.", {
                hideProgressBar: true,
                style: { width: "300px" },
              });
            } else if (isMultipleImages && isSingleBoard) {
              // Multiple images added to a single board
              toast.success("Multiple contents added successfully.", {
                hideProgressBar: true,
                style: { width: "300px" },
              });
            } else if (
              (isSingleImage && isMultipleBoards) ||
              (isMultipleImages && isMultipleBoards)
            ) {
              // Single image added to multiple boards
              toast.success("Contents added successfully.", {
                hideProgressBar: true,
                style: { width: "300px" },
              });
            }
          }
        }

        setSelectedBoards([]);
        onClose();
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        toast.warn(error?.response.data.message, {
          hideProgressBar: true,
        });
      }
    } else {
      if (!selectedPost) {
        console.error("No post selected");
        return;
      }

      const postId = selectedPost?.postId.toString();
      const payload = {
        board_ids: selectedBoards,
        category_name: "Designer",
        postId: postId,
        notes: notes,
      };
      try {
        await addContent(payload);
        toast.success("Added content successfully", {
          hideProgressBar: true,
          style: { width: "270px" },
        });
        setSelectedBoards([]);
        onClose();
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        toast.warn(error?.response.data.message, {
          hideProgressBar: true,
        });
      }
    }
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const getBoards = async () => {
    const res = await getBoardsByUserId(userId);
    setPublicBoards(res?.data?.data?.public.boards);
    setPrivateBoards(res?.data?.data.private.boards);
  };

  const handleSearch = async (event: ChangeEvent<HTMLInputElement>) => {
    const searchStr = event.target.value;
    setSearchString(searchStr);
  };

  const filteredPublicBoards = publicBoards.filter(i =>
    i.board_name.toLowerCase().includes(searchString)
  );

  const filteredPrivateBoards = privateBoards.filter(i =>
    i.board_name.toLowerCase().includes(searchString)
  );
  return (
    <div className="flex justify-center items-center h-[60%] bg-gray-100">
      <div>
        <div className="flex">
          <div className="w-1/2 pr-4">
            <div className="relative">
              {fromTrendBoard ? (
                <div>
                  <div className="flex flex-row gap-2">
                    {[
                      ...(data?.filter(item => item?.image_link) ?? []),
                      ...Array(
                        Math.max(
                          0,
                          4 -
                            (data?.filter(item => item?.image_link)?.length ??
                              0)
                        )
                      ),
                    ]
                      .slice(0, 2)
                      .map((item, index) => (
                        <React.Fragment key={`row1-${index}`}>
                          {item?.image_link ? (
                            <img
                              src={`${process.env.REACT_APP_CLOUD_FRONT_URL}${item.image_link.substring(item.image_link.indexOf(".com/") + 5)}`}
                              alt={`Image ${index + 1}`}
                              className="rounded-lg mb-2 w-32 h-40"
                            />
                          ) : (
                            <div className="flex items-center justify-center border rounded-2xl bg-lightGray w-32 h-40">
                              <span className="text-xl font-bold text-secondary tracking-widest font-poppins">
                                ICH
                              </span>
                            </div>
                          )}
                        </React.Fragment>
                      ))}
                  </div>
                  <div className="flex flex-row gap-2">
                    {[
                      ...(data?.filter(item => item?.image_link) ?? []),
                      ...Array(
                        Math.max(
                          0,
                          4 -
                            (data?.filter(item => item?.image_link)?.length ??
                              0)
                        )
                      ),
                    ]
                      .slice(2, 4)
                      .map((item, index) => (
                        <React.Fragment key={`row2-${index}`}>
                          {item?.image_link ? (
                            <img
                              src={`${process.env.REACT_APP_CLOUD_FRONT_URL}${item.image_link.substring(item.image_link.indexOf(".com/") + 5)}`}
                              alt={`Image ${index + 1}`}
                              className="rounded-lg mb-2 w-32 h-40"
                            />
                          ) : (
                            <div className="flex items-center justify-center border rounded-2xl bg-lightGray w-32 h-40">
                              <span className="text-xl font-bold text-secondary tracking-widest font-poppins">
                                ICH
                              </span>
                            </div>
                          )}
                        </React.Fragment>
                      ))}
                  </div>
                  {(data?.filter(item => item?.image_link)?.length ?? 0) >
                    4 && (
                    <div
                      className="w-10 h-7 bg-white rounded-xl absolute right-1 bottom-4 flex justify-center cursor-pointer"
                      onClick={() => {
                        setMultiSelectContentOpen?.(true);
                        onClose();
                      }}
                    >
                      +
                      {(data?.filter(item => item?.image_link)?.length ?? 0) -
                        4}
                    </div>
                  )}
                </div>
              ) : (
                <img src={imageUrl} alt="image" className="rounded-lg mb-4" />
              )}
            </div>
            <label className="block text-gray-700 mb-2">
              Enter Notes (optional)
            </label>
            <textarea
              className="w-full border rounded-lg p-2  focus:border-black focus:outline-none  border-black"
              placeholder="Input Text"
              onChange={e => {
                setNotes(e.target.value);
              }}
            />
          </div>
          <div className="w-1/2 pl-4">
            <div className="flex items-center mb-4 relative">
              <Search className="ml-2 absolute" />
              <input
                type="text"
                className="w-full border rounded-lg p-2 border-black placeholder:cursor-pointer pl-10 focus:border-black focus:outline-none placeholder:text-[9e9e9e]"
                placeholder="Search Board"
                onChange={handleSearch}
                value={searchString}
              />
              <div className="ml-8 mr-2 -mt-[6px]">
                <div
                  className="text-2xl text-primary cursor-pointer text-center"
                  onClick={toggleModal}
                >
                  +
                </div>{" "}
              </div>
            </div>
            <div className="h-[335px] overflow-x-auto">
              {filteredPublicBoards.length === 0 &&
                filteredPrivateBoards.length === 0 && <div> No boards</div>}
              {filteredPublicBoards.length > 0 && (
                <>
                  <h3 className="text-gray-700 mb-2">Public</h3>
                  <div className="mb-2">
                    {filteredPublicBoards?.map(board => (
                      <div className="mb-2 flex flex-row gap-2" key={board.id}>
                        {selectedBoards.includes(board.id) ? (
                          <div
                            onClick={() => handleCheckboxChange(board.id)}
                            className="cursor-pointer"
                          >
                            <CheckBox />
                          </div>
                        ) : (
                          <div
                            onClick={() => handleCheckboxChange(board.id)}
                            className="cursor-pointer"
                          >
                            <CheckBoxChecked />{" "}
                          </div>
                        )}

                        <label htmlFor={board.id} className="text-gray-700">
                          {board.board_name}
                        </label>
                      </div>
                    ))}
                  </div>
                </>
              )}
              {filteredPrivateBoards.length > 0 && (
                <>
                  <h3 className="text-gray-700 mb-2 mt-4">Private</h3>
                  {filteredPrivateBoards.map(board => (
                    <div className="mb-2  flex flex-row gap-2" key={board.id}>
                      {selectedBoards.includes(board.id) ? (
                        <div
                          onClick={() => handleCheckboxChange(board.id)}
                          className="cursor-pointer"
                        >
                          <CheckBox />
                        </div>
                      ) : (
                        <div
                          onClick={() => handleCheckboxChange(board.id)}
                          className="cursor-pointer"
                        >
                          <CheckBoxChecked />{" "}
                        </div>
                      )}
                      <label htmlFor={board.id} className="text-gray-700">
                        {board.board_name}
                      </label>
                    </div>
                  ))}
                </>
              )}
            </div>
          </div>
        </div>
        <div className="mt-6">
          <button
            className={`w-full ${disableButton ? "bg-gray" : "bg-black"} text-white py-3 rounded-full`}
            onClick={() => {
              if (selectedBoards.length) handleSubmit();
            }}
            disabled={disableButton}
          >
            Add to board
            {selectedBoards.length > 0 && `(${selectedBoards.length})`}
          </button>
        </div>
      </div>

      {isModalOpen && (
        <Modal isOpen={isModalOpen} onClose={closeModal} title="Create Board">
          <CreateBoard
            onClose={closeModal}
            userId={userId}
            refresh={getBoards}
          />
        </Modal>
      )}
    </div>
  );
};

export default SaveToBoard;
