import React from "react";

type SelectionChipsProps = {
  value: string;
  selected?: boolean;
  onclick?: (material: string) => void;
};

const SelectionChips: React.FC<SelectionChipsProps> = ({
  value,
  selected = false,
  onclick,
}) => {
  return (
    <div
      className={`py-1 px-2 rounded-full ${selected ? "bg-gray81" : "bg-white"} border border-secondary text-[10px] ${onclick ? "mr-2 mb-2" : ""} ${onclick ? "cursor-pointer" : ""}`}
      onClick={() => onclick && onclick(value)}
    >
      {value}
    </div>
  );
};

export default SelectionChips;
