import React from "react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any, prettier/prettier
function CrossIconModal(props: any) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="#4D4D4D"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.9999 19L5.4999 18.5C5.1999 18.2 5.1999 17.7 5.4999 17.3L17.9999 5L18.4999 5.5C18.7999 5.8 18.7999 6.3 18.4999 6.7L5.9999 19Z"
        fill="#4D4D4D"
      />
      <path
        d="M17.9999 19L5.59994 6.6C5.29994 6.3 5.29994 5.8 5.59994 5.4L5.99994 5L18.3999 17.4C18.6999 17.7 18.6999 18.2 18.3999 18.6L17.9999 19Z"
        fill="#4D4D4D"
      />
    </svg>
  );
}

export default CrossIconModal;
