import { User } from "../../types";
import { admin, forecaster } from "./axios";

type ProductTypeParams = {
  category: string;
  gender: string;
};

export const getProductTypes = async (
  params: ProductTypeParams,
  user: User | null
) => {
  if (user === null) {
    throw new Error("User is not available");
  }
  const { group } = user!;
  if (group) {
    try {
      if (group === "admin") {
        const response = await admin.get("trends/filters/productTypes", {
          params,
        });
        return response.data;
      } else if (group === "forecaster") {
        const response = await forecaster.get("trends/filters/productTypes", {
          params,
        });
        return response.data;
      }
    } catch (error) {
      console.error("Error generating content:", error);
      throw error;
    }
  } else {
    console.error("Group Not Found");
  }
};

type StylingDetailsParams = {
  category: string;
  gender: string;
  productType: string;
};

export const getStylingDetails = async (
  params: StylingDetailsParams,
  user: User | null
) => {
  if (user === null) {
    throw new Error("User is not available");
  }
  const { group } = user!;
  if (group) {
    try {
      if (group === "admin") {
        const response = await admin.get("trends/filters/styling-details", {
          params,
        });
        return response.data;
      } else if (group === "forecaster") {
        const response = await forecaster.get(
          "trends/filters/styling-details",
          {
            params,
          }
        );
        return response.data;
      }
    } catch (error) {
      console.error("Error generating content:", error);
      throw error;
    }
  } else {
    console.error("Group Not Found");
  }
};
