import React from "react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function DeleteSnapshotIcon(props: any) {
  return (
    <svg
      width={props.width || "20"}
      height={props.height || "20"}
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.66675 5.16667H13.3334M6.66675 7.83333V11.8333M9.33341 7.83333V11.8333M3.33341 5.16667L4.00008 13.1667C4.00008 13.5203 4.14056 13.8594 4.39061 14.1095C4.64065 14.3595 4.97979 14.5 5.33341 14.5H10.6667C11.0204 14.5 11.3595 14.3595 11.6096 14.1095C11.8596 13.8594 12.0001 13.5203 12.0001 13.1667L12.6667 5.16667M6.00008 5.16667V3.16667C6.00008 2.98986 6.07032 2.82029 6.19534 2.69526C6.32037 2.57024 6.48994 2.5 6.66675 2.5H9.33341C9.51023 2.5 9.67979 2.57024 9.80482 2.69526C9.92984 2.82029 10.0001 2.98986 10.0001 3.16667V5.16667"
        stroke="#212121"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default DeleteSnapshotIcon;
