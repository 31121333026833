import { User } from "../../types";
import { admin, forecaster } from "./axios";

enum UserGroup {
  Admin = "admin",
  Forecaster = "forecaster",
}

const getAxiosInstance = (group: UserGroup) => {
  switch (group) {
    case UserGroup.Admin:
      return admin;
    case UserGroup.Forecaster:
      return forecaster;
    default:
      throw new Error("Invalid user group");
  }
};

export type SnapshotPayload = {
  name: string;
  ownerId: string;
  ownerName: string | undefined;
  genericFilters: {
    preferenceFilters: {
      startDate: string;
      endDate: string;
      region: string;
      genre: string;
      product: string;
      gender: string;
      brands: string[];
      source: string;
      category: string;
      color: string;
    };
    trendAnalysisFilters: {
      product_type: string;
      material: string;
      styling_details: string;
    };
  };
  images: string[];
  req_id: string;
};

export const createSnapshot = async (
  payload: SnapshotPayload,
  user: User | null
) => {
  if (!user) throw new Error("User is not available");

  try {
    const axiosInstance = getAxiosInstance(user.group as UserGroup);
    const response = await axiosInstance.post("snapshots", payload);
    return response.data;
  } catch (error) {
    console.error("Error creating snapshot:", error);
    throw error;
  }
};

type ListSnapshotParams = {
  userId: string;
  page: number;
  pageSize: number;
};

export const getListSnapshots = async (
  params: ListSnapshotParams,
  user: User | null
) => {
  if (!user) throw new Error("User is not available");

  try {
    const axiosInstance = getAxiosInstance(user.group as UserGroup);
    const response = await axiosInstance.get("snapshots", { params });
    return response.data;
  } catch (error) {
    console.error("Error fetching snapshot list:", error);
    throw error;
  }
};

type SnapshotDetailsParams = {
  page: number;
  numberOfTrends: number;
  postsPerTrend: number;
};

export const getSnapshotDetails = async (
  snapshotId: number,
  user: User | null,
  params: SnapshotDetailsParams
) => {
  if (!user) throw new Error("User is not available");

  try {
    const axiosInstance = getAxiosInstance(user.group as UserGroup);
    const response = await axiosInstance.get(`snapshots/${snapshotId}`, {
      params,
    });
    return response.data;
  } catch (error) {
    console.error(
      `Error fetching snapshot details for ID ${snapshotId}:`,
      error
    );
    throw error;
  }
};

interface shareSnapshotPayload {
  sharedWith: string;
  sharedBy: string;
}
export const shareSnapshot = async (
  snapshotId: number,
  user: User | null,
  params: shareSnapshotPayload
) => {
  if (!user) throw new Error("User is not available");

  try {
    const axiosInstance = getAxiosInstance(user.group as UserGroup);
    const response = await axiosInstance.post(
      `snapshots/${snapshotId}/share`,
      params
    );
    return response.data;
  } catch (error) {
    console.error(
      `Error fetching snapshot details for ID ${snapshotId}:`,
      error
    );
    throw error;
  }
};

export const getListUsers = async (user: User | null, search: string) => {
  if (!user) throw new Error("User is not available");
  try {
    const axiosInstance = getAxiosInstance(user.group as UserGroup);
    const response = await axiosInstance.get(
      `users?limit=200&page=1`,
      search ? { params: { search } } : {}
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching user list:", error);
    throw error;
  }
};

interface TrendsExpandedView {
  page: number;
  pageSize: number;
}
export const getTrendsExpandedViewSnapshot = async (
  snapshotId: number | undefined,
  user: User | null,
  trendsNumber: number | undefined,
  params: TrendsExpandedView
) => {
  if (!user) throw new Error("User is not available");

  try {
    const axiosInstance = getAxiosInstance(user.group as UserGroup);
    const response = await axiosInstance.get(
      `snapshots/${snapshotId}/trends/${trendsNumber}`,
      { params }
    );
    return response.data;
  } catch (error) {
    console.error(
      `Error fetching snapshot details for ID ${snapshotId}:`,
      error
    );
    throw error;
  }
};

export const deleteSnapshot = async (snapshotId: number, user: User | null) => {
  if (!user) throw new Error("User is not available");

  try {
    const axiosInstance = getAxiosInstance(user.group as UserGroup);
    const response = await axiosInstance.delete(`snapshots/${snapshotId}`);
    return response.data;
  } catch (error) {
    console.error("Error deleting snapshot:", error);
    throw error;
  }
};

export const isSnapshotAlreadyShared = async (
  snapshotId: number,
  sharedWithId: string,
  user: User | null
) => {
  if (!user) throw new Error("User is not available");

  try {
    const axiosInstance = getAxiosInstance(user.group as UserGroup);
    const response = await axiosInstance.get(
      `snapshots/${snapshotId}/share/${sharedWithId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching snapshot details for ID:", error);
    throw error;
  }
};
