import React from "react";
import ReactDOM from "react-dom";
import CrossIcon from "../../assets/icons/CrossIcon";
import { DeleteFilter } from "../../app/api/savedFilterApis";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { toast } from "react-toastify";
interface Props {
  closeDeleteModal: () => void;
  closeViewModal: () => void;
  id: string;
  setFilterId: React.Dispatch<React.SetStateAction<string>>;
  setFilterName: React.Dispatch<React.SetStateAction<string>>;
  setFilterSaved: React.Dispatch<React.SetStateAction<boolean>>;
}
const DeleteFilterModal: React.FC<Props> = ({
  closeDeleteModal,
  closeViewModal,
  id,
  setFilterId,
  setFilterName,
  setFilterSaved,
}) => {
  const user = useSelector((state: RootState) => state.user.userInfo);
  const userId = user?.userId;
  const closeModal = () => {
    closeDeleteModal();
  };
  const handleDelete = async () => {
    try {
      await DeleteFilter(id, userId, user);
      toast.success("Filter deleted successfully", {
        hideProgressBar: true,
      });
      setFilterSaved(true);
      setFilterId("");
      setFilterName("");
      closeViewModal();
      closeDeleteModal();
    } catch (error) {
      toast.error("Error while deleting filter, please try again", {
        hideProgressBar: true,
      });
    }
  };
  return ReactDOM.createPortal(
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div
        className="fixed inset-0 bg-black opacity-50"
        onClick={closeModal}
      ></div>

      <div
        className="relative bg-white rounded-lg shadow-sm z-60 font-poppins"
        style={{ width: "400px", height: "188px", overflow: "hidden" }}
      >
        <div className="flex flex-col p-2">
          {/* Option 1: Edit Current Filter */}
          <div className="mb-1">
            <h1 className="text-[20px] font-bold font-poppins">
              Are you sure you want to delete the filter?
            </h1>

            <label className="text-sm font-normal font-poppins">
              Once removed the content will be lost and cannot be recovered.
            </label>
          </div>
        </div>

        {/* Add a full-width border here */}
        <div className="border-t border-disabled"></div>

        {/* Save Button */}
        <div className="flex justify-center pt-2 gap-10">
          <div
            className="font-poppins text-sm border border-gray text-gray rounded-full px-6 py-2 cursor-pointer"
            onClick={closeModal}
          >
            Close
          </div>
          <div
            className="cursor-pointer font-poppins text-sm bg-black rounded-full text-white px-6 py-2"
            onClick={handleDelete}
          >
            Confirm
          </div>
        </div>
      </div>
    </div>,
    document.body
  );
};

export default DeleteFilterModal;
