import React from "react";
import ReactDOM from "react-dom";
import CrossIcon from "../../assets/icons/CrossIcon";

interface Props {
  closeWarningModal: () => void;
  closeSaveModal: () => void;
  closeViewModal: () => void;
}
const SaveChangesWarningModal: React.FC<Props> = ({
  closeWarningModal,
  closeSaveModal,
  closeViewModal,
}) => {
  const closeModal = () => {
    closeWarningModal();
  };
  const handleConfirm = () => {
    closeWarningModal();
    closeSaveModal();
    closeViewModal();
  };

  return ReactDOM.createPortal(
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div
        className="fixed inset-0 bg-black opacity-50"
        onClick={closeModal}
      ></div>

      <div
        className=" relative bg-white rounded-lg shadow-sm z-60 font-poppins"
        style={{ width: "400px", height: "198px", overflow: "hidden" }}
      >
        <div className="flex flex-col p-3">
          {/* Option 1: Edit Current Filter */}
          <div className="mb-4">
            <div className="flex justify-between">
              <h1 className="text-[20px] font-bold font-poppins text-primary">
                Are you sure you don't want to save this filter?
              </h1>
            </div>
            <div className="text-sm font-normal font-poppins mt-3">
              Once closed the content will be lost and cannot be recovered.
            </div>
          </div>
          {/* Save Button */}
          <div className="flex justify-center gap-10 border-t-1 border-disabled pt-2">
            <div
              className="font-poppins text-sm  text-tertiary font-bold  rounded-full  px-6 py-2 cursor-pointer"
              onClick={closeModal}
            >
              Cancel
            </div>
            <div
              className="cursor-pointer font-poppins font-bold text-sm bg-primary rounded-full text-white px-6 py-2"
              onClick={handleConfirm}
            >
              Confirm
            </div>
          </div>
        </div>
      </div>
    </div>,
    document.body
  );
};

export default SaveChangesWarningModal;
