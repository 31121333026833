import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import {
  DeleteFilter,
  getFilterById,
  saveFilter,
  updateFilter,
} from "../../app/api/savedFilterApis";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import moment from "moment";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import CrossIcon from "../../assets/icons/CrossIcon";
import ModalSkeleton from "./ModalSkeleton";
import DeleteFilterModal from "./DeleteFilterModal";
import SaveChangesWarningModal from "./SaveChangesWarningModal";
import { capitalizeFirstLetter } from "../../utils";

interface Props {
  onCloseSavedFilter: () => void;
  onCloseViewFilter: () => void;
  filterType: string;
  setFilterType: React.Dispatch<React.SetStateAction<string>>;
  region: string;
  genre: string;
  product: string;
  gender: string;
  brands: string[];
  startDate: string;
  endDate: string;
  source: string;
  category: string;
  color: string;
  ageGroup: string;
  id: string;
  setGenre: React.Dispatch<React.SetStateAction<string>>;
  setProduct: React.Dispatch<React.SetStateAction<string>>;
  setGender: React.Dispatch<React.SetStateAction<string>>;
  setRegion: React.Dispatch<React.SetStateAction<string>>;
  setBrands: React.Dispatch<React.SetStateAction<string[]>>;
  setStartDate: React.Dispatch<React.SetStateAction<string>>;
  setEndDate: React.Dispatch<React.SetStateAction<string>>;
  setSource: React.Dispatch<React.SetStateAction<string>>;
  setCategory: React.Dispatch<React.SetStateAction<string>>;
  setColor: React.Dispatch<React.SetStateAction<string>>;
  setAgeGroup: React.Dispatch<React.SetStateAction<string>>;
  handleClearAll: () => void;
  filterId: string;
  filterName: string;
  setFilterId: React.Dispatch<React.SetStateAction<string>>;
  setFilterName: React.Dispatch<React.SetStateAction<string>>;
  setFilterSaved: React.Dispatch<React.SetStateAction<boolean>>;
  setDisableAfterSave: React.Dispatch<React.SetStateAction<boolean>>;
}
const SaveFilterModal: React.FC<Props> = ({
  onCloseSavedFilter,
  onCloseViewFilter,
  filterType,
  setFilterType,
  region,
  genre,
  product,
  gender,
  brands,
  startDate,
  endDate,
  source,
  category,
  color,
  ageGroup,
  id,
  setGenre,
  setProduct,
  setGender,
  setRegion,
  setBrands,
  setStartDate,
  setEndDate,
  setSource,
  setCategory,
  setColor,
  setAgeGroup,
  handleClearAll,
  filterId,
  filterName,
  setFilterId,
  setFilterName,
  setFilterSaved,
  setDisableAfterSave,
}) => {
  const [savedGenre, setSavedGenre] = useState<string>("");
  const [savedProduct, setSavedProduct] = useState<string>("");
  const [savedGender, setSavedGender] = useState<string>("");
  const [savedRegion, setSavedRegion] = useState<string>("");
  const [savedBrands, setSavedBrands] = useState<string[]>([]);
  const [savedStartDate, setSavedStartDate] = useState<string>("");
  const [savedEndDate, setSavedEndDate] = useState<string>("");
  const [savedSource, setSavedSource] = useState<string>("");
  const [savedCategory, setSavedCategory] = useState<string>("");
  const [savedColor, setSavedColor] = useState<string>("");
  const [savedAgeGroup, setSavedAgeGroup] = useState<string>("");
  const [savedFilterName, setSavedFilterName] = useState<string>("");
  const [editFilter, setEditFilter] = useState<boolean>(false);
  const [originalFilterName, setOriginalFilterName] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [visibleDate, setVisibleDate] = useState<string>("");
  const [saveChangesWarningModal, setSaveChangeWarningModal] =
    useState<boolean>(false);
  const [editChange, setEditChange] = useState<boolean>(false);
  const [deleteFilterModal, setDeleteFilterModal] = useState<boolean>(false);
  const user = useSelector((state: RootState) => state.user.userInfo);
  const userId = user?.userId;

  useEffect(() => {
    const getFilterData = async () => {
      try {
        setIsLoading(true);
        const filterData = await getFilterById(id, user);
        setSavedGenre(filterData.Genre);
        setSavedProduct(filterData.Product);
        setSavedGender(filterData.Gender);
        setSavedRegion(filterData.Region);
        setSavedBrands(filterData.Brand);

        setSavedStartDate(filterData.StartDate);
        setSavedEndDate(filterData.EndDate);
        setSavedSource(filterData.Source);
        setSavedCategory(filterData.Category);
        setSavedColor(filterData.Color);
        setSavedAgeGroup(filterData.AgeGroup);
        setSavedAgeGroup(filterData.AgeGroup);
        setSavedFilterName(filterData.FilterName);
        setOriginalFilterName(filterData.FilterName);
      } catch (error) {
        toast.error("Error while getting filter data, please try again", {
          hideProgressBar: true,
        });
      } finally {
        setIsLoading(false);
      }
    };
    if (filterType === "view") {
      getFilterData();
    } else if (filterType === "save") {
      setSavedBrands(brands);
      setSavedGenre(genre);
      setSavedProduct(product);
      setSavedGender(gender);
      setSavedRegion(region);
      setSavedStartDate(startDate);
      setSavedEndDate(endDate);
      setSavedSource(source);
      setSavedCategory(category);
      setSavedColor(color);
      setSavedAgeGroup(ageGroup);
    } else if (filterType === "edit") {
      setSavedBrands(brands);
      setSavedGenre(genre);
      setSavedProduct(product);
      setSavedGender(gender);
      setSavedRegion(region);

      setSavedStartDate(startDate);
      setSavedEndDate(endDate);
      setSavedSource(source);
      setSavedCategory(category);
      setSavedColor(color);
      setSavedAgeGroup(ageGroup);
      setSavedFilterName(filterName);
      setOriginalFilterName(filterName);
    }
  }, []);

  const handleSubmit = async () => {
    if (filterType === "save" && editFilter) {
      const payload = {
        Genre: savedGenre,
        Product: savedProduct,
        Gender: savedGender,
        Region: savedRegion,
        Brand: savedBrands,
        StartDate: savedStartDate ? savedStartDate : "",
        EndDate: savedEndDate ? savedEndDate : "",
        Source: savedSource,
        Category: savedCategory,
        Color: savedColor,
        AgeGroup: savedAgeGroup,
        FilterName: savedFilterName,
      };
      if (!savedFilterName.trim()) {
        toast.error("Please enter a filter name", {
          hideProgressBar: true,
        });
        return;
      }
      try {
        await updateFilter(id, payload, user, userId);
        toast.success("Filter updated successfully", {
          hideProgressBar: true,
        });
        setDisableAfterSave(true);
        setFilterId("");
        setFilterName("");
      } catch (error) {
        console.error("Error updating filter:", error);
        toast.error("Error while updating filter, please try again", {
          hideProgressBar: true,
        });
      }
      onCloseSavedFilter();
      onCloseViewFilter();
    } else if (filterType === "save") {
      if (!savedFilterName.trim()) {
        toast.error("Please enter a filter name", {
          hideProgressBar: true,
        });
        return;
      }
      const payload = {
        Genre: savedGenre,
        Product: savedProduct,
        Gender: savedGender,
        Region: savedRegion,
        Brand: savedBrands,
        StartDate: savedStartDate,
        EndDate: savedEndDate,
        Source: savedSource,
        Category: savedCategory,
        Color: savedColor,
        AgeGroup: savedAgeGroup,
        FilterName: savedFilterName,
      };
      try {
        const response = await saveFilter(payload, user, userId);
        toast.success("Filter saved successfully", {
          hideProgressBar: true,
        });
        setFilterSaved(true);
        onCloseSavedFilter();
        setDisableAfterSave(true);
      } catch (error: unknown) {
        if (error instanceof AxiosError) {
          toast.error("Duplicate filter name", {
            hideProgressBar: true,
          });
          return;
        }
        toast.error("Error while saving filter, please try again", {
          hideProgressBar: true,
        });
        console.error(error);
      }
    } else if (filterType === "view") {
      // apply the filter and close the modal

      handleClearAll();
      setFilterId(id);
      setFilterName(savedFilterName);
      if (savedStartDate) {
        const formattedStartDate = moment(savedStartDate, "YYYY-MM-DD").format(
          "DD-MM-YYYY"
        );
        setStartDate(savedStartDate);
      }

      // Set end date with formatting
      if (savedEndDate) {
        const formattedEndDate = moment(savedEndDate, "YYYY-MM-DD").format(
          "DD-MM-YYYY"
        );
        setEndDate(savedEndDate);
      }

      setGenre(savedGenre);

      setSource(savedSource);

      setProduct(savedProduct);

      setGender(savedGender);

      setRegion(savedRegion);

      setCategory(savedCategory);
      setAgeGroup(savedAgeGroup);
      setColor(savedColor);
      setBrands([...savedBrands]);
      onCloseSavedFilter();
      onCloseViewFilter();
    } else if (filterType === "edit") {
      const payload = {
        Genre: savedGenre,
        Product: savedProduct,
        Gender: savedGender,
        Region: savedRegion,
        Brand: savedBrands,
        StartDate: savedStartDate,
        EndDate: savedEndDate,
        Source: savedSource,
        Category: savedCategory,
        Color: savedColor,
        AgeGroup: savedAgeGroup,
        FilterName: savedFilterName,
      };
      try {
        await saveFilter(payload, user, userId);
        toast.success("Filter updated successfully", {
          hideProgressBar: true,
        });
        setFilterId("");
        setFilterName("");
        setDisableAfterSave(true);
      } catch (error) {
        console.error("Error updating filter:", error);
        toast.error("Duplicate filter name", {
          hideProgressBar: true,
        });
      }
      onCloseSavedFilter();
      onCloseViewFilter();
    }
  };
  const handleAction = async () => {
    if (filterType === "save") {
      setSaveChangeWarningModal(true);
    } else if (filterType === "view") {
      setDeleteFilterModal(true);
    } else {
      setSaveChangeWarningModal(true);
    }
  };

  const handleFilterNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setSavedFilterName(newValue);
    if (newValue === "") {
      return;
    }
    if (filterType === "view" && newValue !== originalFilterName) {
      setEditFilter(true);
      setFilterType("save");
    } else if (filterType === "save" && newValue === originalFilterName) {
      setEditFilter(false);
      setFilterType("view");
    } else if (filterType === "edit" && newValue === originalFilterName) {
      setEditChange(false);
    } else if (filterType === "edit" && newValue !== originalFilterName) {
      setEditChange(true);
    }
  };
  const closeModal = () => {
    if (filterType === "save") {
      setSaveChangeWarningModal(true);
    } else if (filterType === "view") {
      onCloseSavedFilter();
    } else {
      setSaveChangeWarningModal(true);
    }
  };

  return ReactDOM.createPortal(
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div className="fixed inset-0 bg-black opacity-50"></div>

      <div
        className="relative bg-white rounded-lg shadow-sm z-60 font-poppins"
        style={{ width: "530px", height: "616px", overflow: "hidden" }}
      >
        <div className="pl-6 pt-2 pr-6 pb-2 border border-b-disabled">
          <div className="flex justify-between">
            <div className="font-poppins text-[24px] font-bold text-[#353E43]">
              {filterType === "save" ? "Save Filter" : " Filter"}
            </div>
            <div className="cursor-pointer pt-2" onClick={closeModal}>
              <CrossIcon width="20px" height="20px" />
            </div>
          </div>
        </div>
        {isLoading ? (
          <>
            <ModalSkeleton />
          </>
        ) : (
          <>
            <div className=" h-[500px] gap-[32px] pt-4 px-6 pb-4">
              <div className="font-poppins text-[14px] text-secondary">
                Name Filter
                <span className="text-red-500 ml-1">*</span>
              </div>
              <input
                type="text"
                placeholder="Enter name"
                className="w-[486px]  rounded-lg border text-secondary border-gray-300 p-2 text-xs"
                value={savedFilterName}
                onChange={e => {
                  handleFilterNameChange(e);
                }}
              />
              <div className="h-[410px] flex flex-col flex-wrap gap-4 mt-3">
                <div>
                  <div className="font-poppins  text-secondary text-[14px] text-sm">
                    Date Range
                  </div>
                  <div className="flex gap-2">
                    <span className=" px-4 py-2 text-secondary text-xs rounded-lg bg-inactive ">
                      {savedStartDate
                        ? moment(savedStartDate, "YYYY-MM-DD").format(
                            "DD-MM-YYYY"
                          )
                        : "Start Date"}
                    </span>
                    <span className=" px-4 py-2 text-secondary text-xs rounded-lg bg-inactive ">
                      {savedEndDate
                        ? moment(savedEndDate, "YYYY-MM-DD").format(
                            "DD-MM-YYYY"
                          )
                        : "End Date"}
                    </span>
                  </div>
                </div>
                <div className="">
                  <label className="block  text-secondary text-sm font-poppins text-[14px] mb-1">
                    Select Gender
                  </label>
                  <div className="flex space-x-4">
                    <label className="inline-flex items-center">
                      <input
                        type="checkbox"
                        className="form-radio"
                        name="gender"
                        value="Men"
                        style={{ accentColor: "gray" }}
                        checked={savedGender === "Men"}
                      />
                      <span className="ml-2 mt-1 text-sm text-secondary">
                        Male
                      </span>
                    </label>
                    <label className="inline-flex items-center">
                      <input
                        type="checkbox"
                        className="form-radio"
                        name="gender"
                        value="Women"
                        style={{ accentColor: "gray" }}
                        checked={savedGender === "Women"}
                      />
                      <span className="ml-2 mt-1 text-sm text-secondary">
                        Female
                      </span>
                    </label>
                  </div>
                </div>
                <div className="">
                  <label className="block text-secondary text-sm  mb-1">
                    Select Age Group
                  </label>
                  <div className="flex space-x-4">
                    <label className="inline-flex items-center">
                      <input
                        type="checkbox"
                        className="form-radio"
                        name="ageGroup"
                        value="Adult"
                        checked={savedAgeGroup === "Adult"}
                        style={{ accentColor: "gray" }}
                      />
                      <span className="ml-2 mt-1 text-sm text-secondary">
                        Adult
                      </span>
                    </label>
                    <label className="inline-flex items-center">
                      <input
                        type="checkbox"
                        className="form-radio"
                        name="ageGroup"
                        value="Youth"
                        checked={savedAgeGroup === "Youth"}
                        style={{ accentColor: "gray" }}
                      />
                      <span className="ml-2 mt-1 text-sm text-secondary">
                        Youth
                      </span>
                    </label>
                  </div>
                </div>
                <div>
                  <div className="font-poppins text-[14px] text-secondary text-sm">
                    Genre
                  </div>
                  <div
                    className=" px-3 h-[32px] py-2 text-secondary text-xs rounded-lg bg-inactive
              w-[209px]"
                  >
                    {savedGenre || "None"}
                  </div>
                </div>
                <div>
                  <div className="font-poppins text-secondary text-[14px] text-sm">
                    Category
                  </div>
                  <div
                    className=" px-3 py-2 text-secondary text-xs rounded-lg bg-inactive
              h-[32px] w-[209px]"
                  >
                    {savedCategory || "None"}
                  </div>
                </div>
                <div>
                  <div className="font-poppins text-secondary text-[14px] text-sm">
                    Product
                  </div>
                  <div
                    className=" px-3 py-2 bg text-secondary text-xs rounded-lg  
              h-[32px] w-[209px] bg-inactive"
                  >
                    {savedProduct || "None"}
                  </div>
                </div>
                <div>
                  <div className="font-poppins text-secondary text-[14px] text-sm">
                    Sources
                  </div>
                  <div
                    className=" px-3 py-1 bg-inactive text-secondary text-xs rounded-lg  
              h-[28px]  w-full"
                  >
                    {savedSource || "None"}
                  </div>
                </div>
                <div>
                  <div className="font-poppins text-[14px] text-secondary text-sm">
                    Region
                  </div>
                  <div
                    className=" px-3 py-[6px] bg-inactive text-secondary text-xs rounded-lg  
              h-[29px] w-[260px]"
                  >
                    {savedRegion || "None"}
                  </div>
                </div>
                <div className="">
                  <div className="font-poppins text-[14px] text-secondary text-sm">
                    Color Code
                  </div>
                  <div
                    className=" px-3 py-2 bg-inactive  text-secondary text-xs rounded-lg 
              h-[32px] w-[260px]"
                  >
                    {savedColor || "None"}
                  </div>
                </div>
                <div>
                  <div className="font-poppins text-[14px] text-secondary text-sm">
                    Brands
                  </div>
                  <div
                    className={`relative bg-inactive px-1 py-2 text-secondary  rounded-lg  h-[167px] w-[260px] overflow-auto justify-start`}
                  >
                    {savedBrands.length > 0 ? (
                      <>
                        <div className="flex-wrap flex items-center gap-2">
                          {savedBrands
                            .sort((a, b) => a.length - b.length)
                            .map((brand, index) => (
                              <span
                                key={index}
                                className="px-1 text-xs font-medium py-1 bg-gray-200 text-secondary rounded-full shadow-sm border border-disabled"
                              >
                                {brand}
                              </span>
                            ))}
                        </div>
                      </>
                    ) : (
                      <span className="px-2 text-[14px] py-2">None</span>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex align-center border border-t-disabled  justify-center gap-8 shadow-top-md ">
              <button
                type="button"
                className={`font-poppins  text-sm px-10 py-2 m-2 rounded-full font-bold pl-1 ${
                  filterType === "save" && editFilter
                    ? "text-negative"
                    : filterType === "save"
                      ? "text-tertiary"
                      : filterType === "edit"
                        ? "text-negative"
                        : "text-negative"
                } w-[200px] h-[46px]`}
                onClick={handleAction}
              >
                {filterType === "save" && editFilter
                  ? "Discard"
                  : filterType === "save"
                    ? "Close"
                    : filterType === "edit"
                      ? "Discard"
                      : "Delete"}
              </button>
              <button
                type="button"
                className={`font-poppins text-sm  m-2 font-semibold text-white px-10 py-2 rounded-full disabled:bg-inactive bg-primary w-[200px] h-[46px]`}
                disabled={
                  savedFilterName === "" ||
                  (filterType === "edit" && !editChange)
                }
                onClick={handleSubmit}
              >
                {filterType === "save"
                  ? "Save"
                  : filterType === "edit"
                    ? "Confirm"
                    : filterType === "view"
                      ? "Apply"
                      : ""}
              </button>
            </div>
          </>
        )}
      </div>
      {deleteFilterModal && (
        <DeleteFilterModal
          closeDeleteModal={() => {
            setDeleteFilterModal(false);
          }}
          id={id}
          setFilterId={setFilterId}
          setFilterName={setFilterName}
          closeViewModal={onCloseViewFilter}
          setFilterSaved={setFilterSaved}
        />
      )}
      {saveChangesWarningModal && (
        <SaveChangesWarningModal
          closeWarningModal={() => {
            setSaveChangeWarningModal(false);
          }}
          closeSaveModal={onCloseSavedFilter}
          closeViewModal={onCloseViewFilter}
        />
      )}
    </div>,
    document.body
  );
};

export default SaveFilterModal;
