import React, { useEffect, useState } from "react";
import SaveFilterModal from "./SaveFilterModal";
import ViewFilterModal from "./ViewFilterModal";
import EditFilterModal from "./EditFilterModal";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import {
  getFilterById,
  getFiltersByUserId,
} from "../../app/api/savedFilterApis";
import moment from "moment";
import { toast } from "react-toastify";

interface savedFilterProps {
  setGenre: React.Dispatch<React.SetStateAction<string>>;
  setProduct: React.Dispatch<React.SetStateAction<string>>;
  setBrands: React.Dispatch<React.SetStateAction<string[]>>;
  setGender: React.Dispatch<React.SetStateAction<string>>;
  setRegion: React.Dispatch<React.SetStateAction<string>>;
  setStartDate: React.Dispatch<React.SetStateAction<string>>;
  setEndDate: React.Dispatch<React.SetStateAction<string>>;
  setSource: React.Dispatch<React.SetStateAction<string>>;
  setCategory: React.Dispatch<React.SetStateAction<string>>;
  setColor: React.Dispatch<React.SetStateAction<string>>;
  setAgeGroup: React.Dispatch<React.SetStateAction<string>>;
  region: string;
  genre: string;
  product: string;
  gender: string;
  brands: string[];
  startDate: string;
  endDate: string;
  source: string;
  category: string;
  color: string;
  ageGroup: string;
  isCollapsed: boolean;
  handleClearAll: () => void;
  filterId: string;
  filterName: string;
  setFilterId: React.Dispatch<React.SetStateAction<string>>;
  setFilterName: React.Dispatch<React.SetStateAction<string>>;
  disableAfterSave: boolean;
  setDisableAfterSave: React.Dispatch<React.SetStateAction<boolean>>;
}

const SaveFilter: React.FC<savedFilterProps> = ({
  setGenre,
  setProduct,
  setBrands,
  setGender,
  setRegion,
  setStartDate,
  setEndDate,
  setSource,
  setCategory,
  setColor,
  setAgeGroup,
  region,
  genre,
  product,
  gender,
  brands,
  startDate,
  endDate,
  source,
  category,
  color,
  ageGroup,
  isCollapsed,
  handleClearAll,
  filterId,
  filterName,
  setFilterId,
  setFilterName,
  disableAfterSave,
  setDisableAfterSave,
}) => {
  const [filterType, setFilterType] = useState<string>("");
  const [isSaveFilterModalOpen, setSaveFilterModalOpen] =
    useState<boolean>(false);
  const [isViewFilterModalOpen, setViewFilterModalOpen] =
    useState<boolean>(false);
  const [filters, setFilters] = useState<unknown[]>([]);
  const [saveButtonActive, setSaveButtonActive] = useState<boolean>(false);
  const [isEditFilterModalOpen, setEditFilterOpen] = useState<boolean>(false);
  const [filterRegion, setFilterRegion] = useState<string>("");
  const [filterGenre, setFilterGenre] = useState<string>("");
  const [filterProduct, setFilterProduct] = useState<string>("");
  const [filterGender, setFilterGender] = useState<string>("");
  const [filterBrands, setFilterBrands] = useState<string[]>([]);
  const [filterStartDate, setFilterStartDate] = useState<string>("");
  const [filterEndDate, setFilterEndDate] = useState<string>("");
  const [filterSource, setFilterSource] = useState<string>("");
  const [filterColor, setFilterColor] = useState<string>("");
  const [filterCategory, setFilterCategory] = useState<string>("");
  const [filterAgeGroup, setFilterAgeGroup] = useState<string>("");
  const [filterSaved, setFilterSaved] = useState<boolean>(false);
  const user = useSelector((state: RootState) => state.user.userInfo);
  const userId = user?.userId;
  useEffect(() => {
    if (filterId) {
      const getFilterData = async () => {
        try {
          const filterData = await getFilterById(filterId, user);
          setFilterRegion(filterData.Region);
          setFilterGenre(filterData.Genre);
          setFilterProduct(filterData.Product);
          setFilterGender(filterData.Gender);
          setFilterBrands(filterData.Brand);
          setFilterStartDate(filterData.StartDate);
          setFilterEndDate(filterData.EndDate);
          setFilterSource(filterData.Source);
          setFilterColor(filterData.Color);
          setFilterName(filterData.FilterName);
          setFilterCategory(filterData.Category);
          setFilterAgeGroup(filterData.AgeGroup);
        } catch (error) {
          console.error(error);
        }
      };
      getFilterData();
    }
  }, [filterId]);
  const handleViewFilter = () => {
    setFilterType("view");
    setViewFilterModalOpen(true);
  };

  useEffect(() => {
    const getFilterForUser = async () => {
      try {
        const filtersData = await getFiltersByUserId(userId, user);
        setFilters(filtersData.data);
        setFilterSaved(false);
      } catch (error) {
        console.error(error);
      }
    };
    getFilterForUser();
  }, [filterSaved]);

  // To decide if save button is active
  useEffect(() => {
    if (
      region ||
      genre ||
      product ||
      gender ||
      brands.length > 0 ||
      startDate ||
      endDate ||
      source ||
      category ||
      color ||
      ageGroup
    ) {
      setDisableAfterSave(false);
      setSaveButtonActive(true);
    } else {
      setSaveButtonActive(false);
    }
  }, [
    region,
    genre,
    product,
    gender,
    brands,
    startDate,
    endDate,
    source,
    category,
    color,
    ageGroup,
  ]);
  const handleSaveFilter = () => {
    //To check wether the filter is edited or not

    if (
      filterId &&
      (filterRegion !== region ||
        filterGenre !== genre ||
        filterProduct !== product ||
        filterGender !== gender ||
        filterCategory !== category ||
        JSON.stringify(filterBrands) !== JSON.stringify(brands) ||
        filterStartDate !== startDate ||
        filterEndDate !== endDate ||
        filterSource !== source ||
        filterColor !== color ||
        filterAgeGroup !== ageGroup)
    ) {
      setEditFilterOpen(true);
    } else if (filterId) {
      toast.error("Please Edit the Filter Before Saving Again", {
        hideProgressBar: true,
      });
    } else {
      setFilterType("save");
      setSaveFilterModalOpen(true);
    }
  };
  return (
    <div
      className={`absolute left-0 bottom-0 bg-white border-t-2 border-[#E9E9E9]  z-50  mt-4 shadow-lg ${isCollapsed ? "hidden" : "block"}`}
    >
      <div className="w-[195px] h-[34px]   flex">
        <button
          className={`font-poppins w-[90px] text-sm pl-2 p-1  font-semibold  m-1   rounded-full cursor-pointer ${saveButtonActive ? "bg-[#4d4d4d] text-white" : "text-tertiary bg-inactive"} disabled:bg-inactive disabled:text-tertiary`}
          onClick={handleSaveFilter}
          disabled={!saveButtonActive || disableAfterSave}
        >
          Save Filter
        </button>
        <button
          className={`font-poppins w-[90px] font-semibold  text-sm p-1 pl-2 m-1 rounded-xl cursor-pointer ${
            filters.length === 0 ? "text-tertiary" : "text-[#4d4d4d]"
          }`}
          onClick={handleViewFilter}
          disabled={filters.length === 0}
        >
          View Filter
        </button>
      </div>

      {isSaveFilterModalOpen && (
        <SaveFilterModal
          filterType={filterType}
          setFilterType={setFilterType}
          onCloseViewFilter={() => setViewFilterModalOpen(false)}
          onCloseSavedFilter={() => setSaveFilterModalOpen(false)}
          region={region}
          genre={genre}
          product={product}
          gender={gender}
          brands={brands}
          startDate={startDate}
          endDate={endDate}
          source={source}
          category={category}
          color={color}
          ageGroup={ageGroup}
          id=""
          setGenre={setGenre}
          setProduct={setProduct}
          setGender={setGender}
          setRegion={setRegion}
          setBrands={setBrands}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          setSource={setSource}
          setCategory={setCategory}
          setColor={setColor}
          setAgeGroup={setAgeGroup}
          handleClearAll={handleClearAll}
          filterId={filterId}
          filterName={filterName}
          setFilterId={setFilterId}
          setFilterName={setFilterName}
          setDisableAfterSave={setDisableAfterSave}
          setFilterSaved={setFilterSaved}
        />
      )}
      {isViewFilterModalOpen && (
        <ViewFilterModal
          filterType={filterType}
          setFilterType={setFilterType}
          onCloseSavedFilter={() => setSaveFilterModalOpen(false)}
          onCloseViewFilter={() => setViewFilterModalOpen(false)}
          setGenre={setGenre}
          setProduct={setProduct}
          setGender={setGender}
          setRegion={setRegion}
          setBrands={setBrands}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          setSource={setSource}
          setCategory={setCategory}
          setColor={setColor}
          setAgeGroup={setAgeGroup}
          handleClearAll={handleClearAll}
          filterName={filterName}
          setFilterId={setFilterId}
          setFilterName={setFilterName}
          setFilterSaved={setFilterSaved}
          setDisableAfterSave={setDisableAfterSave}
        />
      )}
      {isEditFilterModalOpen && (
        <EditFilterModal
          onCloseEditFilter={() => {
            setEditFilterOpen(false);
          }}
          setSaveFilterModalOpen={setSaveFilterModalOpen}
          isSaveFilterModalOpen={isSaveFilterModalOpen}
          filterType={filterType}
          setFilterType={setFilterType}
          onCloseViewFilter={() => setViewFilterModalOpen(false)}
          onCloseSavedFilter={() => setSaveFilterModalOpen(false)}
          region={region}
          genre={genre}
          product={product}
          gender={gender}
          brands={brands}
          startDate={startDate}
          endDate={endDate}
          source={source}
          category={category}
          color={color}
          ageGroup={ageGroup}
          id=""
          setGenre={setGenre}
          setProduct={setProduct}
          setGender={setGender}
          setRegion={setRegion}
          setBrands={setBrands}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          setSource={setSource}
          setCategory={setCategory}
          setColor={setColor}
          setAgeGroup={setAgeGroup}
          handleClearAll={handleClearAll}
          filterId={filterId}
          filterName={filterName}
          setFilterId={setFilterId}
          setFilterName={setFilterName}
          setFilterSaved={setFilterSaved}
          setDisableAfterSave={setDisableAfterSave}
        />
      )}
    </div>
  );
};

export default SaveFilter;
