import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const ModalSkeleton = () => {
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div className="fixed inset-0 bg-black opacity-50"></div>
      <div
        className="relative bg-white rounded-lg shadow-sm font-poppins"
        style={{ width: "541px", height: "565px", overflow: "hidden" }}
      >
        {/* Main Content Skeleton */}
        <div className="h-[460px] gap-[32px] pt-4 px-6 pb-4">
          {/* Input Skeleton */}
          <div>
            <Skeleton width={100} height={16} />
            <Skeleton width="100%" height={32} />
          </div>
          <div className="flex flex-col flex-wrap">
            {/* Other Dropdowns */}
            {[
              "Genre",
              "Category",
              "Product",
              "Sources",
              "Region",
              "Colors",
            ].map((field, index) => (
              <div key={index} className="mt-4">
                <Skeleton width={100} height={16} />
                <Skeleton width="100%" height={32} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalSkeleton;
