import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as DropdownArrow } from "../../assets/icons/dropdownArrow.svg";

interface DropdownProps {
  label: string;
  options: string[];
  onSelect: (option: string) => void;
  onOpen?: () => void;
  minWidth?: number;
  trendSnapshotView?: boolean;
}

const Dropdown: React.FC<DropdownProps> = ({
  label,
  options,
  onSelect,
  onOpen,
  minWidth,
  trendSnapshotView,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => {
    setDropdownOpen(prev => {
      if (!prev && onOpen) onOpen();
      return !prev;
    });
  };

  const handleSelect = (option: string) => {
    onSelect(option);
    setDropdownOpen(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        className={`mt-0 block ${minWidth ? `min-w-[100px]` : "w-full"} pl-3 pr-5 py-1 text-left text-base border border-default rounded-lg`}
        disabled={trendSnapshotView}
        onClick={toggleDropdown}
      >
        <span className="text-secondary text-xs capitalize">{label}</span>
        <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
          <span
            className={`transition-transform duration-300 ${dropdownOpen ? "rotate-180" : ""}`}
          >
            <DropdownArrow className="w-2 h-2 " />
          </span>
        </span>
      </button>
      {dropdownOpen && (
        <div className="absolute mt-0 w-full rounded-md shadow-lg bg-white z-10 max-h-48 overflow-y-auto overflow-x-hidden">
          {options.map((option, index) => (
            <button
              key={index}
              className={`block w-full text-left px-4 py-2 text-xs text-secondary capitalize ${index !== options.length - 1 ? "border-b-2 border-light" : ""}`}
              onClick={() => handleSelect(option)}
            >
              {option}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
