import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import CrossIcon from "../../../assets/icons/CrossIcon";
import { capitalizeFirstLetter } from "../../../utils";
import moment from "moment";
import { getUserId } from "../../../app/api/user/user";
import {
  getListUsers,
  shareSnapshot,
  isSnapshotAlreadyShared,
} from "../../../app/api/trendSnapshot";
import { useDebounce } from "../../../app/hooks/targetHook";
import TickSvgIcon from "../../../assets/icons/TickIcon";
import { toast } from "react-toastify";

interface Props {
  onCloseSnapshotShareModal: () => void;
  ownerName: string;
  ownerId: string;
  postId: string | undefined;
  snapshotName: string;
  snapshotId: number;
}
interface handle {
  _id: string;
  fullName: string;
  email: string;
  groupName: string;
  cognitoSubId: string;
  isUserVerified: boolean;
  isActive: boolean;
  createdAt: string;
  updatedAt: string;
  __v: number;
}
interface userSelected {
  _id: string;
  fullName: string;
}
const SnapshotShareModal: React.FC<Props> = ({
  onCloseSnapshotShareModal,
  ownerName,
  postId,
  snapshotId,
  snapshotName,
  ownerId,
}) => {
  const user = useSelector((state: RootState) => state.user.userInfo);
  const userEmail = user?.email;
  const [userId, setUserId] = useState<string>("");
  const [userSearch, setUserSearch] = useState("");
  const [handleData, setHandleData] = useState<handle[]>([]);
  const [userSelected, setUserSelected] = useState<userSelected[]>([]);
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(true);

  const toggleUserSelection = async (userId: string, fullName: string) => {
    if (userId === ownerId) return;
    const response = await isSnapshotAlreadyShared(snapshotId, userId, user);
    const { isSharedWithUser } = response.data;
    if (isSharedWithUser) {
      return toast.error("Snapshot has already been shared with this user", {
        hideProgressBar: true,
      });
    }
    // Check if the user is already selected
    const isSelected = userSelected.some(user => user._id === userId);

    if (isSelected) {
      // Remove the user if they are already selected
      setUserSelected(userSelected.filter(user => user._id !== userId));
    } else {
      // Add the user if not already selected
      setUserSelected([...userSelected, { _id: userId, fullName }]);
    }
  };
  const getUserByEmail = async () => {
    if (userEmail) {
      try {
        const res = await getUserId(userEmail);
        const fetchedUserId = res.data.data[0]?._id;
        if (fetchedUserId) {
          setUserId(fetchedUserId);
        } else {
          console.error("User ID not found.");
        }
      } catch (error) {
        console.error("Error fetching user ID:", error);
      }
    }
  };
  useEffect(() => {
    getUserByEmail();
  }, []);
  const debouncedInput = useDebounce(inputValue, 500);

  useEffect(() => {
    //get user to share;

    const getUser = async () => {
      setLoading(true);
      try {
        const data = await getListUsers(user, debouncedInput);
        setHandleData(data.data);
      } catch (error) {
        console.error("Error fetching user list:", error);
        throw error;
      } finally {
        setLoading(false);
      }
    };
    getUser();
  }, [debouncedInput]);
  const handleCloseSnapshotShareModal = () => {
    onCloseSnapshotShareModal();
  };

  const handleShare = async () => {
    let success = true;
    try {
      const sharePromises = userSelected.map(async users => {
        const params = {
          sharedWith: users._id,
          sharedBy: userId,
        };
        if (users._id === userId) {
          success = false;
          toast.error("Snapshot has already been shared with you", {
            hideProgressBar: true,
          });
          return;
        }
        try {
          const response = await shareSnapshot(snapshotId, user, params);
          return response;
        } catch (error) {
          console.error(`Error sharing snapshot ${snapshotId}:`, error);
          toast.error("Error sharing snapshot", {
            hideProgressBar: true,
          });
          return null;
        }
      });

      const results = await Promise.all(sharePromises);
      if (success) {
        toast.success("Snapshot shared successfully", {
          hideProgressBar: true,
        });
      }
      onCloseSnapshotShareModal();
    } catch (error) {
      console.error("Error during sharing snapshots:", error);
    }
  };
  return ReactDOM.createPortal(
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      {/* Modal Background */}
      <div
        className="fixed inset-0 bg-black opacity-50"
        onClick={handleCloseSnapshotShareModal}
      ></div>

      <div
        className="relative bg-white rounded-lg shadow-lg z-40 font-poppins"
        style={{ width: "408px" }}
      >
        <div className="flex justify-between">
          <div className="p-4 text-[24px] font-poppins font-bold">
            Share Snapshot
          </div>
          <div
            className="p-6 cursor-pointer"
            onClick={onCloseSnapshotShareModal}
          >
            <CrossIcon width="14px" height="14px" />
          </div>
        </div>
        <div className="px-4 mb-2   ">
          <div className="flex flex-wrap">
            {userSelected.map((option, index) => (
              <div
                key={index}
                className="border border-secondary text-secondary text-sm px-2 py-1 rounded-2xl mr-1 mb-1 flex items-center space-x-1"
              >
                <span>{option.fullName}</span>
                <button
                  className="text-secondary"
                  onClick={e => {
                    e.stopPropagation();
                    toggleUserSelection(option._id, option.fullName);
                  }}
                >
                  &times;
                </button>
              </div>
            ))}
            <input
              className="flex-1 px-2 py-1 border border-transparent focus:outline-none focus:border-[#737373] rounded-md"
              placeholder="Select options"
              onChange={e => setInputValue(e.target.value)}
            />
          </div>
        </div>
        <div className="w-full px-4  ">
          <div className="flex justify-between  mt-4">
            <div className="flex  gap-2 ">
              <div className="w-10 h-10 flex items-center justify-center rounded-full  text-gray  text-sm border border-light">
                {ownerName
                  .split(" ")
                  .map(name => name[0])
                  .join("")
                  .toUpperCase()}
              </div>
              <div>
                <div className="text-primary text-sm">{ownerName}</div>
                <div className="text-secondary text-xs">{snapshotName}</div>
              </div>
            </div>
            <div className="">
              <span className=" text-negative  font-poppins text-xs bg-red-100 py-1 px-2 rounded-full font-bold">
                Owner
              </span>
            </div>
          </div>
        </div>
        <div className="w-full h-[345px] px-4 mt-2 mb-4  overflow-auto">
          {!loading ? (
            handleData.length > 0 ? (
              handleData?.map((data, index) => (
                <div
                  className="w-full  py-2 relative cursor-pointer "
                  key={data._id}
                  onClick={() => toggleUserSelection(data._id, data.fullName)}
                >
                  <div className="flex  gap-2">
                    <div className="w-10 h-10 flex items-center justify-center rounded-full  text-gray  text-sm border border-light ">
                      {data.fullName
                        .split(" ")
                        .map(name => name[0])
                        .join("")
                        .toUpperCase()}
                    </div>
                    <div>
                      <div className="text-primary text-sm">
                        {data.fullName}
                      </div>
                      <div className="text-secondary text-xs">{data.email}</div>
                    </div>
                    {userSelected.some(user => user._id === data._id) && (
                      <span className="absolute right-0">
                        <TickSvgIcon fill="gray" width="24" height="16" />
                      </span>
                    )}
                  </div>
                </div>
              ))
            ) : (
              <div className="flex justify-center items-center h-full">
                <div className="text-secondary text-sm">No users found</div>
              </div>
            )
          ) : (
            <div className="space-y-4">
              {Array(7)
                .fill(0)
                .map((_, index) => (
                  <div
                    key={index}
                    className="flex items-center justify-between bg-gray81/50 rounded-xl"
                  >
                    <Skeleton width="60%" height={20} />
                    <Skeleton width="25%" height={30} />
                  </div>
                ))}
            </div>
          )}
        </div>
        <div className="flex justify-center items-center mb-4 ">
          <button
            className="w-[347px] px-4 py-2 bg-primary text-white rounded-full items-center flex justify-center font-bold disabled:text-tertiary disabled:bg-inactive"
            disabled={userSelected.length === 0}
            onClick={handleShare}
          >
            Share
          </button>
        </div>
      </div>
    </div>,
    document.body
  );
};

export default SnapshotShareModal;
