import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import SelectionChips from "./SelectionChips";
import { ReactComponent as Close } from "../../../assets/icons/closeBold.svg";
import { FeedItem } from "../../../interface/DesignerInterface";
import { useSelector } from "react-redux";
import Pagination from "../../Target/Pagination";
import { RootState } from "../../../app/store";
import { getPaginatedTrendsPost } from "../../../app/api/trends";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import { ReactComponent as HeartIcon } from "../../../assets/icons/heart.svg";
import { ReactComponent as BlackHeartIcon } from "../../../assets/icons/heartWithBlack.svg";
import { getTrendsExpandedViewSnapshot } from "../../../app/api/trendSnapshot";

type TrendContentSelectionProps = {
  onClose?: () => void;
  setTrendSaveToBoardOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  setMultiSelectContentOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  name?: string;
  selection?: string[];
  userId: string;
  req_id: string;
  allPagesData: Record<string, FeedItem[]>;
  setAllPagesData: (item: Record<string, FeedItem[]>) => void;
  trendSnapshotTrendsView?: boolean;
  snapshotId?: number;
  trendNo?: number;
};

const TrendContentSelection: React.FC<TrendContentSelectionProps> = ({
  onClose,
  setTrendSaveToBoardOpen,
  setMultiSelectContentOpen,
  name,
  selection,
  req_id,
  allPagesData,
  setAllPagesData,
  trendSnapshotTrendsView,
  snapshotId,
  trendNo,
}) => {
  const [posts, setPosts] = React.useState<FeedItem[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 12;
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);

  const user = useSelector((state: RootState) => state.user.userInfo);

  const handlePageChange = (page: number) => {
    allPagesData[currentPage] = posts;
    setAllPagesData(allPagesData);
    setCurrentPage(page);
  };

  function chunkArrayToObject(array: FeedItem[], size: number) {
    if (size <= 0) {
      throw new Error("Chunk size must be greater than 0");
    }

    const result: Record<string, FeedItem[]> = {};
    let chunkIndex = 1;

    for (let i = 0; i < array.length; i += size) {
      result[chunkIndex] = array.slice(i, i + size);
      chunkIndex++;
    }

    return result;
  }

  useEffect(() => {
    if (allPagesData[currentPage]) {
      setPosts(allPagesData[currentPage]);
    }
  }, [currentPage]);

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      try {
        const response = await getPaginatedTrendsPost(
          { page: currentPage, pageSize: 10000, trend_name: name, req_id },
          user
        );
        const updatedResponse = response?.data?.posts.map((item: FeedItem) => ({
          ...item,
          isChecked: true,
        }));
        const allPageRes = chunkArrayToObject(updatedResponse, pageSize);
        setAllPagesData(allPageRes);
        setPosts(allPageRes[currentPage]);
        setTotalPages(Math.ceil(response?.data?.postCount / pageSize));
      } catch (error) {
        if ((error as AxiosError).response?.status !== 400) {
          toast.error("Error fetching posts", {
            hideProgressBar: true,
          });
        }
      } finally {
        setLoading(false);
      }
    };

    const getSnapshotViewTrendsData = async () => {
      setLoading(true);
      try {
        const params = {
          page: currentPage,
          pageSize: 10000,
        };
        const response = await getTrendsExpandedViewSnapshot(
          snapshotId,
          user,
          trendNo,
          params
        );
        const updatedResponse = response?.data?.posts.map((item: FeedItem) => ({
          ...item,
          isChecked: true,
        }));
        const allPageRes = chunkArrayToObject(updatedResponse, pageSize);
        setAllPagesData(allPageRes);
        setPosts(allPageRes[currentPage]);
        setTotalPages(Math.ceil(response?.data?.totalPosts / pageSize));
      } catch (error) {
        console.error("Error fetching snapshot data:", error);
      } finally {
        setLoading(false);
      }
    };

    trendSnapshotTrendsView ? getSnapshotViewTrendsData() : getData();
  }, []);

  const handleCheckboxChange = (index: number) => {
    const updatedData = [...posts!];
    updatedData[index].isChecked = !updatedData[index].isChecked;
    setPosts(updatedData);
  };

  const handleSubmit = () => {
    allPagesData[currentPage] = posts;
    setAllPagesData(allPagesData);

    setMultiSelectContentOpen?.(false);
    setTrendSaveToBoardOpen?.(true);
  };

  return ReactDOM.createPortal(
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div
        className=" fixed inset-0 bg-black opacity-50"
        onClick={onClose}
      ></div>
      <div
        className="relative bg-white rounded-lg shadow-lg z-60"
        style={{ width: "740px", height: "690px", overflow: "hidden" }}
      >
        <div className="sticky top-0 bg-white p-4 border-b border-gray-200 z-10">
          <div className="flex justify-between">
            <div className="flex space-x-2 items-center">
              <h1 className="font-poppins text-xl ">{name}</h1>
              <div className="w-[2px] h-[18px] bg-[#4b4b4b]"></div>
              {selection?.map((value, index) => (
                <SelectionChips key={index} value={value} />
              ))}
            </div>
            <div className="flex space-x-3">
              <div className="cursor-pointer">
                <Close className="w-4 h-4" onClick={onClose} />
              </div>
            </div>
          </div>
        </div>

        <div
          className="overflow-y-auto"
          style={{ maxHeight: "calc(680px - 130px)" }}
        >
          <div className="grid grid-cols-4 gap-2 ml-4 mr-4 mb-10 mt-2">
            {posts?.map((item, index) => (
              <div key={index} className="relative ">
                <img
                  src={`${process.env.REACT_APP_CLOUD_FRONT_URL}${item?.image_link?.substring(item?.image_link?.indexOf(".com/") + 5)}`}
                  alt="image"
                  className="rounded-lg mb-2 w-40 h-48"
                />

                <div
                  className="w-4 h-4 bg-white rounded-full absolute top-2 right-4 flex justify-center items-center cursor-pointer"
                  onClick={() => handleCheckboxChange(index)}
                >
                  {item.isChecked ? <BlackHeartIcon /> : <HeartIcon />}
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="p-2 absolute bg-white w-full bottom-0 flex flex-col items-center justify-center">
          <div className="flex text-right">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          </div>
          <button
            type="button"
            className="font-bold text-inverted w-9/12 text-sm bg-primary px-6 py-3 rounded-full mb-4"
            onClick={handleSubmit}
          >
            Continue
          </button>
        </div>
      </div>
    </div>,

    document.body
  );
};

export default TrendContentSelection;
