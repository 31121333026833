import React, { useRef, useState } from "react";
import Dropdown from "../Dropdown";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, ChartOptions } from "chart.js";
import { ReactComponent as RightArrow } from "../../../assets/icons/rightArrow.svg";

interface OverallAnalysisProps {
  analysisOpen?: boolean;
}
const OverallAnalysis: React.FC<OverallAnalysisProps> = ({ analysisOpen }) => {
  const [filterType, setFilterType] = useState("Most Popular");
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [scrolled, setScrolled] = useState(false);

  const dummyFilterType = ["Most Popular", "New and Emerging"];

  const barGraphData = {
    labels: Array.from({ length: 50 }, (_, i) => i + 1), // Labels from 1 to 50
    datasets: [
      {
        // label: "My First Dataset",
        data: [
          99, 95, 93, 90, 87, 85, 80, 78, 76, 73, 70, 68, 66, 65, 61, 59, 56,
          53, 50, 49, 46, 43, 41, 40, 39, 36, 33, 30, 29, 28, 27, 24, 23, 20,
          19, 18, 16, 15, 13, 11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1,
        ],
        backgroundColor: ["#58acdf", "#ff7c01", "#fec601"],

        borderWidth: 0,

        barPercentage: 0.9,
        categoryPercentage: 0.9,
      },
    ],
  };
  const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

  const getRandomLetter = () => {
    return letters.charAt(Math.floor(Math.random() * letters.length));
  };

  const barOptions: ChartOptions<"bar"> = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          autoSkip: true,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          callback: (value: any, index) => {
            return index % 2 === 0 ? value + 1 : null;
          },
          maxRotation: 0,
          minRotation: 0,
        },
        title: {
          display: false,
          text: "X Axis Label",
          align: "center",
        },
        stacked: false,
      },
      y: {
        beginAtZero: true,
        title: {
          display: false,
          text: "Y Axis Label",
          align: "center",
        },
        min: 0,
        max: 100,
        ticks: {
          stepSize: 25,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          callback: function (value: any) {
            return value;
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
        position: "top",
        maxWidth: 10,
      },
      title: {
        display: false,
        text: "My Bar Chart",
      },
      datalabels: {
        anchor: "end",
        align: "start",
        formatter: value => getRandomLetter(),
        color: "black",
        font: {
          weight: "normal",
          size: 5,
        },

        padding: {
          top: -18,
        },
      },
    },
  };

  const handleRightScroll = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current?.scrollBy({ left: 450, behavior: "smooth" });
      setScrolled(true);
    }
  };

  return (
    <div className="ml-3 mr-2">
      <div className="text-sm">Filter By</div>
      <Dropdown
        label={filterType || "Select type"}
        options={dummyFilterType}
        onSelect={option => {
          setFilterType(option);
        }}
      />

      <div className="relative w-[430px] mt-4 overflow-x-hidden">
        {!scrolled && (
          <div
            className="absolute top-1/2 left-[410px] transform -translate-y-1/2 cursor-pointer z-10 w-5 h-5 rounded-full bg-white flex items-center"
            onClick={handleRightScroll}
          >
            <RightArrow className="text-lg p-[1px]" />
          </div>
        )}

        <div className="absolute top-0 right-10">
          <div className="text-[8px] mb-0.5">Popularity</div>
          <div className="flex flex-row gap-3">
            <div>
              <div className="text-[10px] font-bold">97%</div>
              <div className="text-[8px] mt-0.5">Contribution</div>
            </div>
            <div>
              <div className="text-[10px] font-bold">High</div>
              <div className="text-[8px] mt-0.5">Impact</div>
            </div>
            <div>
              <div className="text-[10px] font-bold">Safe</div>
              <div className="text-[8px] mt-0.5">Bet</div>
            </div>
          </div>
        </div>

        <div
          ref={scrollContainerRef}
          className="w-[450px] h-[200px] overflow-x-scroll"
          style={{ display: "flex", scrollBehavior: "smooth" }}
        >
          <div className="min-w-[800px] pr-8">
            <Bar data={barGraphData} options={barOptions} />
          </div>
        </div>
      </div>
    </div>
  );
};
export default OverallAnalysis;
