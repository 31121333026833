import React from "react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function ShareSnapshotIcon(props: any) {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 24 25"
      fill={props.fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.501 15.0786C17.0203 15.0811 16.5463 15.1914 16.114 15.4014C15.6817 15.6115 15.302 15.9159 15.0031 16.2923L10.309 13.3577C10.4801 12.8056 10.4801 12.2146 10.309 11.6625L15.0031 8.72792C15.4795 9.31786 16.1493 9.72013 16.894 9.86348C17.6386 10.0068 18.4099 9.88199 19.0713 9.51108C19.7327 9.14018 20.2415 8.54715 20.5075 7.83705C20.7735 7.12695 20.7796 6.34561 20.5247 5.63143C20.2698 4.91726 19.7704 4.31636 19.1149 3.93517C18.4594 3.55397 17.6901 3.4171 16.9433 3.5488C16.1965 3.68049 15.5205 4.07225 15.0349 4.65467C14.5493 5.2371 14.2855 5.97259 14.2903 6.73088C14.2933 7.01803 14.3365 7.30334 14.4187 7.5785L9.7247 10.5131C9.31029 9.99137 8.74386 9.61153 8.10391 9.42617C7.46397 9.24082 6.78221 9.25914 6.15314 9.47858C5.52408 9.69803 4.97886 10.1077 4.59306 10.6509C4.20726 11.1941 4 11.8439 4 12.5101C4 13.1763 4.20726 13.8261 4.59306 14.3693C4.97886 14.9125 5.52408 15.3222 6.15314 15.5416C6.78221 15.7611 7.46397 15.7794 8.10391 15.594C8.74386 15.4087 9.31029 15.0288 9.7247 14.5071L14.4187 17.4417C14.3365 17.7169 14.2933 18.0022 14.2903 18.2893C14.2903 18.9243 14.4786 19.5451 14.8314 20.0731C15.1842 20.6011 15.6856 21.0126 16.2723 21.2556C16.859 21.4986 17.5045 21.5622 18.1273 21.4383C18.7501 21.3144 19.3222 21.0086 19.7713 20.5596C20.2203 20.1106 20.5261 19.5385 20.6499 18.9157C20.7738 18.2929 20.7102 17.6473 20.4672 17.0606C20.2242 16.474 19.8127 15.9725 19.2847 15.6197C18.7567 15.2669 18.136 15.0786 17.501 15.0786Z"
        fill={props.fill}
      />
    </svg>
  );
}

export default ShareSnapshotIcon;
