import { User } from "../../types";
import { admin, forecaster } from "./axios";

type ArchiveParams = {
  req_id: string;
  trend_name: string;
};

export const archiveTrend = async (
  params: ArchiveParams,
  user: User | null
) => {
  if (user === null) {
    throw new Error("User is not available");
  }

  const { group } = user!;
  if (!group) {
    console.error("Group Not Found");
    return;
  }

  try {
    const apiClient =
      group === "admin" ? admin : group === "forecaster" ? forecaster : null;
    if (apiClient) {
      await apiClient.patch(
        `/trends/archive?trend_name=${params.trend_name}&req_id=${params.req_id}`
      );
    } else {
      console.error("Group not found");
    }
  } catch (error) {
    console.error("Error fetching trends:", error);
    throw error;
  }
};

export const unarchiveTrend = async (
  params: ArchiveParams,
  user: User | null
) => {
  if (user === null) {
    throw new Error("User is not available");
  }

  const { group } = user!;
  if (!group) {
    console.error("Group Not Found");
    return;
  }

  try {
    const apiClient =
      group === "admin" ? admin : group === "forecaster" ? forecaster : null;
    if (apiClient) {
      await apiClient.patch(
        `/trends/unarchive?trend_name=${params.trend_name}&req_id=${params.req_id}`
      );
    } else {
      console.error("Group not found");
    }
  } catch (error) {
    console.error("Error fetching trends:", error);
    throw error;
  }
};

type GetArchivedParams = {
  req_id: string;
  posts_per_trend: number;
  page: number;
  pageSize: number;
};

export const getArchivedTrend = async (
  params: GetArchivedParams,
  user: User | null
) => {
  if (user === null) {
    throw new Error("User is not available");
  }

  const { group } = user!;
  if (!group) {
    console.error("Group Not Found");
    return;
  }

  try {
    const apiClient =
      group === "admin" ? admin : group === "forecaster" ? forecaster : null;
    if (apiClient) {
      const response = await apiClient.get(`/trends/archived`, {
        params,
      });
      return response?.data;
    } else {
      console.error("Group not found");
    }
  } catch (error) {
    console.error("Error fetching trends:", error);
    throw error;
  }
};
