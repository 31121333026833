import React, { useState } from "react";
import { ReactComponent as LeftArrow } from "../../../assets/icons/leftArrow.svg";
import { useNavigate } from "react-router-dom";
import Dropdown from "../Dropdown";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, ChartOptions } from "chart.js";

interface ExpandViewOverallAnalysisProps {
  isSidebarOpen?: boolean;
}

const ExpandViewOverallAnalysis: React.FC<
  ExpandViewOverallAnalysisProps
> = () => {
  const navigate = useNavigate();
  const [filterType, setFilterType] = useState("Most Popular");

  const dummyFilterType = ["Most Popular", "New and Emerging"];

  const barGraphData = {
    labels: Array.from({ length: 50 }, (_, i) => i + 1), // Labels from 1 to 50
    datasets: [
      {
        // label: "My First Dataset",
        data: [
          99, 95, 93, 90, 87, 85, 80, 78, 76, 73, 70, 68, 66, 65, 61, 59, 56,
          53, 50, 49, 46, 43, 41, 40, 39, 36, 33, 30, 29, 28, 27, 24, 23, 20,
          19, 18, 16, 15, 13, 11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1,
        ],
        backgroundColor: ["#58acdf", "#ff7c01", "#fec601"],

        borderWidth: 0,

        barPercentage: 0.9,
        categoryPercentage: 0.9,
      },
    ],
  };
  const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

  const getRandomLetter = () => {
    return letters.charAt(Math.floor(Math.random() * letters.length));
  };

  const barOptions: ChartOptions<"bar"> = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          autoSkip: true,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          callback: (value: any, index) => {
            return index % 2 === 0 ? value + 1 : null;
          },
          maxRotation: 0,
          minRotation: 0,
        },
        title: {
          display: false,
          text: "X Axis Label",
          align: "center",
        },
        stacked: false,
      },
      y: {
        beginAtZero: true,
        title: {
          display: false,
          text: "Y Axis Label",
          align: "center",
        },
        min: 0,
        max: 100,
        ticks: {
          stepSize: 25,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          callback: function (value: any) {
            return value;
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
        position: "top",
        maxWidth: 10,
      },
      title: {
        display: false,
        text: "My Bar Chart",
      },
      datalabels: {
        anchor: "end",
        align: "start",
        formatter: value => getRandomLetter(),
        color: "black",
        font: {
          weight: "normal",
          size: 5,
        },

        padding: {
          top: -18,
        },
      },
    },
  };

  const data = [
    {
      tNo: "01",
      contribution: "30%",
      impact: "High",
      bet: "Safe",
      productType: "Abu Kurta",
      color: "Blue",
    },
    {
      tNo: "02",
      contribution: "25%",
      impact: "Low",
      bet: "Risky",
      productType: "Classic Shirt",
      color: "Red",
    },
    {
      tNo: "03",
      contribution: "40%",
      impact: "Medium",
      bet: "Neutral",
      productType: "Denim Jeans",
      color: "Orange",
    },
    {
      tNo: "04",
      contribution: "35%",
      impact: "High",
      bet: "Safe",
      productType: "Formal Suit",
      color: "Green",
    },
    {
      tNo: "05",
      contribution: "20%",
      impact: "Low",
      bet: "Risky",
      productType: "Casual T-shirt",
      color: "Yellow",
    },
    {
      tNo: "06",
      contribution: "50%",
      impact: "Medium",
      bet: "Safe",
      productType: "Leather Jacket",
      color: "Black",
    },
    {
      tNo: "07",
      contribution: "45%",
      impact: "High",
      bet: "Neutral",
      productType: "Sweatshirt",
      color: "Pink",
    },
    {
      tNo: "08",
      contribution: "15%",
      impact: "Low",
      bet: "Risky",
      productType: "Track Pants",
      color: "Purple",
    },
    {
      tNo: "09",
      contribution: "60%",
      impact: "High",
      bet: "Safe",
      productType: "Kurta Pajama",
      color: "Brown",
    },
    {
      tNo: "10",
      contribution: "55%",
      impact: "Medium",
      bet: "Neutral",
      productType: "Blazer",
      color: "Gray",
    },
    {
      tNo: "11",
      contribution: "30%",
      impact: "High",
      bet: "Safe",
      productType: "Abu Kurta",
      color: "Blue",
    },
    {
      tNo: "12",
      contribution: "25%",
      impact: "Low",
      bet: "Risky",
      productType: "Classic Shirt",
      color: "Red",
    },
    {
      tNo: "13",
      contribution: "40%",
      impact: "Medium",
      bet: "Neutral",
      productType: "Denim Jeans",
      color: "Orange",
    },
    {
      tNo: "14",
      contribution: "35%",
      impact: "High",
      bet: "Safe",
      productType: "Formal Suit",
      color: "Green",
    },
    {
      tNo: "15",
      contribution: "20%",
      impact: "Low",
      bet: "Risky",
      productType: "Casual T-shirt",
      color: "Yellow",
    },
    {
      tNo: "16",
      contribution: "50%",
      impact: "Medium",
      bet: "Safe",
      productType: "Leather Jacket",
      color: "Black",
    },
    {
      tNo: "17",
      contribution: "45%",
      impact: "High",
      bet: "Neutral",
      productType: "Sweatshirt",
      color: "Pink",
    },
    {
      tNo: "18",
      contribution: "15%",
      impact: "Low",
      bet: "Risky",
      productType: "Track Pants",
      color: "Purple",
    },
    {
      tNo: "19",
      contribution: "60%",
      impact: "High",
      bet: "Safe",
      productType: "Kurta Pajama",
      color: "Brown",
    },
    {
      tNo: "20",
      contribution: "55%",
      impact: "Medium",
      bet: "Neutral",
      productType: "Blazer",
      color: "Gray",
    },
  ];

  return (
    <div className="bg-white">
      <div className="flex flex-row justify-between">
        <div className="flex flex-row mt-6">
          <div>
            <LeftArrow
              className="text-lg ml-4 mt-4 cursor-pointer"
              onClick={() => navigate("/designer-page")}
            />
          </div>
          <div className="transition-all  duration-500 ease-in-out opacity-100 font-semibold max-w-full ml-4 mt-2.5 text-secondary ">
            Overall Analysis
          </div>
        </div>
        <div className="mt-2 mr-4">
          <div className="text-sm">Filter By</div>
          <div className="w-48">
            <Dropdown
              label={filterType || "Select type"}
              options={dummyFilterType}
              onSelect={option => {
                setFilterType(option);
              }}
            />
          </div>
        </div>
      </div>

      <div className="relative">
        <div className="absolute -top-5 left-80 ">
          <div className="text-[8px] mb-0.5">Popularity</div>
          <div className="flex flex-row gap-3">
            <div>
              <div className="text-[10px] font-bold">97%</div>
              <div className="text-[8px] mt-0.5">Contribution</div>
            </div>
            <div>
              <div className="text-[10px] font-bold">High</div>
              <div className="text-[8px] mt-0.5">Impact</div>
            </div>
            <div>
              <div className="text-[10px] font-bold">Safe</div>
              <div className="text-[8px] mt-0.5">Bet</div>
            </div>
          </div>
        </div>

        <div className="w-[800px] h-[200px] mt-8 mx-40">
          <Bar data={barGraphData} options={barOptions} />
        </div>
      </div>

      <div className="pt-4">
        <div className="overflow-x-auto">
          <div className="min-w-full border border-gray-300 rounded-md">
            {/* Table Header */}
            <div className="grid grid-cols-6 border-b border-gray-300 bg-gray-100 font-bold text-gray-800">
              <div className="p-2 border-r border-gray-300">T.No</div>
              <div className="p-2 border-r border-gray-300">Contribution</div>
              <div className="p-2 border-r border-gray-300">Impact</div>
              <div className="p-2 border-r border-gray-300">Bet</div>
              <div className="p-2 border-r border-gray-300">Product Type</div>
              <div className="p-2">Color</div>
            </div>
            {/* Table Body */}
            {data.map((row, index) => (
              <div
                key={index}
                className="grid grid-cols-6 border-b border-gray-300"
              >
                <div className="p-2 border-r border-gray-300">{row.tNo}</div>
                <div className="p-2 border-r border-gray-300">
                  {row.contribution}
                </div>
                <div className="p-2 border-r border-gray-300">{row.impact}</div>
                <div className="p-2 border-r border-gray-300">{row.bet}</div>
                <div className="p-2 border-r border-gray-300">
                  {row.productType}
                </div>
                <div className="p-2">{row.color}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExpandViewOverallAnalysis;
