import React, { useEffect, useState } from "react";
import { INotification } from "../../interface/INotification";
import { firestore } from "../../app/config/FirebaseConfig";
import { doc, updateDoc } from "firebase/firestore";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { getUserId } from "../../app/api/user/user";
import NotificationItem from "./NotificationItem";

interface NotificationModalProps {
  notifications: INotification[];
}

const NotificationModal: React.FC<NotificationModalProps> = ({
  notifications,
}) => {
  const user = useSelector((state: RootState) => state.user.userInfo);
  const userEmail = user?.email;
  const [userId, setUserId] = useState("");

  const getUserByEmail = async () => {
    if (userEmail) {
      const res = await getUserId(userEmail);
      setUserId(res.data.data[0]._id);
    }
  };

  useEffect(() => {
    getUserByEmail();
  }, []);
  const redirectPage = (index: number) => {
    const notification = notifications[index];

    const { title, sourceDetails } = notification;
    const { sourceId } = sourceDetails;

    if (title === "Boards_invited") {
      window.location.href = `/boards/${sourceId}`;
    }
    if (title === "Ticket_content-reported") {
      window.location.href = `/tickets/review/${sourceId}`;
    }
    if (title === "Boards_user-role-change") {
      window.location.href = `/boards/${sourceId}`;
    }
    if (title === "Snapshot_shared") {
      window.location.href = `/trend-snapshot/details/${sourceId}`;
    }
  };

  const handleNotificationClick = async (
    notificationId: string,
    index: number
  ) => {
    if (!userId) return;

    const notification = notifications[index];
    if (!notification.isRead) {
      try {
        const notificationDocRef = doc(
          firestore,
          `notifications`,
          notificationId
        );
        await updateDoc(notificationDocRef, { isRead: true });
      } catch (error) {
        console.error("Error updating document: ", error);
      }
    }
    redirectPage(index);
  };
  return (
    <div className="bg-white rounded-lg shadow-lg w-[320px] overflow-y-auto h-[512px]">
      {notifications.length === 0 ? (
        <p className="text-center text-gray-500 mt-2">No notifications</p>
      ) : (
        notifications.map((notification, index) => (
          <NotificationItem
            key={notification.id}
            notification={notification}
            onClick={() => handleNotificationClick(notification.id, index)}
          />
        ))
      )}
    </div>
  );
};

export default NotificationModal;
