import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";

const PrivateRoute = ({ children }: { children: React.ReactElement }) => {
  const isAuthenticated = useSelector(
    (state: RootState) => state.user.userInfo
  );
  return isAuthenticated ? children : <Navigate to="/auth/login" />;
};

export default PrivateRoute;
