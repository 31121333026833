import React from "react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any, prettier/prettier
function CrossIcon(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width}
      height={props.height}
      fill="none"
      viewBox="0 0 12 12"
    >
      <path
        fill="#9E9E9E"
        fillRule="evenodd"
        d="M6.207 7.415l3.737 3.734a0.857 0.857 0 101.21-1.211L7.415 6l3.739-3.738a0.857 0.857 0 10-1.211-1.209L6.207 4.793 2.466 1.054a0.857 0.857 0 10-1.21 1.211L4.793 6 1.054 9.738a0.857 0.857 0 001.211 1.209L6.207 7.415z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default CrossIcon;
