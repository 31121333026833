import React, { useState } from "react";
import { createPortal } from "react-dom";
import { archiveTrend, unarchiveTrend } from "../../app/api/archive";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { AxiosError } from "axios";
import { toast } from "react-toastify";

interface ArchiveModalProps {
  text: string;
  onClose: () => void;
  req_id: string;
  name: string;
  setReload?: React.Dispatch<React.SetStateAction<boolean>>;
  archive?: boolean;
  setIsExpandViewOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
const ArchiveModal: React.FC<ArchiveModalProps> = ({
  text,
  onClose,
  req_id,
  name,
  setReload,
  archive,
  setIsExpandViewOpen,
}) => {
  const [loading, setLoading] = useState<boolean>(false);

  const user = useSelector((state: RootState) => state.user.userInfo);
  const handleArchiveTrend = async () => {
    const params = { req_id, trend_name: name };
    try {
      setLoading(true);
      await archiveTrend(params, user);
      toast.success("Trend Archived Successfully", {
        hideProgressBar: true,
      });
      if (setReload) {
        setReload(true);
      }
    } catch (error) {
      if ((error as AxiosError).response?.status !== 400) {
        toast.error("Error while archieving trends", {
          hideProgressBar: true,
        });
      }
    } finally {
      setLoading(false);
      setIsExpandViewOpen(false);
      onClose();
    }
  };

  const handleUnarchiveTrend = async () => {
    const params = { req_id, trend_name: name };
    try {
      setLoading(true);
      await unarchiveTrend(params, user);
      toast.success("Trend Unarchived Successfully", {
        hideProgressBar: true,
      });
      if (setReload) {
        setReload(true);
      }
    } catch (error) {
      if ((error as AxiosError).response?.status !== 400) {
        toast.error("Error while unarchieving trends", {
          hideProgressBar: true,
        });
      }
    } finally {
      setLoading(false);
      setIsExpandViewOpen(false);
      onClose();
    }
  };

  return typeof document !== "undefined"
    ? createPortal(
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 w-screen h-screen font-poppins">
          <div className="bg-white rounded-lg shadow-lg p-4 w-[350px]">
            <h2 className="text-2xl font-bold mb-6 text-primary ">{text}</h2>
            <div className="flex justify-between">
              <button
                className="bg-white text-tertiary font-bold border border-gray-300 rounded-full px-9 py-2"
                onClick={onClose}
                disabled={loading}
              >
                Cancel
              </button>
              <button
                className="bg-primary font-semibold text-white rounded-full  px-9 py-2"
                onClick={archive ? handleUnarchiveTrend : handleArchiveTrend}
                disabled={loading}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>,
        document.body
      )
    : null;
};

export default ArchiveModal;
