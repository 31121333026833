/* eslint-disable @typescript-eslint/no-explicit-any */

import { useState, useEffect } from "react";
import { getFilteredEvents, getEventCategories } from "../../app/api/events";
import { ReactComponent as LeftArrow } from "../../assets/icons/leftArrow.svg";
import { ReactComponent as RightArrow } from "../../assets/icons/rightArrow.svg";
import { ReactComponent as DropdownArrow } from "../../assets/icons/dropdownArrow.svg";
import moment from "moment";
import { toast } from "react-toastify";
import { EVENTS_COLORS, EVENT_PERIODS } from "../../constants/constants";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import Dropdown from "../Common/Dropdown";
import { capitalizeFirstLetter } from "../../utils";
import { AxiosError } from "axios";

const Keyevents = ({
  isCollapsed,
  onToggle,
}: {
  isCollapsed: boolean;
  onToggle: () => void;
}) => {
  const currentYear = moment().year();
  const [filterOpen, setFilterOpen] = useState(true);
  const [periodOpen, setPeriodOpen] = useState(true);
  const [categories, setCategories] = useState<string[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);

  const [events, setEvents] = useState<any[]>([]);

  const [expandedDates, setExpandedDates] = useState<{
    [key: string]: boolean;
  }>({});

  const [fromMonth, setFromMonth] = useState<string>(moment().format("MMMM"));
  const [fromYear, setFromYear] = useState<number>(currentYear);

  const [toMonth, setToMonth] = useState<string>(
    moment().add(2, "months").format("MMMM")
  );
  const [toYear, setToYear] = useState<number>(
    moment().add(2, "months").year()
  );
  const toggleExpand = (monthYear: string, date: string) => {
    setExpandedDates(prev => ({
      ...prev,
      [`${monthYear}-${date}`]: !prev[`${monthYear}-${date}`],
    }));
  };

  const user = useSelector((state: RootState) => state.user.userInfo);
  const userRole = user?.group;

  const validateDateRange = () => {
    const fromDate = moment(`${fromYear}-${fromMonth}`, "YYYY-MMMM");
    const toDate = moment(`${toYear}-${toMonth}`, "YYYY-MMMM");

    if (fromDate.isAfter(toDate)) {
      toast.error("End date cannot be earlier than the start date", {
        hideProgressBar: true,
      });
      return false;
    }
    return true;
  };
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await getEventCategories(userRole);
        if (
          response.data &&
          response.data.data &&
          Array.isArray(response.data.data)
        ) {
          setCategories(response.data.data);
        }
      } catch (error: any) {
        if ((error as AxiosError).response?.status !== 400) {
          toast.error("Error fetching categories:", {
            hideProgressBar: true,
          });
        }
      }
    };

    fetchCategories();
  }, []);

  useEffect(() => {
    if (validateDateRange() && categories.length > 0) {
      const fetchEvents = async () => {
        if (fromMonth && fromYear && toMonth && toYear) {
          try {
            const fromDate = `${fromYear}-${moment(fromMonth, "MMMM").format("MM")}`;
            const toDate = `${toYear}-${moment(toMonth, "MMMM").format("MM")}`;

            const response = await getFilteredEvents(
              selectedCategories.length > 0 ? selectedCategories : categories,
              fromDate,
              toDate,
              userRole
            );

            if (response.data && Array.isArray(response.data)) {
              setEvents(response.data);
            }
          } catch (error: any) {
            if ((error as AxiosError).response?.status !== 400) {
              toast.error("Error fetching events:", {
                hideProgressBar: true,
              });
            }
          }
        }
      };

      fetchEvents();
    }
  }, [selectedCategories, fromMonth, fromYear, toMonth, toYear, categories]);

  const handleCategoryToggle = (category: string) => {
    setSelectedCategories(prev => {
      const newSelectedCategories = prev.includes(category)
        ? prev.filter(c => c !== category)
        : [...prev, category];

      return newSelectedCategories;
    });
  };

  const Tag = ({
    text,
    color,
    onClick,
    selected,
  }: {
    text: string;
    color: string;
    onClick: () => void;
    selected: boolean;
  }) => (
    <div
      className={`inline-flex p-1 flex-none text-center justify-center items-center text-gray-800 border border-default rounded-full text-xs font-medium cursor-pointer m-1 select-none`}
      onClick={onClick}
      style={{ backgroundColor: selected ? color : "" }}
    >
      {!selected && (
        <span
          className="w-2 h-2 rounded-full"
          style={{ backgroundColor: color }}
        ></span>
      )}
      <span className={`${selected ? "pl-3 pr-3" : "pl-2 pr-2"}`}>{text}</span>
    </div>
  );

  const groupEventsByDateInMonth = (events: any[]) => {
    const groupedEvents: { [month: string]: { [date: string]: any[] } } = {};

    events.forEach(event => {
      const monthYear = moment(event.date).format("MMMM YYYY");
      const day = moment(event.date).format("DD");

      if (!groupedEvents[monthYear]) {
        groupedEvents[monthYear] = {};
      }

      if (!groupedEvents[monthYear][day]) {
        groupedEvents[monthYear][day] = [];
      }

      groupedEvents[monthYear][day].push(event);
    });

    return groupedEvents;
  };

  //const groupedEvents = groupEventsByMonth(events);
  const groupedEvents = groupEventsByDateInMonth(events);

  const getFuturePeriods = () => {
    const currentYear = moment().year();
    const yearsRange = 3;

    const futurePeriods = [];
    for (let year = currentYear; year < currentYear + yearsRange; year++) {
      for (let month = 0; month < 12; month++) {
        const label = moment({ month }).format("MMMM");
        futurePeriods.push({
          label,
          months: [month + 1],
          year,
        });
      }
    }

    return futurePeriods;
  };
  const futurePeriods = getFuturePeriods();
  const yearsSet = new Set(futurePeriods.map(p => p.year.toString()));
  const uniqueYears = Array.from(yearsSet);

  return (
    <div
      className={`min-h-[calc(100vh-80px)] ml-2 h-full pt-2 flex flex-col justify-between bg-white shadow-md transition-all duration-500 ease-in-out ${
        isCollapsed ? "w-12" : "w-[275px]"
      }`}
    >
      <div className="sticky ml-1 top-0 bg-white z-10">
        <div
          className={`flex items-center text-primary rounded-2xl mb-2 cursor-pointer ${
            isCollapsed ? " justify-center" : "px-1 py-2"
          }`}
          onClick={onToggle}
        >
          {isCollapsed ? (
            <div className="flex flex-col items-center">
              <LeftArrow className="text-lg mb-4" />
              <div>
                {/* KEY EVENTS name to show while collapse */}
                {"STNEVEYEK".split("").map((letter, index) => (
                  <span
                    key={index}
                    className="block -rotate-90 font-semibold text-secondary"
                    style={{ marginBottom: "-8px" }}
                  >
                    {letter}
                  </span>
                ))}
              </div>
            </div>
          ) : (
            <>
              <RightArrow className="text-lg mr-2" />
              <span className="transition-all duration-500 ease-in-out opacity-100 font-semibold max-w-full ml-3 text-secondary">
                Key Events
              </span>
            </>
          )}
        </div>
        {!isCollapsed && (
          <>
            <div className="mb-4 border-b border-light pb-3 pl-2">
              <span
                className="text-xs cursor-pointer flex font-semibold items-center select-none"
                onClick={() => setFilterOpen(!filterOpen)}
              >
                Filter Category
                {
                  <DropdownArrow
                    className={`w-3.5 h-3.5 ml-1 ${
                      filterOpen
                        ? "transform -rotate-90 duration-500 ease-in-out"
                        : "rotate-90 duration-500 ease-in-out"
                    }`}
                  />
                }
              </span>
              {filterOpen && (
                <div className="mt-3">
                  {categories.map((category, index) => (
                    <Tag
                      key={index}
                      text={capitalizeFirstLetter(category)} //here
                      color={EVENTS_COLORS[category]}
                      onClick={() => handleCategoryToggle(category)}
                      selected={selectedCategories.includes(category)}
                    />
                  ))}
                </div>
              )}
            </div>
            <div
              className={`mb-3 border-b border-light pb-3 pl-2 ${
                periodOpen ? "shadow-md " : ""
              }`}
            >
              <h2
                className="text-xs cursor-pointer font-semibold flex items-center select-none"
                onClick={() => setPeriodOpen(!periodOpen)}
              >
                Select Period
                {
                  <DropdownArrow
                    className={`w-3.5 h-3.5 ml-1 ${
                      periodOpen
                        ? "transform -rotate-90 duration-500 ease-in-out"
                        : "rotate-90 duration-500 ease-in-out"
                    }`}
                  />
                }
              </h2>
              {periodOpen && (
                <div className="mt-3">
                  <div className="flex items-center space-x-4">
                    <span className="text-xs font-normal text-secondary">
                      From
                    </span>
                    <Dropdown
                      label={fromYear ? `${fromYear}` : "Select From Year"}
                      options={uniqueYears}
                      onSelect={option => setFromYear(Number(option))}
                    />
                    <Dropdown
                      label={fromMonth ? `${fromMonth}` : "Select From Month"}
                      options={moment.months().map(month => month)}
                      onSelect={option => setFromMonth(option)}
                      minWidth={100}
                    />
                  </div>

                  <div className="flex items-center space-x-4 mt-2">
                    <span className="text-xs font-normal text-secondary mr-[15.5px]">
                      To
                    </span>
                    <Dropdown
                      label={toYear ? `${toYear}` : "Select To Year"}
                      options={uniqueYears}
                      onSelect={option => setToYear(Number(option))}
                    />
                    <Dropdown
                      label={toMonth ? `${toMonth}` : "Select To Month"}
                      options={moment.months().map(month => month)}
                      onSelect={option => setToMonth(option)}
                      minWidth={100}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="overflow-y-auto h-[calc(100vh-465px)] mt-4">
              {events.length === 0 ? (
                <div className="text-center text-gray-500">
                  No events found. Please select a different category or period.
                </div>
              ) : (
                Object.keys(groupedEvents)
                  .sort((a, b) =>
                    moment(a, "MMMM YYYY").diff(moment(b, "MMMM YYYY"))
                  )
                  .map((monthYear, monthIdx) => (
                    <div key={monthIdx} className="overflow-hidden">
                      <div className="bg-lightest text-center py-0.5 flex items-center justify-center">
                        <div className="flex-1 border-t border-gray-300 px-2"></div>
                        <span className="text-gray-500 text-xs px-2">
                          {monthYear}
                        </span>
                        <div className="flex-1 border-t border-gray-300"></div>
                      </div>
                      {Object.keys(groupedEvents[monthYear])
                        .sort((a, b) => parseInt(a) - parseInt(b))
                        .map((date, dateIdx) => (
                          <div key={dateIdx}>
                            <div className="px-4 py-2">
                              <span className="text-secondary text-sm">
                                {moment(
                                  `${date} ${monthYear}`,
                                  "DD MMMM YYYY"
                                ).format("DD MMMM")}
                              </span>
                            </div>
                            <div className="px-4 py-1">
                              <div className="flex items-center">
                                <span
                                  className="w-3 h-3 rounded-full mr-2 bg-red-200 flex-shrink-0"
                                  style={{
                                    backgroundColor:
                                      EVENTS_COLORS[
                                        groupedEvents[monthYear][date][0]
                                          .category
                                      ],
                                  }}
                                ></span>
                                <span className="text-secondary text-sm">
                                  {groupedEvents[monthYear][date][0].name}
                                </span>
                              </div>
                            </div>

                            {expandedDates[`${monthYear}-${date}`] &&
                              groupedEvents[monthYear][date]
                                .slice(1)
                                .map((event, eventIdx) => (
                                  <div key={eventIdx} className="px-4 py-1 ">
                                    <div className="flex items-center">
                                      <span
                                        className="w-3 h-3 rounded-full mr-2 flex-shrink-0"
                                        style={{
                                          backgroundColor:
                                            EVENTS_COLORS[event.category],
                                        }}
                                      ></span>
                                      <span className="text-secondary text-sm line-clamp-1 flex-grow">
                                        {event.name}
                                      </span>
                                    </div>
                                  </div>
                                ))}
                            {groupedEvents[monthYear][date].length > 1 && (
                              <div
                                className="px-4 py-1 text-blue-500 cursor-pointer text-xs select-none "
                                onClick={() => toggleExpand(monthYear, date)}
                              >
                                {expandedDates[`${monthYear}-${date}`]
                                  ? "Show Less"
                                  : "Show More ..."}
                              </div>
                            )}
                          </div>
                        ))}
                    </div>
                  ))
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Keyevents;
