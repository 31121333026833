import React, { useCallback, useEffect, useRef, useState } from "react";
import { FeedItem } from "../../../interface/DesignerInterface";
import { ReactComponent as RightArrow } from "../../../assets/icons/rightArrow.svg";
import { ReactComponent as LeftArrow } from "../../../assets/icons/leftArrow.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import type { Swiper as SwiperType } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import InstaPost from "../InstaPost";
import { Board } from "../../../interface/BoardsInterface";
import {
  instaPostHeightWhenTrendsClosed,
  instaPostHeightWhenTrendsOpen,
  instaPostWidthWhenTrendsClosed,
  instaPostWidthWhenTrendsOpen,
  minSpaceBetweenItemsInCarousel,
  preferencesClosedWidth,
  preferencesOpenWidth,
  sidebarClosedWidth,
  sidebarOpenWidth,
  trendsClosedWidth,
  trendsOpenWidth,
} from "../../../constants/constants";

type SwiperType = {
  activeIndex: number;
};

interface CarouselProps {
  isSidebarOpen: boolean;
  sidebarOpen: boolean;
  trendsOpen: boolean;
  data: FeedItem[];
  privateBoards: Board[];
  setPrivateBoards: React.Dispatch<React.SetStateAction<Board[]>>;
  publicBoards: Board[];
  setPublicBoards: React.Dispatch<React.SetStateAction<Board[]>>;
  userId: string;
  trendSnapshotView?: boolean;
  archive?: boolean;
}

const Carousel: React.FC<CarouselProps> = ({
  isSidebarOpen,
  sidebarOpen,
  data,
  trendsOpen,
  privateBoards,
  setPrivateBoards,
  publicBoards,
  setPublicBoards,
  userId,
  trendSnapshotView,
  archive,
}) => {
  const [itemsPerSlide, setItemsPerSlide] = useState<number>(1);
  const [spacePerItems, setSpacePerItems] = useState<number>(10);
  const [currentSlideIndex, setCurrentSlideIndex] = useState<number>(0);

  const divRef = useRef<HTMLDivElement | null>(null);

  const calculateWidth = useCallback((): string => {
    const sidebarWidth = !isSidebarOpen ? sidebarOpenWidth : sidebarClosedWidth;
    const preferencesWidth = !sidebarOpen
      ? preferencesOpenWidth
      : preferencesClosedWidth;
    const trendsSidebarWidth = trendsOpen ? trendsOpenWidth : trendsClosedWidth;
    let totalSidebarWidth: number;
    if (archive) {
      totalSidebarWidth = sidebarWidth;
      const availableWidth = `calc(100vw - ${totalSidebarWidth}px - 36px)`;
      return availableWidth;
    } else {
      totalSidebarWidth = sidebarWidth + preferencesWidth + trendsSidebarWidth;
      const availableWidth = `calc(100vw - ${totalSidebarWidth}px - 60px + ${trendSnapshotView ? "25px" : "0px"})`;
      return availableWidth;
    }
  }, [isSidebarOpen, sidebarOpen, trendsOpen]);

  useEffect(() => {
    const handleResize = () => {
      const mainDiv = divRef.current;
      if (!mainDiv) return;

      const width = mainDiv.offsetWidth;
      const widthOfItem = trendsOpen
        ? instaPostWidthWhenTrendsOpen
        : instaPostWidthWhenTrendsClosed;

      let items = Math.floor(width / widthOfItem);
      let space = 0;
      const minSpace = minSpaceBetweenItemsInCarousel;

      while (items > 1) {
        space = Math.floor((width - items * widthOfItem) / (items - 1));
        if (space >= minSpace) break;
        items--;
      }
      space = items > 1 ? space : width % widthOfItem;

      setItemsPerSlide(items);
      setSpacePerItems(space);
    };

    handleResize(); // Initial calculation
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [isSidebarOpen, sidebarOpen, trendsOpen]);

  const handleSlideChange = (swiper: SwiperType) => {
    setCurrentSlideIndex(swiper.activeIndex);
  };

  return (
    <div className="relative" ref={divRef} style={{ width: calculateWidth() }}>
      <Swiper
        modules={[Navigation, Pagination]}
        spaceBetween={spacePerItems}
        slidesPerView={itemsPerSlide}
        slidesPerGroup={itemsPerSlide}
        navigation={{
          nextEl: ".custom-next",
          prevEl: ".custom-prev",
        }}
        pagination={false}
        loop={false}
        onActiveIndexChange={handleSlideChange}
      >
        {data.map((item, index) => (
          <SwiperSlide key={index}>
            <InstaPost
              item={item}
              trendsOpen={trendsOpen}
              privateBoards={privateBoards}
              setPrivateBoards={setPrivateBoards}
              publicBoards={publicBoards}
              setPublicBoards={setPublicBoards}
              userId={userId}
            />
          </SwiperSlide>
        ))}

        {/* Add dummy InstaPosts if data length is less than itemsPerSlide */}
        {Array.from({ length: Math.max(0, itemsPerSlide - data.length) }).map(
          (_, index) => (
            <SwiperSlide key={`dummy-${index}`}>
              <div
                className="flex items-center justify-center border rounded-2xl h-full bg-lightGray"
                style={{
                  width: trendsOpen
                    ? instaPostWidthWhenTrendsOpen
                    : instaPostWidthWhenTrendsClosed,
                  height: trendsOpen
                    ? instaPostHeightWhenTrendsOpen
                    : instaPostHeightWhenTrendsClosed,
                }}
              >
                <span className="text-xl font-bold text-secondary tracking-widest font-poppins">
                  ICH
                </span>
              </div>
            </SwiperSlide>
          )
        )}

        {/* Custom Navigation Buttons */}
        <div
          className={`custom-prev absolute top-1/3 left-3 transform  cursor-pointer z-10 bg-white rounded-full h-8 w-8 flex justify-center items-center ${currentSlideIndex === 0 ? "hidden" : ""}`}
        >
          <LeftArrow className="w-5 h-5" />
        </div>
        <div
          className={`custom-next absolute top-1/3 right-3 transform cursor-pointer z-10 bg-white rounded-full h-8 w-8 flex justify-center items-center ${
            currentSlideIndex >= data.length - itemsPerSlide ? "hidden" : ""
          }`}
        >
          <RightArrow className="w-5 h-5" />
        </div>
      </Swiper>
      <div className="text-center">
        <span className="text-sm">
          {Math.ceil(currentSlideIndex / itemsPerSlide) + 1}/
          {Math.ceil(data.length / itemsPerSlide)}
        </span>
      </div>
    </div>
  );
};

export default Carousel;
