import {
  collection,
  limit,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { firestore } from "../config/FirebaseConfig";
import { useEffect, useState } from "react";
import { INotification } from "../../interface/INotification";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { getUserId } from "../../app/api/user/user";
export function useNotifications() {
  const [notifications, setNotifications] = useState<INotification[]>([]);
  const user = useSelector((state: RootState) => state.user.userInfo);
  const userEmail = user?.email;
  const [userId, setUserId] = useState("");

  const getUserByEmail = async () => {
    if (userEmail) {
      const res = await getUserId(userEmail);
      setUserId(res.data.data[0]._id);
    }
  };

  useEffect(() => {
    getUserByEmail();
  }, []);
  useEffect(() => {
    if (!userId) return;
    const q = query(
      collection(firestore, `notifications`),
      // where("isRead", "==", false),
      where("userId", "==", userId),
      orderBy("timestamp", "desc"),
      limit(30)
    );

    const unsubscribe = onSnapshot(q, snapshot => {
      const newNotifications = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      })) as INotification[];
      setNotifications(newNotifications);
    });

    return () => unsubscribe();
  }, [userId]);
  return notifications;
}
