import React, { useRef, useState } from "react";
import { ReactComponent as ReturnArrow } from "../../assets/icons/returnArrow.svg";
import { ReactComponent as HeartIcon } from "../../assets/icons/heart.svg";
import { ReactComponent as Download } from "../../assets/icons/download.svg";
import { ReactComponent as CopyToClipboard } from "../../assets/icons/copyToClipboard.svg";
import { toast } from "react-toastify";
import { copyImageToClipboard } from "../../utils";
import { ReactComponent as BlackHeartIcon } from "../../assets/icons/heartWithBlack.svg";
import DeleteModal from "../Boards/DeleteModal";
import ReportExpandView from "./reportExpandView";
import Modal from "../Common/Modal";

type Props = {
  imageUrl: string;
  brandName: string;
  source: string;
  description: string;
  openSaveToBoard: (imageUrl: string) => void;
  openDownloadImage: (imageUrl: string, source: string) => void;
  postId: string;
  permalink: string;
  view?: boolean;
  boardId?: string;
  closeExpandView?: () => void;
  liveSince: string;
};

const ExpandView: React.FC<Props> = ({
  imageUrl,
  brandName,
  description,
  source,
  openSaveToBoard,
  openDownloadImage,
  postId,
  permalink,
  view,
  boardId,
  closeExpandView,
  liveSince,
}) => {
  const [removeContent, setRemoveContent] = useState(false);

  const imageRefs = useRef(new Map<string, HTMLDivElement>());
  const [isExpandViewOpen, setExpandViewOpen] = useState(false);
  const closeView = () => setExpandViewOpen(false);

  const handleCopyImageToClipboard = (imageUrl: string) => {
    copyImageToClipboard(imageUrl, `IG/${source}`);
  };

  const closeRemoveModal = () => setRemoveContent(false);

  return (
    <div className="flex justify-center items-center h-[50%] bg-gray-100">
      <div className="h-[50%] w-full">
        <div className="relative w-full">
          <div
            ref={el => {
              if (el) imageRefs.current.set(postId.toString(), el);
            }}
          >
            <img
              src={imageUrl}
              alt="image"
              className="rounded-lg w-full mb-4 object-cover"
            />
          </div>
          <div className="absolute top-0 right-0 bg-translucent text-white rounded-bl-lg rounded-tr-lg px-1 py-0.5 text-sm ">
            {source ? `IG/${source}` : brandName}
          </div>
          <div className="absolute bottom-2 left-2 flex items-center space-x-2">
            <button
              className="text-red-500 border bg-white border-red-500 rounded-full px-2 py-1 text-2xs"
              onClick={() => setExpandViewOpen(true)}
            >
              Report
            </button>
          </div>
          <div className="absolute bottom-2 right-2 flex items-center space-x-2">
            <div className="flex space-x-1">
              {!view && (
                <div
                  className="bg-white flex p-1 items-center justify-center rounded-full cursor-pointer"
                  onClick={() => {
                    window.open(`${permalink}`, "_blank");
                  }}
                >
                  <ReturnArrow />
                </div>
              )}
              {view ? (
                <div
                  className="bg-white flex w-5 h-5 items-center justify-center rounded-full cursor-pointer"
                  onClick={() => setRemoveContent(true)}
                >
                  <BlackHeartIcon />
                </div>
              ) : (
                <div
                  className="bg-white flex p-1 items-center justify-center rounded-full"
                  onClick={() => openSaveToBoard(imageUrl)}
                >
                  <HeartIcon />
                </div>
              )}
              <div
                className="bg-white flex p-1 items-center justify-center rounded-full cursor-pointer"
                onClick={() => openDownloadImage(imageUrl, source)}
              >
                <Download />
              </div>
              <div
                className="bg-white flex p-1 items-center justify-center rounded-full cursor-pointer"
                onClick={() => handleCopyImageToClipboard(imageUrl)}
              >
                <CopyToClipboard />
              </div>
            </div>
          </div>
        </div>
        <div className="text-gray-700 mb-4">{brandName}</div>
        <div className="relative">
          <textarea
            readOnly
            className="bg-gray-100 border-2 p-4 rounded-lg text-gray-700 w-full h-32 resize-none outline-none"
          >
            {description}
          </textarea>
          <button
            className="absolute bottom-3 right-6 text-gray-500 hover:text-gray-700"
            onClick={() => {
              navigator.clipboard.writeText(description);
              toast.success("Description copied to clipboard.", {
                hideProgressBar: true,
              });
            }}
          >
            <CopyToClipboard className="w-4 h-4" />
          </button>
        </div>
      </div>

      {removeContent && (
        <Modal isOpen={removeContent} onClose={closeRemoveModal} title="">
          <DeleteModal
            onClose={closeRemoveModal}
            contentId={boardId as string}
            closeExpandView={closeExpandView}
          />
        </Modal>
      )}
      <Modal isOpen={isExpandViewOpen} onClose={closeView} title="Report">
        <ReportExpandView
          imageUrl={imageUrl}
          source={permalink}
          liveSince={liveSince}
          postId={postId}
          closeView={closeView}
        />
      </Modal>
    </div>
  );
};

export default ExpandView;
