import React, { useRef, useState } from "react";
import { ReactComponent as ReturnArrow } from "../../assets/icons/returnArrow.svg";
import { ReactComponent as HeartIcon } from "../../assets/icons/heart.svg";
import { ReactComponent as Download } from "../../assets/icons/download.svg";
import { ReactComponent as CopyToClipboard } from "../../assets/icons/copyToClipboard.svg";
import { toast } from "react-toastify";
import { copyImageToClipboard } from "../../utils";
import { ReactComponent as BlackHeartIcon } from "../../assets/icons/heartWithBlack.svg";
import Modal from "../Common/Modal";
import DeleteModal from "../Boards/DeleteModal";
import moment from "moment";
import { Content } from "../../interface/ContentInterface";
import { SelectedPost } from "../../interface/DesignerInterface";

type Props = {
  imageUrl: string;
  brandName: string;
  source: string;
  description: string;
  openSaveToBoard: (imageUrl: string) => void;
  openDownloadImage: (imageUrl: string, source: string) => void;
  postId: string;
  permalink: string;
  view?: boolean;
  closeExpandView?: () => void;
  date?: string;
  notes?: string;
  userBoardAccessLevelIds?: string[];
  activeTabId?: string;
  selectedPost?: SelectedPost;
  contentId?: string;
  boardId?: string;
  setContentData?: React.Dispatch<React.SetStateAction<Content[]>>;
};

const DesignerExpandView: React.FC<Props> = ({
  imageUrl,
  brandName,
  description,
  source,
  openSaveToBoard,
  openDownloadImage,
  postId,
  permalink,
  view,
  boardId,
  closeExpandView,
  date,
  notes,
  selectedPost,
  activeTabId,
  userBoardAccessLevelIds,
  contentId,
  setContentData,
}) => {
  const [removeContent, setRemoveContent] = useState(false);

  const imageRefs = useRef(new Map<string, HTMLDivElement>());

  const handleCopyImageToClipboard = (imageUrl: string) => {
    copyImageToClipboard(imageUrl, `IG/${source}`);
  };

  const closeRemoveModal = () => setRemoveContent(false);

  const handleRemoveContent = async () => {
    if (activeTabId) {
      if (userBoardAccessLevelIds?.includes(activeTabId || "")) {
        await setRemoveContent(true);
      } else {
        toast.error("You don't have edit access", {
          hideProgressBar: true,
        });
      }
    }
  };

  return (
    <div className="flex justify-center items-center h-[50%]  bg-gray-100">
      <div className="flex flex-row">
        <div className="relative w-1/2">
          <div
            ref={el => {
              if (el) imageRefs.current.set(postId.toString(), el);
            }}
          >
            <img
              src={imageUrl}
              alt="image"
              className="rounded-lg w-full mb-4 object-cover"
            />
          </div>
          <div className="absolute top-0 right-0 bg-translucent text-white rounded-bl-lg rounded-tr-lg px-1 py-0.5 text-sm">
            {source ? `IG/${source}` : brandName}
          </div>

          <div className="absolute bottom-6 right-2 flex items-center space-x-2">
            <div className="flex space-x-1">
              <div
                className="bg-white flex p-1 items-center justify-center rounded-full cursor-pointer"
                onClick={() => {
                  window.open(`${permalink}`, "_blank");
                }}
              >
                <ReturnArrow />
              </div>
              {view ? (
                <div
                  className="bg-white flex w-5 h-5 items-center justify-center rounded-full cursor-pointer"
                  onClick={() => handleRemoveContent()}
                >
                  <BlackHeartIcon />
                </div>
              ) : (
                <div
                  className="bg-white flex p-1 items-center justify-center rounded-full"
                  onClick={() => openSaveToBoard(imageUrl)}
                >
                  <HeartIcon />
                </div>
              )}
              <div
                className="bg-white flex p-1 items-center justify-center rounded-full cursor-pointer"
                onClick={() => openDownloadImage(imageUrl, source)}
              >
                <Download />
              </div>
              <div
                className="bg-white flex p-1 items-center justify-center rounded-full cursor-pointer"
                onClick={() => handleCopyImageToClipboard(imageUrl)}
              >
                <CopyToClipboard />
              </div>
            </div>
          </div>
        </div>
        <div className="w-1/2 ml-4">
          <div className="text-primary text-sm font-bold mb-3">
            Source Information
          </div>

          <div className="text-xs mb-1 text-primary">Brand: {brandName}</div>
          <div className="text-xs mb-1 text-primary">
            Date: {moment(date).format("DD/MM/YYYY")}
          </div>
          <div className="relative text-xs text-secondary h-[350px] overflow-y-auto">
            {description}
            <button
              className="absolute bottom-3 right-6 text-gray-500 hover:text-gray-700"
              onClick={() => {
                navigator.clipboard.writeText(description);
                toast.success("Description copied to clipboard.", {
                  hideProgressBar: true,
                });
              }}
            >
              <CopyToClipboard className="w-4 h-4" />
            </button>
          </div>
          <div className="text-primary text-sm font-bold mb-2">Notes Added</div>

          <div className="text-xs mb-1 text-primary">
            {notes ? notes : "No Notes added"}
          </div>
        </div>
      </div>

      {removeContent && (
        <Modal isOpen={removeContent} onClose={closeRemoveModal} title="">
          <DeleteModal
            onClose={closeRemoveModal}
            contentId={contentId as string}
            closeExpandView={closeExpandView}
            fromExpandedView={true}
            boardId={boardId}
            setContentData={setContentData}
          />
        </Modal>
      )}
    </div>
  );
};

export default DesignerExpandView;
