import React, { useEffect, useState } from "react";
import { ReactComponent as DescendingHamburger } from "../assets/icons/decendingHamburger.svg";
import { ReactComponent as ArrowUp } from "../assets/icons/arrowUp.svg";
import { useNavigate } from "react-router-dom";
import { getAllTickets } from "../app/api/admin/tickets";
import { formatDateWithTime, capitalizeFirstLetter } from "../utils";

interface Ticket {
  image: string;
  source: string;
  issueType: string;
  liveSince: string;
  createdBy: string;
  priority: "High" | "Medium" | "Low" | "Critical";
  reason: string;
  email: string;
  status: string;
  postId: string;
  userId: string;
  _id: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
}
type Props = {
  isSidebarOpen: boolean;
};

const IssueTable: React.FC<Props> = ({ isSidebarOpen }) => {
  const [tickets, setTickets] = useState<Ticket[]>([]);
  const [originalTickets, setOriginalTickets] = useState<Ticket[]>([]);
  const [isAscending, setIsAscending] = useState<boolean>(true);
  const [isStatusSorted, setIsStatusSorted] = useState<boolean>(false);
  const [isPriorityAscending, setIsPriorityAscending] = useState<boolean>(true);
  const [isCreatedByAscending, setIsCreatedByAscending] =
    useState<boolean>(true);

  const navigate = useNavigate();

  const getTickets = async () => {
    const ticketsData = await getAllTickets();
    const sortedTickets = ticketsData.sort(
      (a: Ticket, b: Ticket) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    );
    setTickets(sortedTickets);
    setOriginalTickets(sortedTickets);
  };

  useEffect(() => {
    getTickets();
  }, []);

  const toggleSortOrder = () => {
    const sortedTickets = [...tickets].sort((a: Ticket, b: Ticket) =>
      isAscending
        ? new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
        : new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    );
    setTickets(sortedTickets);
    setIsAscending(!isAscending);
  };

  const sortByStatus = () => {
    if (isStatusSorted) {
      setTickets(originalTickets);
    } else {
      const sortedTickets = [...tickets].sort((a: Ticket, b: Ticket) => {
        if (a.status === "open" && b.status !== "open") return -1;
        if (a.status !== "open" && b.status === "open") return 1;
        return 0;
      });
      setTickets(sortedTickets);
    }
    setIsStatusSorted(!isStatusSorted);
  };

  const togglePriority = () => {
    if (isPriorityAscending) {
      const sortedTickets = [...tickets].sort((a: Ticket, b: Ticket) => {
        const priorityOrder = { Critical: 1, High: 2, Medium: 3, Low: 4 };
        return priorityOrder[a.priority] - priorityOrder[b.priority];
      });
      setTickets(sortedTickets);
    } else {
      const sortedTickets = [...tickets].sort((a: Ticket, b: Ticket) => {
        const priorityOrder = { Critical: 1, High: 2, Medium: 3, Low: 4 };
        return priorityOrder[b.priority] - priorityOrder[a.priority];
      });
      setTickets(sortedTickets);
    }
    setIsPriorityAscending(!isPriorityAscending);
  };
  const toggleCreatedBy = () => {
    if (isCreatedByAscending) {
      const sortedTickets = [...tickets].sort((a: Ticket, b: Ticket) =>
        a.createdBy.localeCompare(b.createdBy)
      );
      setTickets(sortedTickets);
    } else {
      const sortedTickets = [...tickets].sort((a: Ticket, b: Ticket) =>
        b.createdBy.localeCompare(a.createdBy)
      );
      setTickets(sortedTickets);
    }
    setIsCreatedByAscending(!isCreatedByAscending);
  };

  const handleNavigate = (id: string) => {
    navigate(`/tickets/review/${id}`);
  };

  return (
    <div
      className={`${!isSidebarOpen ? "w-[calc(100vw-265px)]" : "w-[calc(100vw-105px)]"} fixed transition-all duration-500 ease-in-out overflow-y-scroll h-[calc(100vh-80px)] ml-1`}
    >
      {/* Header Row */}
      <div className="flex  border-b-2 border-gray-200 sticky top-0 z-10 bg-gray-100 bg-white">
        <div className="w-[7.5%] border-r-2 border-gray-200 text-primary font-bold font-poppins px-4 py-2">
          Sr. No.
        </div>
        <div className="w-[12.5%] border-r-2 border-gray-200 text-primary font-bold font-poppins px-6 py-3 text-center">
          Image
        </div>
        <div className="w-[17.5%] border-r-2 border-gray-200 text-primary font-bold font-poppins px-6 py-3 text-center">
          Source
        </div>
        <div className="w-[12.5%] border-r-2 border-gray-200 text-primary font-bold font-poppins px-6 py-3 relative">
          Created On
          <div
            className="absolute top-[20%] right-1 cursor-pointer"
            onClick={toggleSortOrder}
          >
            <DescendingHamburger />
          </div>
        </div>
        <div className="w-[12.5%] relative border-r-2 border-gray-200 text-primary font-bold font-poppins px-6 py-3">
          <span>Created By</span>
          <div
            className="absolute top-[20%] right-1 cursor-pointer"
            onClick={toggleCreatedBy}
          >
            <DescendingHamburger />
          </div>
        </div>
        <div className="w-[12.5%] border-r-2 border-gray-200 text-primary font-bold font-poppins px-6 py-3">
          Issue Type
        </div>
        <div className="w-[12.5%] relative border-r-2 border-gray-200 text-primary font-bold font-poppins px-6 py-3">
          Priority
          <div
            className="absolute top-[20%] right-1 cursor-pointer"
            onClick={togglePriority}
          >
            <DescendingHamburger />
          </div>
        </div>
        <div className="w-[12.5%] text-primary font-bold font-poppins px-6 py-3 relative">
          Status
          <div
            className="absolute top-[20%] right-1 cursor-pointer"
            onClick={sortByStatus}
          >
            <DescendingHamburger />
          </div>
        </div>
      </div>

      {/* Data Rows */}
      {tickets.map((item, index) => (
        <div
          key={index}
          className="flex items-stretch bg-white hover:bg-lightGray text-sm border-b-2 border-gray-200"
          onClick={() => handleNavigate(item._id)}
        >
          <div className="w-[7.5%] flex justify-center items-center text-center text-gray-900 px-4 py-2 border-r-2 border-gray-200">
            {index + 1}
          </div>
          <div className="w-[12.5%] flex justify-center items-center px-4 py-2 border-r-2 border-gray-200">
            <img
              src={item.image}
              alt={`Image ${index}`}
              className="w-16 h-16 object-cover rounded"
            />
          </div>
          <div className="w-[17.5%] flex items-center px-1 py-2 text-gray-500 text-left overflow-hidden text-[12px] font-poppins border-r-2 border-gray-200">
            {item.source}
          </div>
          <div className="w-[12.5%] flex  justify-center items-center px-4 py-2 text-gray-500 text-center text-[11px] font-poppins border-r-2 border-gray-200 ">
            {formatDateWithTime(new Date(item.createdAt))}
          </div>
          <div className="w-[12.5%] flex justify-center items-center px-4 py-2 text-gray-500 text-center font-poppins border-r-2 border-gray-200">
            <span className="text-center">{item.createdBy}</span>
          </div>
          <div className="w-[12.5%] flex justify-center items-center px-4 py-2 text-gray-500 text-center font-poppins border-r-2 border-gray-200">
            <span className="text-center">{item.issueType}</span>
          </div>
          <div className="w-[12.5%] flex justify-center items-center text-center px-4 py-2 text-gray-500 font-poppins border-r-2 border-gray-200">
            <span
              className={`
                    ${
                      item.priority === "Critical"
                        ? "text-[#fe3839]"
                        : item.priority === "High"
                          ? "text-[#ffb403]"
                          : item.priority === "Medium"
                            ? "text-[#fce83b]"
                            : "text-[#57f000]"
                    }
                  `}
            >
              {capitalizeFirstLetter(item.priority)}
            </span>
          </div>
          <div className="w-[12.5%] flex justify-center items-center py-2 border-r-2 border-gray-200">
            <div
              className={`w-[143px] py-1 rounded-xl text-center ${item.status === "open" ? "bg-green-200 text-green-600" : "bg-gray"} font-poppins`}
            >
              {capitalizeFirstLetter(item.status)}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default IssueTable;
