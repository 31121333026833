import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import AuthLayout from "./components/Layout/AuthLayout";
import DashboardLayout from "./layouts/DashboardLayout";
import HomePage from "./pages/HomePage";
import Target from "./pages/Content/Target";
import Industries from "./pages/Content/Industries";
import DailyViralFeed from "./pages/Feed/DailyViralFeed";
import InnovationFeed from "./pages/Feed/InnovationFeed";
import ContentGenerator from "./pages/GenerativeFeature/ContentGenerator";
import SentimentAnalyser from "./pages/GenerativeFeature/SentimentAnalyser";
import Invite from "./pages/Invite";
import Boards from "./pages/Boards";
import DesignerPage from "./pages/DesignerPage";
import FeatureTracking from "./pages/FeatureTracking";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AcceptInvitation from "./pages/auth/AcceptInvitation";
import ForgotPassword from "./pages/auth/ForgotPassword";
import ResetPassword from "./pages/auth/ResetPassword";
import PrivateRoute from "./pages/auth/PrivateRoute";
import RoleBasedRoute from "./pages/auth/RoleBasedRoute";
import Login from "./pages/auth/Login";
import NotFound404 from "./pages/NotFound404";
import BoardImages from "./pages/Boards/BoardImages";
import "./App.css";
import { useLoadingWithRefresh } from "./app/hooks/useLoadingWithRefresh";
import { useSelector } from "react-redux";
import { RootState } from "./app/store";
import { useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Tickets from "./pages/Tickets";
import ReviewReport from "./components/Tickets/ReviewReport";
import ExpandViewOverallAnalysis from "./components/DesignerPage/TrendsSidebar/ExpanviewOverallAnalysis";
import ExpandViewTrendCurves from "./components/DesignerPage/TrendsSidebar/ExpandViewTrendCurves";
import TrendSnapshotDetails from "./components/DesignerPage/TrendsSnapshot/TrendSnapshotDetails";
import TrendSnapshot from "./pages/TrendSnapshot";
import Archive from "./pages/Archive";

function App() {
  const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);
  const { loading } = useLoadingWithRefresh();
  const navigate = useNavigate();

  const [trendsOpen, setTrendsOpen] = React.useState<boolean>(false);
  const isAuthenticated = useSelector(
    (state: RootState) => state.user.userInfo
  );

  useEffect(() => {
    const publicRoutes = [
      "/auth/login",
      "/auth/forgot-password",
      "/auth/reset-password",
      "/auth/accept-invitation",
    ];
    if (
      !publicRoutes.includes(window.location.pathname) &&
      !loading &&
      !isAuthenticated
    ) {
      navigate("/auth/login", { replace: true });
    }
  }, [loading, isAuthenticated, navigate]);

  useEffect(() => {
    if (trendsOpen === true && isSidebarOpen === false) {
      setIsSidebarOpen(true);
    }
  }, [trendsOpen]);

  useEffect(() => {
    if (trendsOpen === true && isSidebarOpen === false) {
      setTrendsOpen(false);
    }
  }, [isSidebarOpen]);

  return loading ? (
    <div className="flex">
      <div className="w-64 h-screen bg-gray-100 p-4">
        <Skeleton height="100%" width="100%" className="mb-4" />
      </div>
      <div className="flex-1 flex flex-col">
        <div className="h-24 p-4">
          <Skeleton height="100%" width="100%" />
        </div>
        <div className="h-full p-4">
          <Skeleton height="100%" width="100%" />
        </div>
      </div>
    </div>
  ) : (
    <>
      <ToastContainer />
      <Routes>
        <Route path="/auth" element={<AuthLayout />}>
          <Route path="login" element={<Login />} />
          <Route path="accept-invitation" element={<AcceptInvitation />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="reset-password" element={<ResetPassword />} />
        </Route>

        <Route
          path="/"
          element={
            <PrivateRoute>
              <DashboardLayout
                isSidebarOpen={isSidebarOpen}
                setIsSidebarOpen={setIsSidebarOpen}
              />
            </PrivateRoute>
          }
        >
          <Route path="/" index element={<HomePage />} />
          <Route
            path="content/target"
            element={
              <RoleBasedRoute
                roles={["admin"]}
                element={<Target isSidebarOpen={isSidebarOpen} />}
              />
            }
          />
          <Route
            path="content/industries"
            element={
              <RoleBasedRoute roles={["admin"]} element={<Industries />} />
            }
          />
          <Route
            path="feed/daily-viral-feed"
            element={
              <RoleBasedRoute
                roles={["admin", "forecaster"]}
                element={<DailyViralFeed />}
              />
            }
          />
          <Route
            path="feed/innovation-feed"
            element={
              <RoleBasedRoute
                roles={["admin", "forecaster"]}
                element={<InnovationFeed />}
              />
            }
          />
          <Route
            path="generative-feature/content-generator"
            element={
              <RoleBasedRoute
                roles={["admin", "forecaster"]}
                element={<ContentGenerator isSidebarOpen={isSidebarOpen} />}
              />
            }
          />
          <Route
            path="generative-feature/sentiment-analyser"
            element={
              <RoleBasedRoute
                roles={["admin", "forecaster"]}
                element={<SentimentAnalyser isSidebarOpen={isSidebarOpen} />}
              />
            }
          />
          <Route
            path="boards"
            element={
              <RoleBasedRoute
                roles={["admin", "forecaster"]}
                element={<Boards isSidebarOpen={isSidebarOpen} />}
              />
            }
          />
          <Route
            path="boards/:boardId"
            element={
              <RoleBasedRoute
                roles={["admin", "forecaster"]}
                element={<BoardImages isSidebarOpen={isSidebarOpen} />}
              />
            }
          />

          <Route
            path="designer-page"
            element={
              <RoleBasedRoute
                roles={["admin", "forecaster"]}
                element={
                  <DesignerPage
                    isSidebarOpen={isSidebarOpen}
                    trendsOpen={trendsOpen}
                    setTrendsOpen={setTrendsOpen}
                  />
                }
              />
            }
          />

          <Route
            path="designer-page/overall-analysis"
            element={
              <RoleBasedRoute
                roles={["admin", "forecaster"]}
                element={
                  <ExpandViewOverallAnalysis isSidebarOpen={isSidebarOpen} />
                }
              />
            }
          />
          <Route
            path="designer-page/archive"
            element={
              <RoleBasedRoute
                roles={["admin", "forecaster"]}
                element={<Archive isSidebarOpen={isSidebarOpen} />}
              />
            }
          />

          <Route
            path="designer-page/trend-curves"
            element={
              <RoleBasedRoute
                roles={["admin", "forecaster"]}
                element={
                  <ExpandViewTrendCurves isSidebarOpen={isSidebarOpen} />
                }
              />
            }
          />
          <Route
            path="feature-tracking"
            element={
              <RoleBasedRoute roles={["admin"]} element={<FeatureTracking />} />
            }
          />
          <Route
            path="invite"
            element={
              <RoleBasedRoute
                roles={["admin"]}
                element={<Invite isSidebarOpen={isSidebarOpen} />}
              />
            }
          />
          <Route
            path="tickets"
            element={
              <RoleBasedRoute
                roles={["admin"]}
                element={<Tickets isSidebarOpen={isSidebarOpen} />}
              />
            }
          />
          <Route
            path="tickets/review/:id"
            element={
              <RoleBasedRoute
                roles={["admin"]}
                element={<ReviewReport isSidebarOpen={isSidebarOpen} />}
              />
            }
          />
          <Route
            path="trend-snapshot/details/:id"
            element={
              <RoleBasedRoute
                roles={["admin", "forecaster"]}
                element={
                  <TrendSnapshotDetails
                    isSidebarOpen={isSidebarOpen}
                    trendsOpen={trendsOpen}
                    setTrendsOpen={setTrendsOpen}
                  />
                }
              />
            }
          />
          <Route
            path="trend-snapshot"
            element={
              <RoleBasedRoute
                roles={["admin", "forecaster"]}
                element={<TrendSnapshot isSidebarOpen={isSidebarOpen} />}
              />
            }
          />
        </Route>

        <Route path="*" element={<NotFound404 />} />
      </Routes>
    </>
  );
}

export default App;
