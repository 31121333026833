import React, { useEffect, useRef, useState } from "react";

import {
  Chart as ChartJS,
  RadialLinearScale,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  ChartEvent,
  ActiveElement,
  ChartOptions,
  PointElement,
  LineElement,
} from "chart.js";
import {
  Bar,
  Doughnut,
  Pie,
  Line,
  PolarArea,
  getElementAtEvent,
  Scatter,
} from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels"; // Import the data labels plugin
import "chartjs-plugin-datalabels"; // Ensure you have this plugin imported
import { Chart } from "chart.js";
ChartJS.register(
  RadialLinearScale,
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  Tooltip,
  Legend,
  Title,
  ChartDataLabels,
  ChartDataLabels
);

interface ColorAnalysisProps {
  analysisOpen?: boolean;
}
const ColorAnalysis: React.FC<ColorAnalysisProps> = ({ analysisOpen }) => {
  const [showDoughnut, setShowDoughnut] = useState(false);
  const [selectedColor, setSelectedColor] = useState<string>("#ffffff");
  const [selectedColorLabel, setSelectedColorLabel] = useState<string>("");
  const pieChartRef = useRef<ChartJS<"pie", number[], unknown>>(null);
  const doughnutChartRef = useRef<ChartJS<
    "doughnut",
    number[],
    unknown
  > | null>(null);

  const pieData: {
    labels: string[];
    datasets: {
      data: number[];
      backgroundColor: string[];
      borderWidth: number;
    }[];
  } = {
    labels: [
      "Red",
      "Blue",
      "Yellow",
      "Green",
      "Purple",
      "Orange",
      "Black",
      "Pink",
    ],
    datasets: [
      {
        data: [12, 2, 3, 5, 2, 3, 1, 3],
        backgroundColor: [
          "#eb4034",
          "#1a41db",
          "#ebe534",
          "#34eb34",
          "#ae34eb",
          "#eb8334",
          "#000000",
          "#db1a98",
        ],
        borderWidth: 0,
      },
    ],
  };

  // Define the options with the ChartOptions type for "pie" specifically
  const pieOptions: ChartOptions<"pie"> = {
    responsive: true,
    layout: {
      padding: 35,
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false, // Disable tooltip on hover for pie chart
      },

      datalabels: {
        anchor: "end",
        align: "end",
        font: {
          size: 6,
          weight: "normal",
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        formatter: (value: number, context: any) => {
          const label = context?.chart?.data?.labels[context.dataIndex];
          const total = context.dataset.data.reduce(
            (acc: number, value: number) => acc + value,
            0
          );
          const percentage = ((value / total) * 100).toFixed(1);
          return `${label} ${percentage}%`; // Combines label and percentage in one line
        },
        color: context => {
          return (context.dataset.backgroundColor as string[])[
            context.dataIndex
          ];
        },
        padding: 1,
        offset: 1,
      },
    },
  };

  const doughNutData: {
    labels: string[];
    datasets: {
      data: number[];
      backgroundColor: string[];
      borderWidth: number;
    }[];
  } = {
    labels: [
      "Red",
      "Blue",
      "Yellow",
      "Green",
      "Purple",
      "Orange",
      "Black",
      "Pink",
    ],
    datasets: [
      {
        data: [12, 2, 3, 5, 2, 3, 1, 3],
        backgroundColor: [
          "#ba5d9a",
          "#0e718a",
          "#0e168a",
          "#666991",
          "#a19f2d",
          "#eb8334",
          "#a1652d",
          "#db1a98",
        ],
        borderWidth: 0,
      },
    ],
  };

  const doughNutOptions: ChartOptions<"doughnut"> = {
    cutout: "60%",
    responsive: true,
    layout: {
      padding: 35, // Adds padding around the chart
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false, // Disable tooltip on hover for pie chart
      },
      datalabels: {
        font: {
          weight: "bold",
          size: 6, // Adjusted for better visibility
        },
        anchor: "end" as const,
        align: "end" as const,
        offset: 1, // Increase offset to position labels further outside
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        formatter: (value: number, context: any) => {
          const label = context?.chart?.data?.labels[context.dataIndex];
          const total = context.dataset.data.reduce(
            (acc: number, value: number) => acc + value,
            0
          );
          const percentage = ((value / total) * 100).toFixed(1);
          return `${label} ${percentage}%`;
        },
        color: context => {
          return (context.dataset.backgroundColor as string[])[
            context.dataIndex
          ];
        },
        padding: 1, // Adds padding around the labels themselves
      },
    },
  };

  const handlePieClick = (event: React.MouseEvent<HTMLCanvasElement>) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const chart: any = pieChartRef.current;

    if (chart) {
      const elements = getElementAtEvent(chart, event);

      if (elements.length > 0) {
        const { index } = elements[0];
        const clickedColor = pieData.datasets[0].backgroundColor[index];
        const clickedColorName = pieData.labels[index];

        // Set the selected color
        setSelectedColor(clickedColor);
        setSelectedColorLabel(clickedColorName);
        setShowDoughnut(true);
      }
    }
  };

  return (
    <>
      {analysisOpen && (
        <div className="flex flex-row justify-center">
          <div className="w-[200px] mt-4">
            <Pie
              ref={pieChartRef}
              data={pieData}
              options={pieOptions}
              onClick={handlePieClick}
            />
          </div>
          {showDoughnut && (
            <div className="w-[250px]">
              <Doughnut
                key={selectedColor}
                ref={doughnutChartRef}
                data={doughNutData}
                options={doughNutOptions}
                plugins={[
                  {
                    id: "innerCircleFill",
                    beforeDraw(chart) {
                      const {
                        ctx,
                        chartArea: { left, top, width, height },
                      } = chart;
                      const centerX = width / 2 + left;
                      const centerY = height / 2 + top;
                      const innerRadius = (Math.min(width, height) / 2) * 0.6; // Adjust size for the inner color fill

                      ctx.save();
                      ctx.fillStyle = selectedColor; // Set your desired fill color
                      ctx.beginPath();
                      ctx.arc(centerX, centerY, innerRadius, 0, Math.PI * 2); // Draw the inner circle
                      ctx.closePath();
                      ctx.fill();
                      ctx.restore();
                    },
                  },
                  {
                    id: "centerText",
                    beforeDraw(chart) {
                      const {
                        ctx,
                        chartArea: { top, left, width, height },
                      } = chart;
                      ctx.save();
                      ctx.font = "bold 20px Arial"; // Set your desired font
                      ctx.fillStyle = "#acbdcf"; // Center text color
                      ctx.textAlign = "center";
                      ctx.textBaseline = "middle";
                      const total = selectedColorLabel; // This could be dynamic if needed

                      // Draw the total text
                      ctx.fillText(
                        total.toString(),
                        width / 2 + left,
                        height / 2 + top
                      );
                      ctx.fillText("", width / 2 + left, height / 2 + top + 20); // Adjust the y-position as needed
                      ctx.restore();
                    },
                  },
                ]}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default ColorAnalysis;
