import React, {
  ChangeEvent,
  useState,
  useRef,
  useEffect,
  useMemo,
} from "react";
import { ReactComponent as LeftArrow } from "../../../assets/icons/leftArrow.svg";
import { ReactComponent as RightArrow } from "../../../assets/icons/rightArrow.svg";
import { ReactComponent as Minus } from "../../../assets/icons/minus.svg";
import { ReactComponent as Plus } from "../../../assets/icons/plus.svg";
import { toast } from "react-toastify";
import TrendsInput from "./TrendsInput";
import ColorAnalysis from "./ColorAnalysis";
import OverallAnalysis from "./OverallAnalysis";
import TrendCurves from "./TrendCurves";
import { useNavigate } from "react-router-dom";
import { GetTrendsParams } from "../../../app/api/trends";
import {
  materialsForBackend,
  stylingDetailsForBackend,
} from "../../../constants/constants";

interface TrendsProps {
  isTrendOpen: boolean;
  onTrendToggle: () => void;
  productType: string[];
  selectedProductType: string;
  setSelectedProductType: React.Dispatch<React.SetStateAction<string>>;
  materials: string[];
  selectedMaterial: string[];
  setSelectedMaterial: React.Dispatch<React.SetStateAction<string[]>>;
  stylingDetails: string[];
  selectedStylingDetail: string[];
  setSelectedStylingDetail: React.Dispatch<React.SetStateAction<string[]>>;
  runAnalysis: (value: number) => void;
  setAnalysisResultSuccess: React.Dispatch<React.SetStateAction<boolean>>;
  refChange: (value: string) => void;
  startDate: string;
  endDate: string;
  region: string;
  genre: string;
  product: string;
  gender: string;
  brands: string[];
  source: string;
  category: string;
  color: string;
  req_id: string;
  TrendsSidebarSnapshotExpandedView: boolean;
  analysisResultSuccess: boolean;
  ageGroup?: string;
  clear?: boolean;
  setClear?: React.Dispatch<React.SetStateAction<boolean>>;
}

const Trends: React.FC<TrendsProps> = ({
  isTrendOpen,
  onTrendToggle,
  productType,
  selectedProductType,
  setSelectedProductType,
  materials,
  selectedMaterial,
  setSelectedMaterial,
  stylingDetails,
  selectedStylingDetail,
  setSelectedStylingDetail,
  runAnalysis,
  setAnalysisResultSuccess,
  refChange,
  startDate,
  endDate,
  region,
  genre,
  product,
  gender,
  brands,
  source,
  category,
  color,
  req_id,
  TrendsSidebarSnapshotExpandedView,
  analysisResultSuccess,
  ageGroup,
  clear,
  setClear,
}) => {
  const [inputOpen, setInputOpen] = useState(true);
  const [analysisOpen, setAnalysisOpen] = useState(false);
  const [filterChanged, setFilterChanged] = useState(true);

  const navigate = useNavigate();

  const toggleInput = () => {
    setInputOpen(!inputOpen);
  };
  const toggleAnalysis = () => {
    setAnalysisOpen(!analysisOpen);
  };

  useEffect(() => {
    if (clear && setClear) {
      clearAll();
      setClear(false);
    }
  }, [clear]);

  const clearAll = () => {
    setSelectedProductType("");
    setSelectedMaterial([]);
    setSelectedStylingDetail([]);
    setAnalysisResultSuccess(false);
    sessionStorage.removeItem("trends");
    sessionStorage.removeItem("last_req_id");
    sessionStorage.removeItem("last_Archive_Arr");
  };

  const checkFilterChanged = () => {
    const sessionStorageData = sessionStorage.getItem("trends");
    const trendFilters: GetTrendsParams = JSON.parse(
      sessionStorageData || "{}"
    );
    if (Object.entries(trendFilters).length === 0) return true;
    // Region
    if (
      (trendFilters.region && trendFilters.region !== region) ||
      (!trendFilters.region && region)
    )
      return true;

    // Genre
    if (
      (trendFilters.genre && trendFilters.genre !== genre) ||
      (!trendFilters.genre && genre)
    )
      return true;

    // Product
    if (
      (trendFilters.product && trendFilters.product !== product) ||
      (!trendFilters.product && product)
    )
      return true;

    // Gender
    if (
      (trendFilters.gender && trendFilters.gender !== gender) ||
      (!trendFilters.gender && gender)
    )
      return true;

    // Brands
    if (trendFilters.brands) {
      const trendFiltersBrands = trendFilters.brands.split(",");
      if (trendFiltersBrands.length !== brands.length) return true;
      if (trendFiltersBrands && Array.isArray(trendFiltersBrands))
        if (areArraysDifferent(trendFiltersBrands, brands)) return true;
    } else if (brands.length > 0) return true;

    // Start Date
    if (
      (trendFilters.start_date &&
        trendFilters.start_date.split(" ")[0] !== startDate) ||
      (!trendFilters.start_date && startDate)
    )
      return true;

    // End Date
    if (
      (trendFilters.end_date &&
        trendFilters.end_date.split(" ")[0] !== endDate) ||
      (!trendFilters.end_date && endDate)
    )
      return true;

    // Age Group
    if (
      (trendFilters.age_group && trendFilters.age_group !== ageGroup) ||
      (!trendFilters.age_group && ageGroup)
    )
      return true;

    // Source
    if (
      (trendFilters.source && trendFilters.source !== source) ||
      (!trendFilters.source && source)
    )
      return true;

    // Category
    if (
      (trendFilters.category && trendFilters.category !== category) ||
      (!trendFilters.category && category)
    )
      return true;

    // Color
    if (
      (trendFilters.colors && trendFilters.colors !== color) ||
      (!trendFilters.colors && color)
    )
      return true;

    // Apparel Type/ Product Type
    if (trendFilters.apparel_type) {
      if (
        trendFilters.apparel_type !== "Blazers" &&
        selectedProductType !== trendFilters.apparel_type
      )
        return true;
      else if (
        selectedProductType === "Blazers & Suits" &&
        trendFilters.apparel_type !== "Blazers"
      )
        return true;
    }

    // Materials
    const materialToCheck = Object.entries(materialsForBackend)
      .filter(([key, _]) => Object.keys(trendFilters).includes(key))
      .map(([_, material]) => material.label);
    if (areArraysDifferent(materialToCheck, selectedMaterial)) return true;

    // Styling Details
    const stylingDetailToCheck = Object.entries(stylingDetailsForBackend)
      .filter(([key, _]) => Object.keys(trendFilters).includes(key))
      .map(([_, stylingDetail]) => stylingDetail.label);
    if (areArraysDifferent(stylingDetailToCheck, selectedStylingDetail))
      return true;
    return false;
  };

  const areArraysDifferent = (arr1: string[], arr2: string[]) => {
    if (arr1.length !== arr2.length) return true;

    const set = new Set(arr1);
    for (const item of arr2) {
      if (!set.has(item)) return true;
    }
    return false;
  };

  useEffect(() => {
    const filterChangedStatus = checkFilterChanged();
    setFilterChanged(filterChangedStatus);
  }, [
    selectedProductType,
    selectedMaterial,
    selectedStylingDetail,
    startDate,
    endDate,
    region,
    genre,
    product,
    gender,
    brands,
    source,
    category,
    color,
    req_id,
    ageGroup,
    sessionStorage.getItem("trends"),
  ]);

  return (
    <div
      className={` flex flex-col ${TrendsSidebarSnapshotExpandedView ? "bg-dashboard" : "bg-white"} shadow-md transition-all overflow-scroll no-scrollbar duration-500 ease-in-out ${
        isTrendOpen ? "w-[450px]" : "w-8"
      } font-poppins`}
    >
      <div className="flex flex-col h-[calc(100vh-160px)] overflow-y-auto scroller">
        <div>
          <div
            className={`sticky ml-1 top-0  z-10 ${TrendsSidebarSnapshotExpandedView ? "bg-dashboard" : "bg-white"} ease-in-out`}
          >
            <div
              className={`flex items-center text-primary rounded-2xl mb-2 cursor-pointer ${
                isTrendOpen ? "px-1 py-2" : "justify-center"
              }`}
              onClick={onTrendToggle}
            >
              {isTrendOpen ? (
                <div className="flex w-full  items-center">
                  <RightArrow className="text-lg mr-2" />
                  <span className="transition-all duration-500 ease-in-out opacity-100 font-semibold max-w-full ml-1 text-secondary">
                    Trends
                  </span>
                </div>
              ) : (
                <div className="flex flex-col items-center">
                  <LeftArrow className="text-lg mb-4" />
                  <div>
                    {"SDNERT".split("").map((letter, index) => (
                      <span
                        key={index}
                        className="block -rotate-90 font-semibold text-secondary"
                        style={{ marginBottom: "-8px" }}
                      >
                        {letter}
                      </span>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className={`py-2 px-1  space-y-2 ${isTrendOpen ? "" : "hidden"}`}>
          <div className="flex items-center space-x-2">
            <div className="cursor-pointer" onClick={toggleInput}>
              {inputOpen ? <Minus /> : <Plus />}
            </div>
            <div className="text-md font-semibold text-secondary opacity-100 font-poppins">
              Input
            </div>
          </div>
          <TrendsInput
            inputOpen={inputOpen}
            productType={productType}
            selectedProductType={selectedProductType}
            setSelectedProductType={setSelectedProductType}
            materials={materials}
            selectedMaterial={selectedMaterial}
            setSelectedMaterial={setSelectedMaterial}
            stylingDetails={stylingDetails}
            selectedStylingDetail={selectedStylingDetail}
            setSelectedStylingDetail={setSelectedStylingDetail}
          />
        </div>

        <div className={` space-y-2 ${isTrendOpen ? "" : "hidden"}`}>
          {/* <div className="flex items-center space-x-1 p-2">
            <div className="cursor-pointer" onClick={toggleAnalysis}>
              {analysisOpen ? <Minus /> : <Plus />}
            </div>
            <div className="transition-all duration-500 ease-in-out opacity-100 font-semibold max-w-full ml-1 text-secondary">
              Analysis
            </div>
          </div> */}
          {analysisOpen && (
            <>
              <div className="transition-all text-center duration-500 ease-in-out opacity-100 font-semibold max-w-full ml-1 text-secondary mt-2">
                Color Analysis
              </div>
              <div>
                <ColorAnalysis analysisOpen={analysisOpen} />
              </div>
              {/* <div className="text-sm text-center">
                Select above trend options
              </div> */}

              <div className="border-b-2 border-light "></div>
              <div>
                <div className="flex justify-center relative">
                  <div className="transition-all  duration-500 ease-in-out opacity-100 font-semibold max-w-full ml-1 text-secondary mt-2">
                    Overall Analysis
                  </div>

                  <div
                    className="absolute right-2 text-right text-xs underline font-bold	mt-3 cursor-pointer"
                    onClick={() => navigate("/designer-page/overall-analysis")}
                  >
                    Expand
                  </div>
                </div>
                <div>
                  <OverallAnalysis />
                </div>
                {/* <div className="text-sm text-center">
                  Select above trend options
                </div> */}
                <div className="border-b-2 border-light "></div>
              </div>

              <div>
                <div className="flex justify-center relative">
                  <div className="transition-all  duration-500 ease-in-out opacity-100 font-semibold max-w-full ml-1 text-secondary mt-4">
                    Trend Curves
                  </div>

                  <div
                    className="absolute right-2 text-right text-xs underline font-bold	mt-5 cursor-pointer"
                    onClick={() => navigate("/designer-page/trend-curves")}
                  >
                    Expand
                  </div>
                </div>
                <TrendCurves />
                {/* <div className="text-sm text-center">
                  Select above trend options
                </div> */}
              </div>
            </>
          )}
        </div>
      </div>
      <div
        className={`flex justify-evenly h-[80px] items-center border-t border-[#E9E9E9] ${isTrendOpen ? "" : "hidden"}`}
      >
        <button
          className="text-md bg-black disabled:bg-gray px-10 py-1 rounded-full font-[700]  text-white disabled:text-[#9E9E9E]"
          disabled={
            !filterChanged ||
            !selectedProductType ||
            TrendsSidebarSnapshotExpandedView
          }
          onClick={() => {
            refChange("");
            runAnalysis(1);
          }}
        >
          Run Analysis
        </button>
        <button
          className="text-black disabled:text-[#9E9E9E] font-[700] text-sm"
          onClick={clearAll}
          disabled={
            (!selectedProductType &&
              !selectedMaterial &&
              !selectedStylingDetail) ||
            TrendsSidebarSnapshotExpandedView
          }
        >
          Clear All
        </button>
      </div>
    </div>
  );
};

export default Trends;
