import React, {
  useState,
  useEffect,
  useRef,
  ChangeEvent,
  ClipboardEvent,
} from "react";
import { ReactComponent as Search } from "../assets/icons/search.svg";
import Preferences from "../components/DesignerPage/Preferences";
import { toast } from "react-toastify";
import { toPng } from "html-to-image";
import domtoimage from "dom-to-image";
import {
  getBrandsData,
  getDesignerFocusPosts,
  getDropdownData,
  getProducts,
  searchDesignerFocusPosts,
} from "../app/api/designerPage";
import {
  FeedItem,
  GetPostParams,
  GetBrandsParams,
  DropdownData,
  brandsParams,
} from "../interface/DesignerInterface";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import {
  areArraysEqual,
  copyImageToClipboard,
  getFileName,
  getKeyForS3DesignerPage,
} from "../utils";
import moment from "moment";
import { useSelector } from "react-redux";
import { RootState } from "../app/store";
import { getBoardsByUserId } from "../app/api/admin/board";
import { getUserId } from "../app/api/user/user";
import { Board } from "../interface/BoardsInterface";
import TrendsSidebar from "../components/DesignerPage/TrendsSidebar/TrendsSidebar";
import CameraIcon from "../assets/icons/CameraIcon";
import { v4 as uuidv4 } from "uuid";
import { uploadImage } from "../app/api/uploadTos3";
import { Oval } from "react-loader-spinner";
import CrossIcon from "../assets/icons/CrossIcon";
import CustomizedModal from "../components/Common/CustomisedModal";
import { set, throttle } from "lodash";
import { AxiosError } from "axios";
import InstaPost from "../components/DesignerPage/InstaPost";
import Trends from "../components/DesignerPage/Trends/Trends";
import { getProductTypes, getStylingDetails } from "../app/api/trendsFilter";
import {
  defaultMaterials,
  materialsForBackend,
  stylingDetailsForBackend,
} from "../constants/constants";
import { getTrends, GetTrendsParams } from "../app/api/trends";
import { TrendInterface } from "../interface/Trends";
import Pagination from "../components/Target/Pagination";
import TrendsSnapshotModal from "../components/DesignerPage/TrendsSnapshot/TrendsSnapshotModal";
import { useNavigate } from "react-router-dom";
import { getArchivedTrend } from "../app/api/archive";
import * as htmlToImage from "html-to-image";
type Props = {
  isSidebarOpen: boolean;
  trendsOpen: boolean;
  setTrendsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const DesignerPage: React.FC<Props> = ({
  isSidebarOpen,
  trendsOpen,
  setTrendsOpen,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);
  const [calendarOpen, setCalendarOpen] = useState<boolean>(false);
  const [posts, setPosts] = useState<FeedItem[]>([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState<boolean>(true);

  const imageRef = useRef<HTMLInputElement>(null);

  const dropdownRef = useRef<HTMLDivElement>(null);
  const calendarRef = useRef<HTMLDivElement>(null);

  const [genre, setGenre] = useState<string>("");
  const [product, setProduct] = useState<string>("");
  const [gender, setGender] = useState<string>("");
  const [ageGroup, setAgeGroup] = useState<string>("");
  const [region, setRegion] = useState<string>("");
  const [category, setCategory] = useState<string>("");

  const [brands, setBrands] = useState<string[]>([]);
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [searchString, setSearchString] = useState<string>("");
  const [color, setColor] = useState<string>("");

  const [genres, setGenres] = useState<string[]>([]);
  const [regions, setRegions] = useState<string[]>([]);
  const [sources, setSources] = useState<string[]>([]);
  const [categories, setCategories] = useState<string[]>([]);
  const [source, setSource] = useState<string>("");
  const [brandsList, setBrandsList] = useState<{
    [key: string]: brandsParams[];
  }>({});
  const [productList, setProductList] = useState<string[]>([]);

  const [productType, setProductType] = useState<string[]>([]);
  const [selectedProductType, setSelectedProductType] = useState<string>("");
  const [selectedMaterial, setSelectedMaterial] = useState<string[]>([]);
  const [stylingDetails, setStylingDetails] = useState<string[]>([]);
  const [selectedStylingDetail, setSelectedStylingDetail] = useState<string[]>(
    []
  );
  const user = useSelector((state: RootState) => state.user.userInfo);

  const userEmail = user?.email;
  const [userId, setUserId] = useState("");
  const [boardsList, setBoardsList] = useState<Board[]>([]);
  const [publicBoards, setPublicBoards] = useState<Board[]>([]);
  const [privateBoards, setPrivateBoards] = useState<Board[]>([]);

  const getUserByEmail = async () => {
    if (userEmail) {
      const res = await getUserId(userEmail);
      setUserId(res?.data?.data[0]._id);
    }
  };

  const getBoardsByUser = async () => {
    if (userId) {
      const res = await getBoardsByUserId(userId);
      setPublicBoards(res?.data?.data.public.boards);
      setPrivateBoards(res?.data?.data.private.boards);
      setBoardsList(res?.data?.data);
    }
  };

  useEffect(() => {
    getUserByEmail();
    getBoardsByUser();
  }, [userId]);

  const [image, setImage] = useState<string | null>(null);
  const [uploadImageUrl, setUploadImageUrl] = useState<string | null>(null);
  const [loadingImage, setLoadingImage] = useState(false);
  const [imagePreview, setImagePreview] = useState(false);
  const [filterId, setFilterId] = useState<string>("");
  const [filterName, setFilterName] = useState<string>("");
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const bucketName = process.env.REACT_APP_BUCKET_NAME;
  const loadingRef = useRef(false);
  const [isBrandsModalOpen, setBrandsModalOpen] = useState(false);
  const closeBrandsModal = () => setBrandsModalOpen(false);

  const controllerRef = useRef<null | AbortController>(null);

  // Trends Analysis
  const [analysisResultSuccess, setAnalysisResultSuccess] =
    useState<boolean>(false);
  const [trendsLoading, setTrendsLoading] = useState<boolean>(false);
  const [trends, setTrends] = useState<TrendInterface[]>([]);
  const [trendsPage, setTrendsPage] = useState(1);
  const trendsPageSize = 10;
  const trendsPagePerTrend = 50;
  const [trendsTotalPages, setTrendsTotalPages] = useState(1);
  const initialRenderRef = useRef(true);
  const reqIdRef = useRef<string>("");
  const [clear, setClear] = useState<boolean>(false);

  // Snapshot VAriables
  const [trendsSnapshotModal, setTrendsSnapshotModal] = useState(false);
  const [snapShotURL, setSnapShotURL] = useState<string>("");
  const snapshotRef = useRef<HTMLElement | null>(null);
  const [snapshotLoad, setSnapshotLoad] = useState<boolean>(false);
  const [reload, setReload] = useState<boolean>(false);
  const navigate = useNavigate();
  const [archivedTrendsVisible, setArchivedTrendsVisible] =
    useState<boolean>(false);
  const [lastReqId, setLastReqId] = useState<string | null>("");
  const [archiveTrendsArr, setArchiveTrendsArr] = useState<string[]>([]);
  const [lastArchiveTrends, setLastArchiveTrends] = useState<string[]>([]);
  const [snapshotInactive, setSnapshotInactive] = useState<boolean>(false);
  const [tooKsnapshot, setTookSnapshot] = useState<boolean>(false);
  const handleSidebarToggle = () => {
    setSidebarOpen(!sidebarOpen);
  };
  const handleTrendsToggle = () => {
    if (
      category &&
      gender &&
      (category === "Ethnic RTW" || category === "Western RTW")
    ) {
      setTrendsOpen(!trendsOpen);
    } else {
      toast.warn("Please select valid category and gender", {
        hideProgressBar: true,
      });
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setDropdownOpen(false);
    }
    if (
      calendarRef.current &&
      !calendarRef.current.contains(event.target as Node)
    ) {
      setCalendarOpen(false);
    }
  };

  const getProductsData = async () => {
    try {
      const response = await getProducts();
      setProductList(response);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    getDropdownData().then(res => {
      setGenres(res.genres.map((i: DropdownData) => i.name));
      setRegions(res.regions.map((i: DropdownData) => i.name));
      setSources(res.sources.map((i: DropdownData) => i.name));
      setCategories(res.categories.map((i: DropdownData) => i.name));
    });

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [isDeleteOpen, setIsDeleteOpen] = useState(false);

  const closeDeleteModal = () => setIsDeleteOpen(false);

  const handleCopyImageToClipboard = async (
    imageUrl: string,
    postId: string
  ) => {
    await copyImageToClipboard(
      imageUrl,
      `IG/${posts.find(post => post.postId.toString() === postId)?.handle}`
    );
  };

  const fetchPosts = async (page: number, query: string = "") => {
    if (scrollRef.current && page === 1) {
      scrollRef.current.scrollTop = 0;
    }

    if (controllerRef.current) {
      controllerRef.current.abort();
    }

    controllerRef.current = new AbortController();
    const { signal } = controllerRef.current;

    loadingRef.current = true;
    const pageSize = 50;
    const params: GetPostParams = {
      page,
      pageSize,
    };

    if (startDate && endDate) {
      params.startDate = `${startDate} 00:00:00`;
      params.endDate = `${endDate} 23:59:59`;
    }

    if (gender) params.gender = gender;
    if (ageGroup) params.ageGroup = ageGroup;
    if (genre) params.genre = genre;
    if (product) params.product = product;
    if (region) params.region = region;
    if (category) params.category = category;
    if (searchQuery) {
      params.searchQuery = searchQuery;
      if (uploadImageUrl) {
        params.s3Key = uploadImageUrl || "";
        params.bucketName = bucketName;
      }
    }
    if (imagePreview) {
      params.s3Key = uploadImageUrl || "";
      params.bucketName = bucketName;
    }
    if (brands.length > 0) params.brands = brands.join(",");
    if (source) params.source = source;
    if (color) params.colors = color.toUpperCase();

    try {
      const response = await getDesignerFocusPosts(params, { signal });
      setTotalPages(
        Math.ceil(response.data.totalCount / response.data.pageSize)
      );
      return response?.posts;
    } catch (error) {
      if (
        (error as Error).name !== "AbortError" &&
        (error as Error).name !== "CanceledError" &&
        (error as AxiosError).response?.status !== 400
      ) {
        toast.error("Error while fetching posts", {
          hideProgressBar: true,
        });
      }
      return [];
    } finally {
      if (!signal.aborted) {
        loadingRef.current = false;
        setLoading(false);
      }
    }
  };

  const loadMorePosts = async (page: number) => {
    if (analysisResultSuccess) return;
    const newPosts = await fetchPosts(page, searchQuery);

    if (newPosts?.length === 0 && page === 1) {
      setPosts([]);
    } else {
      setPosts(prevPosts =>
        page === 1 ? [...newPosts] : [...prevPosts, ...newPosts]
      );
    }
  };

  useEffect(() => {
    const initializePosts = async () => {
      setPosts([]);
      setLoading(true);
      setPage(1);
      const initialPosts = await fetchPosts(1, searchQuery);
      setPosts(initialPosts);
    };

    if (!analysisResultSuccess) {
      initializePosts();
    }

    return () => {
      if (controllerRef.current) {
        controllerRef.current.abort();
      }
    };
  }, [
    startDate,
    endDate,
    gender,
    ageGroup,
    genre,
    product,
    region,
    brands,
    source,
    color,
    category,
    searchQuery,
    imagePreview,
    analysisResultSuccess,
  ]);

  useEffect(() => {
    const params: GetBrandsParams = {};
    if (gender) params.gender = gender;
    if (ageGroup) params.ageGroup = ageGroup;
    if (genre) params.genre = genre;
    if (source) params.source = source;
    if (region) params.region = region;
    if (product) params.product = product;
    if (category) params.category = category;
    getBrandsData(params).then(res => {
      const brandsLocal = res || {};
      setBrandsList(brandsLocal);
    });
  }, [gender, genre, source, region, product, category, ageGroup]);

  useEffect(() => {
    if (brands.length !== 0 && !filterName) {
      setBrands([]);
    }
  }, [gender, ageGroup, genre, source, region, product]);

  useEffect(() => {
    getProductsData();
  }, []);

  const setCountFlagForArchivedTrends = async () => {
    try {
      setLoading(true);
      const params = {
        req_id: reqIdRef.current,
        posts_per_trend: 1,
        page: 1,
        pageSize: 50,
      };
      const response = await getArchivedTrend(params, user);
      if (
        Array.isArray(response?.data?.trends) &&
        response?.data?.trends?.length > 0
      ) {
        setArchiveTrendsArr(response?.data?.trends);
      }
      if (
        response?.data?.trends &&
        Array.isArray(response?.data?.trends) &&
        response?.data?.trends?.length > 0
      ) {
        setArchivedTrendsVisible(true);
      } else {
        setArchivedTrendsVisible(false);
      }
    } catch (error) {
      if ((error as AxiosError)?.response?.status === 400) {
        console.error("Error fetching Archive count", error);
      }
    }
  };

  const onScrollItems = throttle(() => {
    if (scrollRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;

      const isNearBottom =
        Math.abs(scrollHeight - clientHeight - scrollTop) < 2000;

      if (isNearBottom && page < totalPages && !loadingRef.current) {
        loadMorePosts(page + 1);
        setPage(page + 1);
      }
    }
  }, 1000);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchString(e.target.value);
    // setSearchQuery(e.target.value);
    // setPage(1);
  };

  const formatDate = (date: Date) => {
    const day = String(date.getUTCDate()).padStart(2, "0");
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const year = date.getUTCFullYear();

    return `${day}/${month}/${year}`;
  };

  const handleButtonClick = () => {
    imageRef.current?.click?.();
  };

  const handleImageUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      setLoadingImage(true);
      const fileName = getFileName(file);
      const key = getKeyForS3DesignerPage(fileName);
      const res = await uploadImage(key, file);
      setLoadingImage(false);
      setImage(`${process.env.REACT_APP_CLOUD_FRONT_URL}${res.key}`);
      setUploadImageUrl(res.key);
    }
  };

  const handleRemove = () => {
    setIsDeleteOpen(true);
  };

  const base64ToFile = (base64: string, filename: string): File => {
    const byteString = atob(base64.split(",")[1]);

    const mimeTypeMatch = base64.match(/:(.*?);/);
    const mimeType = mimeTypeMatch?.[1] || "image/png";

    const byteNumbers = new Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      byteNumbers[i] = byteString.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: mimeType });

    return new File([blob], filename, { type: mimeType });
  };

  const handlePaste = async (event: React.ClipboardEvent<HTMLInputElement>) => {
    const hasImage = Array.from(event.clipboardData.items).some(item =>
      item.type.includes("image")
    );

    if (!hasImage) return;
    setLoadingImage(true);

    const items = Array.from(event.clipboardData.items);

    for (const item of items) {
      if (item?.type?.includes("image")) {
        const file = item.getAsFile();

        if (file) {
          const reader = new FileReader();

          try {
            const base64String = await new Promise<string>(
              (resolve, reject) => {
                reader.onload = event => {
                  if (event.target && event.target.result) {
                    resolve(event.target.result as string);
                  } else {
                    reject("Failed to read the file");
                  }
                };
                reader.onerror = () => reject("Error reading the file");
                reader.readAsDataURL(file);
              }
            );

            const imageFile = base64ToFile(base64String, "pasted-image.png");

            if (imageFile) {
              const fileName = getFileName(file);
              const key = getKeyForS3DesignerPage(fileName);

              const res = await uploadImage(key, imageFile);
              setImage(`${process.env.REACT_APP_CLOUD_FRONT_URL}${res.key}`);
              setUploadImageUrl(res.key);
            }
          } catch (error) {
            console.error("Image upload failed:", error);
          }

          setLoadingImage(false);
          break;
        }
      }
    }
  };

  useEffect(() => {
    const fetchProductTypes = async () => {
      try {
        const response = await getProductTypes({ category, gender }, user);
        setSelectedProductType("");
        setSelectedMaterial([]);
        setSelectedStylingDetail([]);
        setProductType(response.data);
      } catch (error) {
        console.error("Error Fetching Product Type", error);
        if ((error as AxiosError)?.response?.status !== 400) {
          toast.error("Error Fetching Product Type", {
            hideProgressBar: true,
          });
        }
      }
    };
    if (
      category &&
      gender &&
      (category === "Ethnic RTW" || category === "Western RTW")
    ) {
      fetchProductTypes();
    } else {
      setProductType([]);
      setTrendsOpen(false);
    }
  }, [category, gender]);

  useEffect(() => {
    setSelectedStylingDetail([]);
    setSelectedMaterial([]);
    const getStylingDetail = async () => {
      try {
        const response = await getStylingDetails(
          {
            category,
            gender,
            productType: selectedProductType,
          },
          user
        );
        setStylingDetails(response.data);
      } catch (error) {
        console.error("Error Fetching Styling Details", error);
        if ((error as AxiosError)?.response?.status !== 400) {
          toast.error("Error Fetching Styling Details", {
            hideProgressBar: true,
          });
        }
      }
    };

    if (
      category &&
      gender &&
      selectedProductType &&
      (category === "Ethnic RTW" || category === "Western RTW")
    ) {
      getStylingDetail();
    }
  }, [selectedProductType]);

  const handleDesignerImageError = (postId: string, image_link: string) => {
    if (image_link === "N/A") {
      setPosts(prev => prev.filter(post => post.postId !== postId));
    }
  };

  useEffect(() => {
    if (analysisResultSuccess && reload) {
      if (trends.length === 1 && trendsPage !== 1) {
        setTrendsPage(prev => prev - 1);
      } else {
        runAnalysis(trendsPage);
      }
      setReload(false);
    }
  }, [reload]);

  const snapshotDisableBtnCheck = (): boolean => {
    if (lastReqId !== reqIdRef.current) return false;

    return areArraysEqual(lastArchiveTrends, archiveTrendsArr);
  };

  useEffect(() => {
    setSnapshotInactive(snapshotDisableBtnCheck());
    setTookSnapshot(false);
  }, [
    tooKsnapshot,
    archiveTrendsArr,
    lastArchiveTrends,
    analysisResultSuccess,
  ]);

  useEffect(() => {
    const sessionStorageData = sessionStorage.getItem("trends");
    const lastReqId = sessionStorage.getItem("last_req_id");
    setLastReqId(lastReqId);
    const lastArchiveTrends = sessionStorage.getItem("last_Archive_Arr");
    setLastArchiveTrends(JSON.parse(lastArchiveTrends || "[]"));
    const trendFilters: GetTrendsParams = JSON.parse(
      sessionStorageData || "{}"
    );
    if (Object.entries(trendFilters).length !== 0) {
      if (trendFilters?.req_id) {
        reqIdRef.current = trendFilters.req_id;
      }
      if (trendFilters?.start_date) {
        setStartDate(
          new Date(trendFilters?.start_date).toISOString().split("T")[0]
        );
      }
      if (trendFilters?.end_date) {
        setEndDate(
          new Date(trendFilters?.end_date).toISOString().split("T")[0]
        );
      }
      if (trendFilters?.genre) {
        setGenre(trendFilters.genre);
      }
      if (trendFilters?.category) {
        setCategory(trendFilters.category);
      }
      if (trendFilters?.gender) {
        setGender(trendFilters.gender);
      }
      if (trendFilters?.region) {
        setRegion(trendFilters.region);
      }
      if (trendFilters?.product) {
        setProduct(trendFilters.product);
      }
      if (trendFilters?.age_group) {
        setAgeGroup(trendFilters.age_group);
      }
      setTrendsOpen(true);
      setTimeout(() => {
        if (trendFilters?.apparel_type) {
          setSelectedProductType(
            trendFilters.apparel_type === "Blazers"
              ? "Blazers & Suits"
              : trendFilters.apparel_type
          );
        }
      }, 3000);
      setTimeout(() => {
        const materialsFromSession = Object.keys(materialsForBackend)
          .filter(key => {
            return trendFilters?.[key as keyof typeof materialsForBackend];
          })
          .map(
            key =>
              materialsForBackend[key as keyof typeof materialsForBackend].label
          );
        setSelectedMaterial(materialsFromSession);
        const stylingDetailsFromSession = Object.keys(stylingDetailsForBackend)
          .filter(key => {
            return trendFilters?.[key as keyof typeof stylingDetailsForBackend];
          })
          .map(
            key =>
              stylingDetailsForBackend[
                key as keyof typeof stylingDetailsForBackend
              ].label
          );
        setSelectedStylingDetail(stylingDetailsFromSession);
        if (trendFilters?.brands) {
          setBrands(trendFilters?.brands.split(","));
        }
      }, 5000);
      runAnalysis(1, trendFilters);
    }
  }, []);

  const runAnalysis = async (page: number, trendFilters?: GetTrendsParams) => {
    //eslint-disable-next-line
    let response: any;

    setTrendsPage(page);

    try {
      setTrendsLoading(true);
      setAnalysisResultSuccess(true);
      if (trendFilters) {
        response = await getTrends(trendFilters, user);
      } else {
        const paramsMaterials = Object.fromEntries(
          Object.entries(materialsForBackend)
            .filter(([_, material]) =>
              selectedMaterial.includes(material.label)
            )
            .map(([key, _]) => {
              return [key, true];
            })
        );

        const paramsStylingDetails = Object.fromEntries(
          Object.entries(stylingDetailsForBackend)
            .filter(([_, stylingDetail]) =>
              selectedStylingDetail.includes(stylingDetail.label)
            )
            .map(([key, _]) => {
              return [key, true];
            })
        );
        const oldReqId = reqIdRef.current;
        reqIdRef.current = reqIdRef.current || uuidv4();
        const newReqId = reqIdRef.current;

        const params: GetTrendsParams = {
          ...paramsMaterials,
          ...paramsStylingDetails,
          ...(selectedProductType
            ? {
                apparel_type:
                  selectedProductType === "Blazers & Suits"
                    ? "Blazers"
                    : selectedProductType,
              }
            : {}),
          page: page === 0 ? 1 : page,
          ...(ageGroup ? { age_group: ageGroup } : {}),
          pageSize: trendsPageSize,
          posts_per_trend: trendsPagePerTrend,
          ...(category ? { category: category } : {}),
          gender: gender,
          genre: genre,
          ...(color ? { colors: color } : {}),
          ...(brands.length > 0 ? { brands: brands.join(",") } : {}),
          ...(region ? { region: region } : {}),
          ...(source ? { source: source } : {}),
          ...(product ? { product: product } : {}),
          req_id: newReqId,
        };
        if (startDate && endDate) {
          params.start_date = `${startDate} 00:00:00`;
          params.end_date = `${endDate} 23:59:59`;
        }
        response = await getTrends(params, user);
        if (oldReqId !== newReqId) {
          sessionStorage.setItem("trends", JSON.stringify(params));
          setSnapshotInactive(snapshotDisableBtnCheck());
          setTookSnapshot(false);
        }
      }
      setTrendsTotalPages(
        Math.ceil(response?.data?.trendCount / trendsPageSize)
      );
      setTrends(response?.data?.trends);
      const scroller = document.querySelector(".trends-scroller");
      if (scroller) {
        scroller.scrollTop = 0;
      }
    } catch (error) {
      if ((error as AxiosError)?.response?.status !== 400) {
        console.error(error);
        toast.error("Error Fetching Trends", {
          hideProgressBar: true,
        });
      }
    } finally {
      setTrendsLoading(false);
      setCountFlagForArchivedTrends();
    }
  };

  const handleTakeSnapshot = async (
    event: React.MouseEvent<HTMLButtonElement>
  ): Promise<void> => {
    const container = document.querySelector<HTMLElement>(".target-container");
    if (!container) {
      console.error("Container not found");
      return;
    }

    // Create a click effect
    const clickEffect = document.createElement("div");
    Object.assign(clickEffect.style, {
      position: "absolute",
      top: `${event.clientY}px`,
      left: `${event.clientX}px`,
      width: "30px", // Increased size
      height: "30px",
      background:
        "radial-gradient(circle, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0) 70%)", // Gradient effect
      borderRadius: "50%",
      transform: "translate(-50%, -50%) scale(0)",
      transition: "transform 0.7s ease-out, opacity 0.7s ease-out", // Longer duration
      pointerEvents: "none",
      zIndex: "9999",
    });

    document.body.appendChild(clickEffect);

    // Trigger the animation
    setTimeout(() => {
      clickEffect.style.transform = "translate(-50%, -50%) scale(8)"; // Larger scale
      clickEffect.style.opacity = "0";
    }, 0);

    // Remove the effect after animation
    setTimeout(() => {
      document.body.removeChild(clickEffect);
    }, 1000);
    setSnapshotLoad(true);
    try {
      const dataUrl = await htmlToImage.toPng(container);
      setSnapShotURL(dataUrl);

      const tempSnapshot = document.createElement("img");
      tempSnapshot.src = dataUrl;

      Object.assign(tempSnapshot.style, {
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "60vw",
        height: "75vh",
        objectFit: "cover",
        border: "5px solid #4d4d4d",
        boxShadow: "0px 4px 10px #4d4d4d",
        backgroundColor: "white",
      });

      document.body.appendChild(tempSnapshot);

      setTimeout(() => {
        setTrendsSnapshotModal(true);
        document.body.removeChild(tempSnapshot);
      }, 1500);
    } catch (error) {
      toast.error("Error capturing snapshot", {
        hideProgressBar: true,
      });
      console.error("Error capturing snapshot:", error);
    } finally {
      setSnapshotLoad(false);
    }
  };

  return (
    <>
      <div
        className={`flex flex-col h-[calc(100vh-80px)] ${!isSidebarOpen ? "w-[calc(100vw-265px)]" : "w-[calc(100vw-105px)]"} transition-all duration-500 ease-in-out fixed ml-1  ${
          isBrandsModalOpen ? "z-10" : ""
        } target-container  align-middle whitespace-nowrap`}
      >
        <div className="flex flex-1 overflow-hidden">
          <Preferences
            startDate={startDate}
            endDate={endDate}
            isCollapsed={sidebarOpen}
            onToggle={handleSidebarToggle}
            setGenre={setGenre}
            genre={genre}
            setProduct={setProduct}
            product={product}
            setGender={setGender}
            gender={gender}
            ageGroup={ageGroup}
            setAgeGroup={setAgeGroup}
            setBrands={setBrands}
            brands={brands}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            setRegion={setRegion}
            region={region}
            genres={genres}
            regions={regions}
            sources={sources}
            source={source}
            setSource={setSource}
            brandsList={brandsList}
            productList={productList}
            color={color}
            setColor={setColor}
            categories={categories}
            category={category}
            setCategory={setCategory}
            isBrandsModalOpen={isBrandsModalOpen}
            setBrandsModalOpen={setBrandsModalOpen}
            closeBrandsModal={closeBrandsModal}
            filterId={filterId}
            filterName={filterName}
            setFilterId={setFilterId}
            setFilterName={setFilterName}
            TrendSnapshotDisabled={false}
            TrendsSnapshotPreferencesView={false}
            setClear={setClear}
          />
          <div
            className={`${analysisResultSuccess ? "px-4" : ""} flex-1 bg-lightGray`}
          >
            <div className="flex flex-col md:flex-row justify-around items-center mb-2">
              {!analysisResultSuccess && (
                <div className="flex bg-white w-full items-center border border-black text-center justify-center rounded-lg p-1">
                  <input
                    type="text"
                    placeholder="Search"
                    className="w-full pl-3 text-md outline-none"
                    value={searchString}
                    onChange={handleSearchChange}
                    onPaste={handlePaste}
                  />
                  {!image ? (
                    <>
                      {loadingImage ? (
                        <>
                          <div className="flex justify-center items-center h-full">
                            <Oval
                              height={10}
                              width={10}
                              color="black"
                              visible={true}
                              ariaLabel="oval-loading"
                              secondaryColor="black"
                              strokeWidth={2}
                              strokeWidthSecondary={2}
                            />
                          </div>
                        </>
                      ) : (
                        <div
                          onClick={handleButtonClick}
                          className="cursor-pointer"
                        >
                          <CameraIcon />
                        </div>
                      )}
                    </>
                  ) : (
                    <div className="relative">
                      <div
                        className="absolute top-[-3px] right-[-3px] bg-white w-[10px] h-[10px] rounded-xl cursor-pointer flex justify-center items-center"
                        onClick={handleRemove}
                      >
                        <CrossIcon width="6px" height="6px" />
                      </div>
                      <div>
                        <img
                          src={image || ""}
                          alt="Uploaded Preview"
                          className="object-cover w-[40px] h-[25px] rounded"
                        />
                      </div>
                    </div>
                  )}
                  <input
                    ref={imageRef}
                    hidden
                    multiple
                    id="accept-photo"
                    accept=".jpg, .jpeg, .png , .gif, .svg"
                    type="file"
                    onChange={handleImageUpload}
                  />
                  <Search
                    className="w-6 h-6 cursor-pointer ml-3 mr-1 "
                    onClick={() => {
                      setSearchQuery(searchString);
                      if (uploadImageUrl && image) {
                        setImagePreview(true);
                      } else {
                        setImagePreview(false);
                      }
                    }}
                  />
                </div>
              )}
            </div>
            {analysisResultSuccess && (
              <div className="w-full h-10 bg-white mb-2 rounded-lg flex items-center">
                <button
                  className="px-3 font-bold text-xs disabled:text-gray-400"
                  // disabled={true}
                  disabled={!analysisResultSuccess || !archivedTrendsVisible}
                  onClick={() => navigate("/designer-page/archive")}
                >
                  Check Archive
                </button>
                |
                <button
                  className={`px-3 font-bold text-xs  disabled:text-gray-400 ${
                    snapshotLoad ? "text-gray-400 " : ""
                  }`}
                  // disabled={true}
                  disabled={
                    snapshotLoad || trends.length === 0 || snapshotInactive
                  }
                  onClick={handleTakeSnapshot}
                >
                  {snapshotLoad ? "Taking Snapshot..." : "Take Snapshot"}
                </button>
              </div>
            )}
            <div
              className={` h-[calc(100vh-146px)]  overflow-auto bg-white p-2.5 rounded-lg`}
              ref={scrollRef}
              onScroll={onScrollItems}
            >
              {((!analysisResultSuccess && loading) ||
                (analysisResultSuccess && trendsLoading)) && (
                <div className="flex flex-wrap justify-around space-x-4">
                  {Array.from({ length: 12 }).map((_, index) => (
                    <div
                      key={index}
                      className="w-[240px] mt-4 p-0 pb-3 gap-2 border border-light rounded-2xl relative cursor-pointer"
                    >
                      <div className="relative w-full h-auto">
                        <Skeleton
                          height={200}
                          width={240}
                          className="rounded-t-2xl"
                        />
                        <div className="absolute top-0 right-0 bg-gray-300 text-white bg-gray81/50 rounded-bl-lg rounded-tr-2xl px-3 py-1 text-xs">
                          <Skeleton width={50} />
                        </div>
                        <div className="absolute bottom-2 right-2 flex space-x-1">
                          <div className="bg-white flex w-5 h-5 items-center justify-center rounded-full">
                            <Skeleton circle={true} height={20} width={20} />
                          </div>
                          <div className="bg-white flex w-5 h-5 items-center justify-center rounded-full">
                            <Skeleton circle={true} height={20} width={20} />
                          </div>
                          <div className="bg-white flex w-5 h-5 items-center justify-center rounded-full">
                            <Skeleton circle={true} height={20} width={20} />
                          </div>
                          <div className="bg-white flex w-5 h-5 items-center justify-center rounded-full">
                            <Skeleton circle={true} height={20} width={20} />
                          </div>
                        </div>
                      </div>
                      <div className="p-2 space-y-0.5">
                        <Skeleton width={100} />
                        <Skeleton width={150} />
                        <Skeleton width={120} />
                      </div>
                    </div>
                  ))}
                </div>
              )}
              {analysisResultSuccess ? (
                <>
                  {trends.length > 0 ? (
                    <>
                      <div
                        className={`trends-scroller overflow-y-scroll scroller ${trendsTotalPages > 1 ? "h-[calc(100%-45px)]" : "h-full"}`}
                      >
                        {trends.map((item, index) => {
                          return (
                            <Trends
                              key={index}
                              isSidebarOpen={isSidebarOpen}
                              sidebarOpen={sidebarOpen}
                              name={item.name}
                              selection={item.trendCategory}
                              trendsOpen={trendsOpen}
                              posts={item.posts}
                              privateBoards={privateBoards}
                              setPrivateBoards={setPrivateBoards}
                              publicBoards={publicBoards}
                              setPublicBoards={setPublicBoards}
                              userId={userId}
                              req_id={reqIdRef.current}
                              trendSnapshotTrendsView={false}
                              setReload={setReload}
                            />
                          );
                        })}
                      </div>
                      <div
                        className={`mt-1 ${trendsTotalPages > 1 ? "block" : "hidden"}`}
                      >
                        <Pagination
                          currentPage={trendsPage}
                          totalPages={trendsTotalPages}
                          onPageChange={runAnalysis}
                        />
                      </div>
                    </>
                  ) : (
                    <div className="font-poppins text-center mt-10 w-full">
                      No Trends Found
                    </div>
                  )}
                </>
              ) : posts?.length === 0 && loading === false ? (
                <div className="text-center text-gray-500">No data found</div>
              ) : (
                <div className="flex flex-wrap justify-evenly gap-4">
                  {posts.map(item => {
                    return (
                      <InstaPost
                        key={item.postId}
                        item={item}
                        trendsOpen={trendsOpen}
                        privateBoards={privateBoards}
                        setPrivateBoards={setPrivateBoards}
                        publicBoards={publicBoards}
                        setPublicBoards={setPublicBoards}
                        userId={userId}
                      />
                    );
                  })}
                </div>
              )}
            </div>
          </div>
          <TrendsSidebar
            isTrendOpen={trendsOpen}
            onTrendToggle={handleTrendsToggle}
            productType={productType}
            selectedProductType={selectedProductType}
            setSelectedProductType={setSelectedProductType}
            materials={defaultMaterials}
            selectedMaterial={selectedMaterial}
            setSelectedMaterial={setSelectedMaterial}
            stylingDetails={stylingDetails}
            selectedStylingDetail={selectedStylingDetail}
            setSelectedStylingDetail={setSelectedStylingDetail}
            runAnalysis={runAnalysis}
            setAnalysisResultSuccess={setAnalysisResultSuccess}
            refChange={(value: string) => (reqIdRef.current = value)}
            startDate={startDate}
            endDate={endDate}
            region={region}
            genre={genre}
            product={product}
            gender={gender}
            brands={brands}
            source={source}
            category={category}
            color={color}
            req_id={reqIdRef.current}
            TrendsSidebarSnapshotExpandedView={false}
            ageGroup={ageGroup}
            analysisResultSuccess={analysisResultSuccess}
            clear={clear}
            setClear={setClear}
          />
        </div>
        {trendsSnapshotModal && (
          <TrendsSnapshotModal
            setTrendsSnapshotModal={setTrendsSnapshotModal}
            snapShotURL={snapShotURL}
            selectedMaterial={selectedMaterial}
            selectedProductType={selectedProductType}
            selectedStylingDetail={selectedStylingDetail}
            startDate={startDate}
            endDate={endDate}
            region={region}
            genre={genre}
            product={product}
            gender={gender}
            brands={brands}
            source={source}
            category={category}
            color={color}
            req_id={reqIdRef.current}
            trends={trends}
            ageGroup={ageGroup}
            setLastReqId={setLastReqId}
            setLastArchiveArr={setLastArchiveTrends}
            archiveTrendsArr={archiveTrendsArr}
            setTookSnapshot={setTookSnapshot}
          />
        )}
      </div>

      <CustomizedModal
        isOpen={isDeleteOpen}
        onClose={closeDeleteModal}
        title="Are you sure ?"
        width="400px"
      >
        <div className="flex justify-center space-x-6 mt-4">
          <button
            className="px-4 py-2 bg-gray-300 text-tertiary font-bold border rounded-full"
            onClick={closeDeleteModal}
          >
            Cancel
          </button>
          <button
            className="px-4 py-2 bg-black text-white font-bold rounded-full"
            onClick={() => {
              setImage("");
              setUploadImageUrl("");
              closeDeleteModal();
              setImagePreview(false);
            }}
          >
            Confirm
          </button>
        </div>
      </CustomizedModal>
    </>
  );
};

export default DesignerPage;
