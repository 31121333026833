import React, { useState, useEffect } from "react";

type NewsImageProps = {
  source: string;
  url: string;
  classProps: string;
};

const NewsImage: React.FC<NewsImageProps> = ({ source, url, classProps }) => {
  const [imageSrc, setImageSrc] = useState<string>(
    "https://www.bing.com/th?id=OVFT.94fhU2WuyPqa4wDZIRambS&pid=News"
  );

  const getFallbackImage = async (
    source: string,
    url: string
  ): Promise<string> => {
    //Check if favicon available or not
    const fetchFavicon = (): Promise<boolean> => {
      return new Promise(resolve => {
        const img = new Image();
        img.src = `${new URL(url).origin}/favicon.ico`;
        img.onload = () => resolve(true);
        img.onerror = () => resolve(false);
      });
    };
    const isFaviconAvailable = await fetchFavicon();
    if (isFaviconAvailable) {
      return `${new URL(url).origin}/favicon.ico`;
    }

    //If favicon not available
    const firstWord = source.split(" ")[0];
    const canvas = document.createElement("canvas");
    canvas.width = 70;
    canvas.height = 70;
    const context = canvas.getContext("2d");
    if (context) {
      context.fillStyle = "#000000";
      context.fillRect(0, 0, canvas.width, canvas.height);
      context.fillStyle = "#FFFFFF";
      context.font = "bold 20px Arial";
      context.textAlign = "center";
      context.textBaseline = "middle";
      context.fillText(firstWord, canvas.width / 2, canvas.height / 2);
      const imageUrl = canvas.toDataURL();
      return imageUrl;
    }

    return "https://www.bing.com/th?id=OVFT.94fhU2WuyPqa4wDZIRambS&pid=News";
  };

  useEffect(() => {
    const fetchImage = async () => {
      const fallbackImage = await getFallbackImage(source, url);
      setImageSrc(fallbackImage);
    };

    fetchImage();
  }, [source, url]);

  return <img alt="Loading..." className={classProps} src={imageSrc} />;
};

export default NewsImage;
