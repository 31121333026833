import React, { useState } from "react";
import ReactDOM from "react-dom";
import SaveFilterModal from "./SaveFilterModal";
import { updateFilter } from "../../app/api/savedFilterApis";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { toast } from "react-toastify";
import moment from "moment";
import CrossIcon from "../../assets/icons/CrossIconModal";
interface Props {
  onCloseEditFilter: () => void;
  isSaveFilterModalOpen: boolean;
  setSaveFilterModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onCloseSavedFilter: () => void;
  onCloseViewFilter: () => void;
  filterType: string;
  setFilterType: React.Dispatch<React.SetStateAction<string>>;
  region: string;
  genre: string;
  product: string;
  gender: string;
  brands: string[];
  startDate: string;
  endDate: string;
  source: string;
  category: string;
  color: string;
  ageGroup: string;
  id: string;
  setGenre: React.Dispatch<React.SetStateAction<string>>;
  setProduct: React.Dispatch<React.SetStateAction<string>>;
  setGender: React.Dispatch<React.SetStateAction<string>>;
  setRegion: React.Dispatch<React.SetStateAction<string>>;
  setBrands: React.Dispatch<React.SetStateAction<string[]>>;
  setStartDate: React.Dispatch<React.SetStateAction<string>>;
  setEndDate: React.Dispatch<React.SetStateAction<string>>;
  setSource: React.Dispatch<React.SetStateAction<string>>;
  setCategory: React.Dispatch<React.SetStateAction<string>>;
  setColor: React.Dispatch<React.SetStateAction<string>>;
  setAgeGroup: React.Dispatch<React.SetStateAction<string>>;
  handleClearAll: () => void;
  filterId: string;
  filterName: string;
  setFilterId: React.Dispatch<React.SetStateAction<string>>;
  setFilterName: React.Dispatch<React.SetStateAction<string>>;
  setFilterSaved: React.Dispatch<React.SetStateAction<boolean>>;
  setDisableAfterSave: React.Dispatch<React.SetStateAction<boolean>>;
}
const EditFilterModal: React.FC<Props> = ({
  onCloseEditFilter,
  isSaveFilterModalOpen,
  setSaveFilterModalOpen,
  onCloseSavedFilter,
  onCloseViewFilter,
  filterType,
  setFilterType,
  region,
  genre,
  product,
  gender,
  brands,
  startDate,
  endDate,
  source,
  category,
  color,
  ageGroup,
  id,
  setGenre,
  setProduct,
  setGender,
  setRegion,
  setBrands,
  setStartDate,
  setEndDate,
  setSource,
  setCategory,
  setColor,
  setAgeGroup,
  handleClearAll,
  filterId,
  filterName,
  setFilterId,
  setFilterName,
  setFilterSaved,
  setDisableAfterSave,
}) => {
  const user = useSelector((state: RootState) => state.user.userInfo);
  const userId = user?.userId;
  const [selectedOption, setSelectedOption] = useState("edit");

  const closeModal = () => {
    onCloseEditFilter();
  };

  const handleSave = async () => {
    const payload = {
      Genre: genre,
      Product: product,
      Gender: gender,
      Region: region,
      Brand: brands,
      StartDate: startDate,
      EndDate: endDate,
      Source: source,
      Color: color,
      Category: category,
      AgeGroup: ageGroup,
      FilterName: filterName,
    };

    if (selectedOption === "edit") {
      try {
        await updateFilter(filterId, payload, user, userId);
        toast.success("Filter updated successfully", {
          hideProgressBar: false,
        });
        setFilterId("");
        setFilterName("");
        setDisableAfterSave(true);
      } catch (error) {
        console.error(
          `Error while ${
            selectedOption === "edit" ? "updating" : "saving"
          } filter:`,
          error
        );
        toast.error(
          `Error while ${
            selectedOption === "edit" ? "updating" : "saving"
          } filter, please try again`,
          {
            hideProgressBar: false,
          }
        );
      }
    } else if (selectedOption === "saveAsNew") {
      setFilterType("edit");
      setSaveFilterModalOpen(true);
    }

    onCloseEditFilter();
  };
  return ReactDOM.createPortal(
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div className="fixed inset-0 bg-black opacity-50"></div>

      <div
        className="relative bg-white rounded-lg shadow-sm z-60 font-poppins"
        style={{ width: "393px", height: "176px", overflow: "hidden" }}
      >
        {/* Top Border */}
        <div className="border-t border-disabled w-full absolute "></div>

        <div className="flex flex-col ">
          {/* Option 1: Edit Current Filter */}
          <div className="flex justify-between p-4">
            <h1 className="text-[20px] font-bold font-poppins">Save Filter</h1>
            <span className="cursor-pointer" onClick={closeModal}>
              <CrossIcon width={"20px"} height={"20px"} />
            </span>
          </div>

          <div className="flex gap-x-6 px-4 py-2">
            <label className="flex items-center accent-black gap-2 mb-2 cursor-pointer">
              <input
                type="radio"
                name="filterOption"
                value="edit"
                checked={selectedOption === "edit"}
                onChange={() => setSelectedOption("edit")}
              />
              <span className="font-poppins text-sm">Edit Current Filter</span>
            </label>

            {/* Option 2: Save As New Filter */}
            <label className="flex items-center accent-black gap-2 mb-2 cursor-pointer">
              <input
                type="radio"
                name="filterOption"
                value="saveAsNew"
                checked={selectedOption === "saveAsNew"}
                onChange={() => setSelectedOption("saveAsNew")}
              />
              <span className="font-poppins text-sm">Save as New Filter</span>
            </label>
          </div>
          <div className="border-t border-disabled w-full"></div>
          {/* Save Button */}
          <div className="flex justify-center">
            <div
              className="mt-4 font-poppins w-[140px] h-[46px]  text-sm p-2 bg-primary text-white rounded-full cursor-pointer flex items-center justify-center"
              onClick={handleSave}
            >
              {selectedOption === "saveAsNew" ? "Continue" : "Save Changes"}
            </div>
          </div>
        </div>
      </div>
      {isSaveFilterModalOpen && (
        <SaveFilterModal
          onCloseSavedFilter={onCloseSavedFilter}
          onCloseViewFilter={onCloseViewFilter}
          filterType={filterType}
          setFilterType={setFilterType}
          id=""
          region={region}
          genre={genre}
          product={product}
          gender={gender}
          brands={brands}
          startDate={startDate}
          endDate={endDate}
          source={source}
          category={category}
          color={color}
          ageGroup={ageGroup}
          setGenre={setGenre}
          setProduct={setProduct}
          setBrands={setBrands}
          setGender={setGender}
          setRegion={setRegion}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          setSource={setSource}
          setCategory={setCategory}
          setColor={setColor}
          setAgeGroup={setAgeGroup}
          handleClearAll={handleClearAll}
          filterId={filterId}
          filterName={filterName}
          setFilterId={setFilterId}
          setFilterName={setFilterName}
          setFilterSaved={setFilterSaved}
          setDisableAfterSave={setDisableAfterSave}
        />
      )}
    </div>,
    document.body
  );
};

export default EditFilterModal;
