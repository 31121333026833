import React, { memo, useEffect, useState } from "react";
import SelectionChips from "../Trends/SelectionChips";
import ProductTypeDropdown from "./ProductTypeDropdown";

type TrendsInputProps = {
  inputOpen: boolean;
  productType: string[];
  selectedProductType: string;
  setSelectedProductType: React.Dispatch<React.SetStateAction<string>>;
  materials: string[];
  selectedMaterial: string[];
  setSelectedMaterial: React.Dispatch<React.SetStateAction<string[]>>;
  stylingDetails: string[];
  selectedStylingDetail: string[];
  setSelectedStylingDetail: React.Dispatch<React.SetStateAction<string[]>>;
};

const TrendsInput: React.FC<TrendsInputProps> = ({
  inputOpen,
  productType,
  selectedProductType,
  setSelectedProductType,
  materials,
  selectedMaterial,
  setSelectedMaterial,
  stylingDetails,
  selectedStylingDetail,
  setSelectedStylingDetail,
}) => {
  const toggleMaterial = (material: string) => {
    if (selectedMaterial.includes(material)) {
      setSelectedMaterial(selectedMaterial.filter(item => item !== material));
    } else if (selectedProductType !== "All") {
      setSelectedStylingDetail([]);
      setSelectedMaterial([material]);
    } else if (selectedMaterial.length + selectedStylingDetail.length < 2) {
      setSelectedMaterial([...selectedMaterial, material]);
    }
  };

  const toggleStyling = (styling: string) => {
    if (selectedStylingDetail.includes(styling)) {
      setSelectedStylingDetail(
        selectedStylingDetail.filter(item => item !== styling)
      );
    } else if (selectedProductType !== "All") {
      setSelectedMaterial([]);
      setSelectedStylingDetail([styling]);
    } else if (selectedMaterial.length + selectedStylingDetail.length < 2) {
      setSelectedStylingDetail([...selectedStylingDetail, styling]);
    }
  };

  return (
    <div
      className={`flex flex-col space-y-2 px-1 ${inputOpen ? "" : "hidden"}`}
    >
      <div className="text-xs text-[#4D4D4D]">Product Type</div>
      <ProductTypeDropdown
        options={productType}
        value={selectedProductType || "Select product type"}
        onSelect={option => {
          setSelectedProductType(option);
        }}
      />
      {selectedProductType && (
        <>
          <div className="text-xs mb-2 text-[#4D4D4D]">Material</div>
          <div className="flex flex-wrap">
            {materials.map((material, index) => (
              <SelectionChips
                value={material}
                key={index}
                selected={selectedMaterial.includes(material)}
                onclick={toggleMaterial}
              />
            ))}
          </div>
          {stylingDetails.length > 0 && (
            <div className="text-xs mb-2 text-[#4D4D4D]">Styling Details</div>
          )}
          <div className="flex flex-wrap">
            {stylingDetails.map((styling, index) => (
              <SelectionChips
                value={styling}
                key={index}
                selected={selectedStylingDetail.includes(styling)}
                onclick={toggleStyling}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default TrendsInput;
