import React, { useEffect, useState, useRef, useCallback } from "react";
import { ReactComponent as Search } from "../assets/icons/search.svg";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { getImageLink } from "../utils";
import { ReactComponent as ExpandSnapshot } from "../assets/icons/snapshotExpandedView.svg";
import { useSelector } from "react-redux";
import { RootState } from "../app/store";
import { getUserId } from "../app/api/user/user";
import { getListSnapshots } from "../app/api/trendSnapshot";
import SnapshotCard from "../components/DesignerPage/TrendsSnapshot/SnapshotCard";
import SnapshotCardSkeleton from "../components/DesignerPage/TrendsSnapshot/SnapshotCardSkeleton";
import { throttle } from "lodash";
import { toast } from "react-toastify";
import { useDebounce } from "../app/hooks/targetHook";

interface Props {
  isSidebarOpen: boolean;
}

interface SnapshotList {
  id: number;
  name: string;
  ownerId: string;
  owner_name: string;
  generic_filters: {
    preferenceFilters: {
      gender?: string;
      source?: string;
      startDate?: string;
      endDate?: string;
      region?: string;
      genre?: string;
      product?: string;
      brands?: string[];
      category?: string;
      color?: string;
      ageGroup?: string;
    };
    trendAnalysisFilters: {
      material: string;
      product_type: string;
      styling_details: string;
    };
  };
  images: string[];
  trendsCount: number;
  sharedCount: number;
  created_at: string;
  updatedAt: string;
}

const TrendSnapshot: React.FC<Props> = ({ isSidebarOpen }) => {
  const navigate = useNavigate();
  const user = useSelector((state: RootState) => state.user.userInfo);
  const userEmail = user?.email;
  const [userId, setUserId] = useState("");
  const [snapshotArr, setSnapshotArr] = useState<SnapshotList[]>([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(50);
  const [searchQuery, setSearchQuery] = useState("");
  const debouncedSearchQuery = useDebounce(searchQuery, 500);
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const loadingRef = useRef(false);

  const handleNavigate = (id: number) => {
    navigate(`/trend-snapshot/details/${id}`);
  };

  const getUserByEmail = async () => {
    if (userEmail) {
      try {
        const res = await getUserId(userEmail);
        const fetchedUserId = res.data.data[0]?._id;
        if (fetchedUserId) {
          setUserId(fetchedUserId);
        } else {
          console.error("User ID not found.");
        }
      } catch (error) {
        console.error("Error fetching user ID:", error);
      }
    }
  };

  const loadMorePosts = async (currentPage: number) => {
    if (!userId || loadingRef.current) return;

    try {
      loadingRef.current = true;
      const listSnapshotParams = {
        userId,
        page: currentPage,
        pageSize: totalPages,
        ...(debouncedSearchQuery && { name: debouncedSearchQuery }),
      };
      const newPosts = await getListSnapshots(listSnapshotParams, user);
      if (newPosts.data.snapshots.length > 0) {
        setSnapshotArr(prevPosts =>
          currentPage === 1
            ? newPosts.data.snapshots
            : [...prevPosts, ...newPosts.data.snapshots]
        );
        setPage(currentPage);
      }
    } catch (error) {
      console.error("Error fetching posts:", error);
    } finally {
      loadingRef.current = false;
    }
  };
  const onScrollItems = throttle(() => {
    if (scrollRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;

      const isNearBottom = scrollHeight - clientHeight - scrollTop < 200;

      if (isNearBottom && page < totalPages) {
        loadMorePosts(page + 1);
      }
    }
  }, 1500);

  useEffect(() => {
    const container = scrollRef.current;
    if (container) {
      container.addEventListener("scroll", onScrollItems);
    }

    return () => {
      if (container) {
        container.removeEventListener("scroll", onScrollItems);
      }
    };
  }, [page, totalPages, snapshotArr]);
  useEffect(() => {
    getUserByEmail();
  }, []);

  useEffect(() => {
    const initializePosts = async () => {
      setSnapshotArr([]);
      setLoading(true);
      setPage(1);
      try {
        const listSnapshotParams = {
          userId,
          page: 1,
          pageSize: totalPages,
          ...(debouncedSearchQuery && { name: debouncedSearchQuery }),
        };
        const initialPosts = await getListSnapshots(listSnapshotParams, user);
        setSnapshotArr(initialPosts.data.snapshots);
      } catch (error) {
        toast.error("Error fetching posts");
        console.error("Error fetching posts:", error);
      }
      setLoading(false);
    };
    if (userId) initializePosts();
  }, [userId, debouncedSearchQuery]);

  return (
    <div
      className={`flex flex-col  ${
        !isSidebarOpen ? "w-[calc(100vw-265px)]" : "w-[calc(100vw-105px)]"
      } fixed ml-1 transition-all duration-500 ease-in-out`}
    >
      <div className="flex flex-col md:flex-row justify-around items-center mb-2">
        <div className="flex bg-white w-full items-center border border-black text-center justify-center rounded-lg p-1 mx-1">
          <Search className="w-6 h-6 ml-3 mr-1 " />
          <input
            type="text"
            placeholder="Search"
            className="w-full pl-3 text-md outline-none"
            value={searchQuery}
            onChange={e => setSearchQuery(e.target.value)}
          />
        </div>
      </div>
      <div
        className="m-1 p-6 gap-6 flex flex-wrap overflow-y-auto max-h-[calc(100vh-130px)] "
        ref={scrollRef}
      >
        {loading ? (
          <SnapshotCardSkeleton count={24} />
        ) : snapshotArr?.length > 0 ? (
          snapshotArr.map(data => (
            <SnapshotCard
              key={data.id}
              data={data}
              getImageLink={getImageLink}
            />
          ))
        ) : (
          <div className="w-full text-center text-gray-500">
            No snapshots available.
          </div>
        )}
      </div>
    </div>
  );
};

export default TrendSnapshot;
