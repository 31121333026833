import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useRef,
  useState,
} from "react";
import { ReactComponent as HeartIcon } from "../../assets/icons/heart.svg";
import { ReactComponent as CopyToClipboard } from "../../assets/icons/copyToClipboard.svg";
import { ReactComponent as BlackHeartIcon } from "../../assets/icons/heartWithBlack.svg";

import { toast } from "react-toastify";
import { copyToClipboard } from "./utils";
import NewsModal from "../../components/Feed/modal";
import SaveToBoard from "../../components/Feed/saveToBoard";
import { NewsItem } from "../../interface/NewsInterface";
import { deleteContentById } from "../../app/api/admin/content";
import { formatDate } from "../../utils";
import { AxiosError } from "axios";
import NewsImage from "../../components/Feed/NewsImage";
import { Board } from "../../interface/BoardsInterface";
import { Content } from "../../interface/ContentInterface";
import { SelectedPost } from "../../interface/DesignerInterface";
import Modal from "../../components/Common/Modal";
import DeleteModal from "../../components/Boards/DeleteModal";

type Props = {
  url: string;
  image: string;
  description: string;
  provider?: string[];
  title: string;
  source: string;
  isSaveToBoardOpen: boolean;
  setSaveToBoardOpen: Dispatch<SetStateAction<boolean>>;
  closeSaveToBoard: () => void;
  fromBoard?: boolean;
  id?: string;
  boardId?: string;
  getBoardData?: (option: string) => void;
  closeDailyExpandView?: () => void;
  date?: string;
  notes?: string;
  userId?: string;
  publicBoards?: Board[];
  setPublicBoards?: React.Dispatch<React.SetStateAction<Board[]>>;
  privateBoards?: Board[];
  setPrivateBoards?: React.Dispatch<React.SetStateAction<Board[]>>;
  dailyNewsId?: string;
  userBoardAccessLevelIds?: string[];
  activeTabId?: string;
  selectedPost?: SelectedPost;
  selectedNews?: Content;
  contentId?: string;
  setContentData?: React.Dispatch<React.SetStateAction<Content[]>>;
};

const DailyExpandView: React.FC<Props> = ({
  url,
  image,
  description,
  provider,
  title,
  source,
  isSaveToBoardOpen,
  setSaveToBoardOpen,
  closeSaveToBoard,
  fromBoard,
  id,
  boardId,
  getBoardData,
  closeDailyExpandView,
  date,
  notes,
  userId,
  publicBoards,
  setPublicBoards,
  privateBoards,
  setPrivateBoards,
  dailyNewsId,
  activeTabId,
  userBoardAccessLevelIds,
  contentId,
  setContentData,
}) => {
  const [removeContent, setRemoveContent] = useState(false);

  const closeRemoveModal = () => setRemoveContent(false);
  //Function to copy text to clipboard
  const handleCopyToClipboard = async () => {
    const response = await copyToClipboard({ title, description, url });
    if (response == "success") {
      toast.success("Text Copied", {
        hideProgressBar: true,
      });
    } else if (response == "error") {
      toast.error("Error Copying Text", {
        hideProgressBar: true,
      });
    }
  };

  const selectedNews: NewsItem = {
    url,
    image,
    description,
    title,
    source,
    _id: dailyNewsId,
  };

  const handleRemoveContent = async () => {
    if (activeTabId) {
      if (userBoardAccessLevelIds?.includes(activeTabId || "")) {
        await setRemoveContent(true);
      } else {
        toast.error("You don't have edit access", {
          hideProgressBar: true,
        });
      }
    }
  };

  return (
    <div className="border border-black p-3 rounded-md font-poppins relative">
      <div className="flex mb-2 gap-4 relative">
        <NewsImage
          source={source}
          url={url}
          classProps="w-[100px] h-[100px] object-fill rounded-lg"
        />
        <div className="flex flex-col">
          <div className="text-sm  ml-1">
            {source === "Bing News"
              ? provider && provider.length > 0
                ? provider[0]
                : "Bing News"
              : source}
          </div>
          <div className="text-sm font-bold ml-1">{formatDate(date)}</div>

          <h3 className="font-bold text-base">
            <a href={url} target="_blank">
              <p className="line-clamp-4 underline"> {title}</p>
            </a>
          </h3>
        </div>
      </div>

      <div className="text-sm min-h-[100px] max-h-[285px] scroller sc overflow-y-scroll">
        {description}
      </div>
      {fromBoard && (
        <>
          <div className="text-primary text-sm font-bold mb-2">Notes Added</div>

          <div className="text-xs mb-1 text-primary">
            {notes ? notes : "No Notes added"}
          </div>
        </>
      )}
      <div className="flex space-x-2 items-center absolute right-7 bottom-3">
        {fromBoard ? (
          <div
            className="bg-white flex w-5 h-5 items-center justify-center shadow-md rounded-full cursor-pointer"
            onClick={() => handleRemoveContent()}
          >
            <BlackHeartIcon />
          </div>
        ) : (
          <div
            className="bg-white flex w-5 h-5 items-center justify-center shadow-md rounded-full cursor-pointer"
            onClick={() => setSaveToBoardOpen(true)}
          >
            <HeartIcon />
          </div>
        )}

        <div
          className="bg-white flex w-5 h-5 items-center justify-center shadow-md rounded-full cursor-pointer "
          onClick={handleCopyToClipboard}
        >
          <CopyToClipboard />
        </div>
      </div>
      <NewsModal
        isOpen={isSaveToBoardOpen}
        onClose={closeSaveToBoard}
        title="Save to Board"
      >
        {
          <SaveToBoard
            selectedNews={selectedNews}
            onClose={closeSaveToBoard}
            userId={userId}
            publicBoards={publicBoards || []}
            setPublicBoards={setPublicBoards}
            privateBoards={privateBoards || []}
            setPrivateBoards={setPrivateBoards}
          />
        }
      </NewsModal>

      {removeContent && (
        <Modal isOpen={removeContent} onClose={closeRemoveModal} title="">
          <DeleteModal
            onClose={closeRemoveModal}
            contentId={contentId as string}
            closeExpandView={closeDailyExpandView}
            fromExpandedView={true}
            boardId={boardId}
            setContentData={setContentData}
          />
        </Modal>
      )}
    </div>
  );
};

export default DailyExpandView;
