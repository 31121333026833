import { User } from "../../types";
import { admin, forecaster } from "./axios";

export type GetTrendsParams = {
  shapes?: boolean;
  sleeves?: boolean;
  necklines_and_collars?: boolean;
  hem_types?: boolean;
  length?: boolean;
  pockets?: boolean;
  cuffs?: boolean;
  details?: boolean;
  placket?: boolean;
  waistband?: boolean;
  fabric?: boolean;
  pattern?: boolean;
  embroidery?: boolean;
  category?: string;
  gender?: string;
  source?: string;
  genre?: string;
  region?: string;
  apparel_type?: string;
  colors?: string;
  start_date?: string;
  end_date?: string;
  req_id?: string;
  posts_per_trend?: number;
  page?: number;
  pageSize?: number;
  brands?: string;
  product?: string;
  age_group?: string;
};
export const getTrends = async (params: GetTrendsParams, user: User | null) => {
  if (user === null) {
    throw new Error("User is not available");
  }

  const { group } = user!;
  if (!group) {
    console.error("Group Not Found");
    return;
  }

  try {
    const apiClient =
      group === "admin" ? admin : group === "forecaster" ? forecaster : null;
    if (apiClient) {
      const response = await apiClient.get("/trends", {
        params,
      });
      return response.data;
    } else {
      console.error("Group not found");
    }
  } catch (error) {
    console.error("Error fetching trends:", error);
    throw error;
  }
};

type GetPaginatedPostParams = {
  trend_name?: string;
  page: number;
  pageSize: number;
  req_id: string;
};

export const getPaginatedTrendsPost = async (
  params: GetPaginatedPostParams,
  user: User | null
) => {
  if (user === null) {
    throw new Error("User is not available");
  }

  const { group } = user!;
  if (!group) {
    console.error("Group Not Found");
    return;
  }

  try {
    const apiClient =
      group === "admin" ? admin : group === "forecaster" ? forecaster : null;
    if (apiClient) {
      const response = await apiClient.get("/trends/posts", {
        params,
      });
      return response.data;
    } else {
      console.error("Group not found");
    }
  } catch (error) {
    console.error("Error fetching trends:", error);
    throw error;
  }
};
