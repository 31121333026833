import { admin, forecaster } from "./axios";
import { User } from "../../types";
import { AxiosError } from "axios";

interface saveFilterInterface {
  Genre: string;
  Product: string;
  Gender: string;
  Region: string;
  Brand: string[];
  StartDate: string;
  EndDate: string;
  Source: string;
  Category: string;
  Color: string;
  AgeGroup: string;
  FilterName: string;
}
export const getFiltersByUserId = async (
  userId: string | undefined,
  user: User | null
) => {
  if (user === null) {
    throw new Error("User is not available");
  }
  const { group } = user!;
  if (group) {
    try {
      const queryParam = `?userId=${userId}`;
      if (group === "admin") {
        const response = await admin.get(`designer-focus/filters${queryParam}`);
        return response.data;
      } else if (group === "forecaster") {
        const response = await forecaster.get(
          `designer-focus/filters${queryParam}`
        );
        return response.data;
      }
    } catch (error) {
      console.error("Error generating content:", error);
      if ((error as AxiosError)?.response?.status !== 400) {
        //Then only show toast error message
        throw error;
      }
    }
  } else {
    console.error("Group Not Found");
  }
};

export const getFilterById = async (filterId: string, user: User | null) => {
  if (user === null) {
    throw new Error("User is not available");
  }
  const { group } = user!;
  if (group) {
    try {
      if (group === "admin") {
        const response = await admin.get(`designer-focus/filters/${filterId}`);
        return response.data.data;
      } else if (group === "forecaster") {
        const response = await forecaster.get(
          `designer-focus/filters/${filterId}`
        );
        return response.data.data;
      }
    } catch (error) {
      console.error("Error generating content:", error);
      if ((error as AxiosError)?.response?.status !== 400) {
        throw error;
      }
    }
  } else {
    console.error("Group Not Found");
  }
};

export const saveFilter = async (
  payload: saveFilterInterface,
  user: User | null,
  userId: string | undefined
) => {
  if (user === null) {
    throw new Error("User is not available");
  }
  const { group } = user!;
  if (group) {
    try {
      if (group === "admin") {
        const response = await admin.post(
          `designer-focus/filters?userId=${userId}`,
          payload
        );
        return response.data;
      } else if (group === "forecaster") {
        const response = await forecaster.post(
          `designer-focus/filters?userId=${userId}`,
          payload
        );
        return response.data;
      } else {
        console.error("Group Not Found");
      }
    } catch (error) {
      console.error("Error generating content:", error);
      if ((error as AxiosError)?.response?.status !== 400) {
        throw error;
      }
    }
  }
};

export const DeleteFilter = async (
  filterId: string,
  userId: string | undefined,
  user: User | null
) => {
  if (user === null) {
    throw new Error("User is not available");
  }
  const { group } = user!;
  if (group) {
    try {
      const queryParam = `?filterId=${filterId}&userId=${userId}`;
      if (group === "admin") {
        const response = await admin.delete(
          `/designer-focus/filters${queryParam}`
        );
        return response.data;
      } else if (group === "forecaster") {
        const response = await forecaster.delete(
          `/designer-focus/filters${queryParam}`
        );
        return response.data;
      }
    } catch (error) {
      console.error("Error generating content:", error);
      if ((error as AxiosError)?.response?.status !== 400) {
        throw error;
      }
    }
  }
};

export const updateFilter = async (
  filterId: string,
  payload: saveFilterInterface,
  user: User | null,
  userId: string | undefined
) => {
  if (user === null) {
    throw new Error("User is not available");
  }
  const { group } = user!;
  if (group) {
    try {
      const queryParam = `?filterId=${filterId}&userId=${userId}`;
      if (group === "admin") {
        const response = await admin.put(
          `/designer-focus/filters${queryParam}`,
          payload
        );
        return response.data;
      } else if (group === "forecaster") {
        const response = await forecaster.put(
          `/designer-focus/filters${queryParam}`,
          payload
        );
        return response.data;
      }
    } catch (error) {
      console.error("Error generating content:", error);
      if ((error as AxiosError)?.response?.status !== 400) {
        //Then only show toast error message
        throw error;
      }
    }
  }
};
