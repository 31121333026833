import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { ReactComponent as ICHLogo } from "../../assets/icons/ichLogo.svg";
import { ReactComponent as ICHNextLogo } from "../../assets/icons/ichNextLogo.svg";
import { ReactComponent as ArrowIcon } from "../../assets/icons/arrow.svg";
import { ReactComponent as TargetIcon } from "../../assets/icons/target.svg";
import { ReactComponent as IndustryIcon } from "../../assets/icons/industry.svg";
import { ReactComponent as DailyViralFeedIcon } from "../../assets/icons/dailyViralFeed.svg";
import { ReactComponent as InnovationFeedIcon } from "../../assets/icons/innovationFeed.svg";
import { ReactComponent as SentimentAnalyserIcon } from "../../assets/icons/sentimentAnalyser.svg";
import { ReactComponent as ContentGeneratorIcon } from "../../assets/icons/ContentGenerator.svg";
import { User } from "../../types";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import ContentIcon from "../../assets/icons/ContentIcon";
import InviteIcon from "../../assets/icons/InviteIcon";
import FeedIcon from "../../assets/icons/FeedIcon";
import DesignerPageIcon from "../../assets/icons/DesignerPageIcon";
import GenerativeIcon from "../../assets/icons/GenerativeIcon";
import BoardsIcon from "../../assets/icons/BoardsIcon";
import CollapseIcon from "../../assets/icons/CollapseIcon";
import TicketIcon from "../../assets/icons/TicketIcon";
import NewsFeedIcon from "../../assets/icons/NewsFeedIcon";
import TrendSnapshotIcon from "../../assets/icons/TrendSnapshotIcon";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
const Sidebar = ({
  isCollapsed,
  onToggle,
  isSidebarOpen,
  setIsSidebarOpen,
}: {
  isCollapsed: boolean;
  onToggle: () => void;
  isSidebarOpen: boolean;
  setIsSidebarOpen: (isOpen: boolean) => void;
}) => {
  const [isContentOpen, setContentOpen] = useState(false);
  const [isFeatureTrackingOpen, setFeatureTrackingOpen] = useState(false);
  const [isFeedOpen, setFeedOpen] = useState(false);
  const [isGenerativeFeatureOpen, setGenerativeFeatureOpen] = useState(false);
  const location = useLocation();
  const user = useSelector((state: RootState) => state.user.userInfo as User);
  const { group } = user || {};

  const handleToggle = (section: string) => {
    setContentOpen(section === "content" ? !isContentOpen : false);
    setFeatureTrackingOpen(
      section === "featureTracking" ? !isFeatureTrackingOpen : false
    );
    setFeedOpen(section === "feed" ? !isFeedOpen : false);
    setGenerativeFeatureOpen(
      section === "generativeFeature" ? !isGenerativeFeatureOpen : false
    );
  };

  const getLinkClass = (path: string) => {
    return location.pathname.startsWith(path)
      ? "bg-primary text-white font-normal"
      : "text-secondary hover:bg-gray";
  };

  const getLinkClassBoards = (path: string) => {
    return location.pathname === path
      ? "bg-primary text-white font-normal"
      : "text-secondary hover:bg-gray hover:text-secondary";
  };

  const isParentActive = (parentPath: string) => {
    return location.pathname.startsWith(parentPath)
      ? "bg-primary text-white font-normal"
      : "text-secondary hover:bg-gray";
  };
  const getSvgColor = (parentPath: string) => {
    return location?.pathname?.startsWith(parentPath) ? "#ffffff" : "#353E43";
  };
  const getSvgColorNews = (parentPath: string) => {
    return location?.pathname?.startsWith(parentPath) ? "#000000" : "#4d4d4d";
  };
  const getSvgColorForBoard = (parentPath: string) => {
    if (location?.pathname?.startsWith(parentPath)) {
      if (`${location?.pathname?.startsWith(parentPath + "/")}`) {
        return "#ffffff";
      } else {
        return "#353E43";
      }
    } else {
      return "#353E43";
    }
  };

  const isChildActive = (childPath: string) => {
    return location.pathname === childPath
      ? "bg-active text-black font-normal"
      : "text-secondary hover:bg-gray";
  };

  const getSvgClass = (path: string) => {
    return location.pathname === path ? "text-white" : "text-tertiary";
  };

  const getParentSvgClass = (parentPath: string) => {
    return location.pathname.startsWith(parentPath)
      ? "text-white"
      : "text-tertiary";
  };

  const getChildSvgClass = (childPath: string) => {
    return location.pathname === childPath ? "text-primary" : "text-tertiary";
  };

  const getArrowClass = (isOpen: boolean, parentPath: string) => {
    return `${isOpen ? "rotate-180" : ""} ${
      location.pathname.startsWith(parentPath) ? "text-white" : "text-black"
    }`;
  };

  return (
    <div
      className={`h-screen bg-white shadow-md transition-all px-3 duration-500 ease-in-out ${
        isCollapsed ? "w-24" : "w-64"
      }`}
      style={{
        userSelect: "none",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        overflow: "hidden",
      }}
    >
      <div>
        <div className="p-2 mt-6 flex justify-center">
          {isCollapsed ? <ICHLogo /> : <ICHNextLogo />}
        </div>
        <nav className={`mt-4 ${isCollapsed ? "px-2" : ""}`}>
          <ul>
            {group === "admin" && (
              <>
                <li
                  className={`flex items-center py-4 px-2 cursor-pointer rounded-2xl mb-2 ${isParentActive(
                    "/content"
                  )}`}
                  onClick={() => handleToggle("content")}
                >
                  {isCollapsed ? (
                    <Tippy content="Content" placement="right">
                      <div
                        className={`flex justify-center items-center w-12 ${
                          isCollapsed ? "" : ""
                        } ${getParentSvgClass("/content")}`}
                      >
                        <ContentIcon fill={`${getSvgColor("/content")}`} />
                      </div>
                    </Tippy>
                  ) : (
                    <div
                      className={`flex justify-center items-center w-12 ${
                        isCollapsed ? "" : ""
                      } ${getParentSvgClass("/content")}`}
                    >
                      <ContentIcon fill={`${getSvgColor("/content")}`} />
                    </div>
                  )}

                  <span
                    className={`transition-all duration-500 ease-in-out ${
                      isCollapsed
                        ? "opacity-0 max-w-0"
                        : "opacity-100 max-w-full"
                    }`}
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      marginLeft: "4px",
                    }}
                  >
                    Content
                  </span>
                  <ArrowIcon
                    className={`ml-auto transform transition-transform duration-500 ease-in-out ${
                      isCollapsed ? "hidden" : "block"
                    } ${getArrowClass(isContentOpen, "/content")}`}
                  />
                </li>
                <div
                  className={`transition-max-height duration-500 ease-in-out ${
                    isContentOpen ? "max-h-40" : "max-h-0"
                  } overflow-hidden`}
                >
                  <ul className="mb-4">
                    <li>
                      <Link
                        to="/content/target"
                        className={`flex items-center px-2 py-2 rounded-xl mb-2 ${isChildActive(
                          "/content/target"
                        )}`}
                      >
                        {isCollapsed ? (
                          <Tippy content="Sources" placement="right">
                            <div
                              className={`flex justify-center items-center w-12 ${
                                isCollapsed ? "" : "mr-1"
                              } ${getChildSvgClass("/content/target")}`}
                            >
                              <TargetIcon style={{ width: "24px" }} />
                            </div>
                          </Tippy>
                        ) : (
                          <div
                            className={`flex justify-center items-center w-12 ${
                              isCollapsed ? "" : "mr-1"
                            } ${getChildSvgClass("/content/target")}`}
                          >
                            <TargetIcon style={{ width: "24px" }} />
                          </div>
                        )}

                        <span
                          className={`transition-all duration-500 ease-in-out ${
                            isCollapsed
                              ? "opacity-0 max-w-0"
                              : "opacity-100 max-w-full"
                          }`}
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                          }}
                        >
                          Sources
                        </span>
                      </Link>
                    </li>
                    {/* <li>
                      <Link
                        to="/content/industries"
                        className={`flex items-center px-2 py-2 rounded-xl mb-2 ${isChildActive(
                          "/content/industries"
                        )}`}
                      >
                        <div
                          className={`flex justify-center items-center w-12 ${
                            isCollapsed ? "" : "mr-1"
                          } ${getChildSvgClass("/content/industries")}`}
                        >
                          <IndustryIcon style={{ width: "24px" }} />
                        </div>
                        <span
                          className={`transition-all duration-500 ease-in-out ${
                            isCollapsed
                              ? "opacity-0 max-w-0"
                              : "opacity-100 max-w-full"
                          }`}
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                          }}
                        >
                          Industries
                        </span>
                      </Link>
                    </li> */}
                  </ul>
                </div>
              </>
            )}

            {group === "admin" && (
              <li>
                <Link
                  to="/invite"
                  className={`flex items-center py-4 px-2 cursor-pointer rounded-2xl mb-2 ${getLinkClass(
                    "/invite"
                  )}`}
                >
                  {isCollapsed ? (
                    <Tippy content="Invite" placement="right">
                      <div
                        className={`flex justify-center items-center w-12 ${
                          isCollapsed ? "" : "mr-1"
                        } ${getSvgClass("/invite")}`}
                      >
                        <InviteIcon fill={`${getSvgColor("/invite")}`} />
                      </div>
                    </Tippy>
                  ) : (
                    <div
                      className={`flex justify-center items-center w-12 ${
                        isCollapsed ? "" : "mr-1"
                      } ${getSvgClass("/invite")}`}
                    >
                      <InviteIcon fill={`${getSvgColor("/invite")}`} />
                    </div>
                  )}

                  <span
                    className={`transition-all duration-500 ease-in-out ${
                      isCollapsed
                        ? "opacity-0 max-w-0"
                        : "opacity-100 max-w-full"
                    }`}
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                    }}
                  >
                    Invite
                  </span>
                </Link>
              </li>
            )}
            {group === "admin" && (
              <li>
                <Link
                  to="/tickets"
                  className={`flex items-center py-4 px-2 cursor-pointer rounded-2xl mb-2 ${isParentActive(
                    "/tickets"
                  )}`}
                >
                  {isCollapsed ? (
                    <Tippy content="Tickets" placement="right">
                      <div
                        className={`flex justify-center items-center w-12 ${
                          isCollapsed ? "" : "mr-1"
                        } ${getParentSvgClass("/tickets")}`}
                      >
                        <TicketIcon fill={`${getSvgColor("/tickets")}`} />
                      </div>
                    </Tippy>
                  ) : (
                    <div
                      className={`flex justify-center items-center w-12 ${
                        isCollapsed ? "" : "mr-1"
                      } ${getParentSvgClass("/tickets")}`}
                    >
                      <TicketIcon fill={`${getSvgColor("/tickets")}`} />
                    </div>
                  )}

                  <span
                    className={`transition-all duration-500 ease-in-out ${
                      isCollapsed
                        ? "opacity-0 max-w-0"
                        : "opacity-100 max-w-full"
                    }`}
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                    }}
                  >
                    Tickets
                  </span>
                </Link>
              </li>
            )}
            <li
              className={`flex items-center  py-4 px-2 cursor-pointer rounded-2xl mb-2 ${isParentActive(
                "/feed"
              )}`}
              onClick={() => handleToggle("feed")}
            >
              {isCollapsed ? (
                <Tippy content="Feed" placement="right">
                  <div
                    className={`flex justify-center items-center w-12 ${
                      isCollapsed ? "" : "mr-1"
                    } ${getParentSvgClass("/feed")}`}
                  >
                    <FeedIcon fill={`${getSvgColor("/feed")}`} />
                  </div>
                </Tippy>
              ) : (
                <div
                  className={`flex justify-center items-center w-12 ${
                    isCollapsed ? "" : "mr-1"
                  } ${getParentSvgClass("/feed")}`}
                >
                  <FeedIcon fill={`${getSvgColor("/feed")}`} />
                </div>
              )}

              <span
                className={`transition-all duration-500 ease-in-out ${
                  isCollapsed ? "opacity-0 max-w-0" : "opacity-100 max-w-full"
                }`}
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }}
              >
                Feed
              </span>
              <ArrowIcon
                className={`ml-auto transform transition-transform duration-500 ease-in-out ${
                  isCollapsed ? "hidden" : "block"
                } ${getArrowClass(isFeedOpen, "/feed")}`}
              />
            </li>
            <div
              className={`transition-max-height duration-500 ease-in-out ${
                isFeedOpen ? "max-h-40" : "max-h-0"
              } overflow-hidden`}
            >
              <ul className="mb-4">
                <li>
                  <Link
                    to="/feed/daily-viral-feed"
                    className={`flex items-center px-2 py-2 rounded-xl mb-2 ${isChildActive(
                      "/feed/daily-viral-feed"
                    )}`}
                  >
                    {isCollapsed ? (
                      <Tippy content="News Feed" placement="right">
                        <div
                          className={`flex justify-center items-center w-12 ${
                            isCollapsed ? "" : "mr-1"
                          } ${getChildSvgClass("/feed/daily-viral-feed")}`}
                        >
                          <NewsFeedIcon
                            fill={`${getSvgColorNews("/feed/daily-viral-feed")}`}
                          />
                        </div>
                      </Tippy>
                    ) : (
                      <div
                        className={`flex justify-center items-center w-12 ${
                          isCollapsed ? "" : "mr-1"
                        } ${getChildSvgClass("/feed/daily-viral-feed")}`}
                      >
                        <NewsFeedIcon
                          fill={`${getSvgColorNews("/feed/daily-viral-feed")}`}
                        />
                      </div>
                    )}

                    <span
                      className={`transition-all duration-500 ease-in-out ${
                        isCollapsed
                          ? "opacity-0 max-w-0"
                          : "opacity-100 max-w-full"
                      }`}
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                      }}
                    >
                      News Feed
                    </span>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/feed/innovation-feed"
                    className={`flex items-center px-2 py-2 rounded-xl mb-2 ${isChildActive(
                      "/feed/innovation-feed"
                    )}`}
                  >
                    {isCollapsed ? (
                      <Tippy content="Innovation Feed" placement="right">
                        <div
                          className={`flex justify-center items-center w-12 ${
                            isCollapsed ? "" : "mr-1"
                          } ${getChildSvgClass("/feed/innovation-feed")}`}
                        >
                          <InnovationFeedIcon style={{ width: "24px" }} />
                        </div>
                      </Tippy>
                    ) : (
                      <div
                        className={`flex justify-center items-center w-12 ${
                          isCollapsed ? "" : "mr-1"
                        } ${getChildSvgClass("/feed/innovation-feed")}`}
                      >
                        <InnovationFeedIcon style={{ width: "24px" }} />
                      </div>
                    )}

                    <span
                      className={`transition-all duration-500 ease-in-out ${
                        isCollapsed
                          ? "opacity-0 max-w-0"
                          : "opacity-100 max-w-full"
                      }`}
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                      }}
                    >
                      Innovation Feed
                    </span>
                  </Link>
                </li>
              </ul>
            </div>
            <li>
              <Link
                to="/designer-page"
                className={`flex items-center py-4 px-2 cursor-pointer rounded-2xl mb-2 ${getLinkClass(
                  "/designer-page"
                )}`}
              >
                {isCollapsed ? (
                  <Tippy content="Designer Page" placement="right">
                    <div
                      className={`flex justify-center items-center w-12 ${getSvgClass(
                        "/designer-page"
                      )}`}
                    >
                      <DesignerPageIcon
                        fill={`${getSvgColor("/designer-page")}`}
                      />
                    </div>
                  </Tippy>
                ) : (
                  <div
                    className={`flex justify-center items-center w-12 mr-1 ${getSvgClass(
                      "/designer-page"
                    )}`}
                  >
                    <DesignerPageIcon
                      fill={`${getSvgColor("/designer-page")}`}
                    />
                  </div>
                )}
                <span
                  className={`transition-all duration-500 ease-in-out ${
                    isCollapsed ? "opacity-0 max-w-0" : "opacity-100 max-w-full"
                  }`}
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                  }}
                >
                  Designer Page
                </span>
              </Link>
            </li>

            <li
              className={`flex items-center  py-4 px-2 cursor-pointer rounded-2xl mb-2 ${isParentActive(
                "/generative-feature"
              )}`}
              onClick={() => handleToggle("generativeFeature")}
            >
              {isCollapsed ? (
                <Tippy content="Generative Feature" placement="right">
                  <div
                    className={`flex justify-center items-center w-12 ${
                      isCollapsed ? "" : "mr-1"
                    } ${getParentSvgClass("/generative-feature")}`}
                  >
                    <GenerativeIcon
                      fill={`${getSvgColor("/generative-feature")}`}
                    />
                  </div>
                </Tippy>
              ) : (
                <div
                  className={`flex justify-center items-center w-12 ${
                    isCollapsed ? "" : "mr-1"
                  } ${getParentSvgClass("/generative-feature")}`}
                >
                  <GenerativeIcon
                    fill={`${getSvgColor("/generative-feature")}`}
                  />
                </div>
              )}

              <span
                className={`transition-all duration-500 ease-in-out ${
                  isCollapsed ? "opacity-0 max-w-0" : "opacity-100 max-w-full"
                }`}
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }}
              >
                Generative Feature
              </span>
              <ArrowIcon
                className={`ml-auto transform transition-transform duration-500 ease-in-out ${
                  isCollapsed ? "hidden" : "block"
                } ${getArrowClass(
                  isGenerativeFeatureOpen,
                  "/generative-feature"
                )}`}
              />
            </li>
            <div
              className={`transition-max-height duration-500 ease-in-out ${
                isGenerativeFeatureOpen ? "max-h-40" : "max-h-0"
              } overflow-hidden`}
            >
              <ul className="mb-4">
                <li>
                  <Link
                    to="/generative-feature/sentiment-analyser"
                    className={`flex items-center px-2 py-2 rounded-xl mb-2 ${isChildActive(
                      "/generative-feature/sentiment-analyser"
                    )}`}
                  >
                    {isCollapsed ? (
                      <Tippy content="Sentiment Analyser" placement="right">
                        <div
                          className={`flex justify-center items-center w-12 ${
                            isCollapsed ? "" : "mr-1"
                          } ${getChildSvgClass(
                            "/generative-feature/sentiment-analyser"
                          )}`}
                        >
                          <ContentGeneratorIcon style={{ width: "24px" }} />
                        </div>
                      </Tippy>
                    ) : (
                      <div
                        className={`flex justify-center items-center w-12 ${
                          isCollapsed ? "" : "mr-1"
                        } ${getChildSvgClass(
                          "/generative-feature/sentiment-analyser"
                        )}`}
                      >
                        <ContentGeneratorIcon style={{ width: "24px" }} />
                      </div>
                    )}

                    <span
                      className={`transition-all duration-500 ease-in-out ${
                        isCollapsed
                          ? "opacity-0 max-w-0"
                          : "opacity-100 max-w-full"
                      }`}
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                      }}
                    >
                      Sentiment Analyser
                    </span>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/generative-feature/content-generator"
                    className={`flex items-center px-2 py-2 rounded-xl mb-2 ${isChildActive(
                      "/generative-feature/content-generator"
                    )}`}
                  >
                    {isCollapsed ? (
                      <Tippy content="Text Generator" placement="right">
                        <div
                          className={`flex justify-center items-center w-12 ${
                            isCollapsed ? "" : "mr-1"
                          } ${getChildSvgClass(
                            "/generative-feature/content-generator"
                          )}`}
                        >
                          <SentimentAnalyserIcon style={{ width: "24px" }} />
                        </div>
                      </Tippy>
                    ) : (
                      <div
                        className={`flex justify-center items-center w-12 ${
                          isCollapsed ? "" : "mr-1"
                        } ${getChildSvgClass(
                          "/generative-feature/content-generator"
                        )}`}
                      >
                        <SentimentAnalyserIcon style={{ width: "24px" }} />
                      </div>
                    )}

                    <span
                      className={`transition-all duration-500 ease-in-out ${
                        isCollapsed
                          ? "opacity-0 max-w-0"
                          : "opacity-100 max-w-full"
                      }`}
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                      }}
                    >
                      Text Generator
                    </span>
                  </Link>
                </li>
              </ul>
            </div>
            <li>
              <Link
                to="/boards"
                className={`flex items-center  py-4 px-2 cursor-pointer rounded-2xl mb-2 ${isParentActive(
                  "/boards"
                )} ${getLinkClassBoards("/boards")}`}
              >
                {isCollapsed ? (
                  <Tippy content="Boards" placement="right">
                    <div
                      className={`flex justify-center items-center w-12 ${
                        isCollapsed ? "" : "mr-1"
                      } ${getSvgClass("/boards")}`}
                    >
                      <BoardsIcon fill={`${getSvgColorForBoard("/boards")}`} />
                    </div>
                  </Tippy>
                ) : (
                  <div
                    className={`flex justify-center items-center w-12 ${
                      isCollapsed ? "" : "mr-1"
                    } ${getSvgClass("/boards")}`}
                  >
                    <BoardsIcon fill={`${getSvgColorForBoard("/boards")}`} />
                  </div>
                )}

                <span
                  className={`transition-all duration-500 ease-in-out ${
                    isCollapsed ? "opacity-0 max-w-0" : "opacity-100 max-w-full"
                  }`}
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                  }}
                >
                  Boards
                </span>
              </Link>
            </li>
            <li>
              <Link
                to="/trend-snapshot"
                className={`flex items-center  py-4 px-2 cursor-pointer rounded-2xl mb-2 ${isParentActive(
                  "/trend-snapshot"
                )} ${getLinkClassBoards("/trend-snapshot")}`}
              >
                {isCollapsed ? (
                  <Tippy content="Trend Snapshot" placement="right">
                    <div
                      className={`flex justify-center items-center w-12 ${
                        isCollapsed ? "" : "mr-1"
                      } ${getSvgClass("/trend-snapshot")}`}
                    >
                      <TrendSnapshotIcon
                        fill={`${getSvgColorForBoard("/trend-snapshot")}`}
                      />
                    </div>
                  </Tippy>
                ) : (
                  <div
                    className={`flex justify-center items-center w-12 ${
                      isCollapsed ? "" : "mr-1"
                    } ${getSvgClass("/trend-snapshot")}`}
                  >
                    <TrendSnapshotIcon
                      fill={`${getSvgColorForBoard("/trend-snapshot")}`}
                    />
                  </div>
                )}

                <span
                  className={`transition-all duration-500 ease-in-out ${
                    isCollapsed ? "opacity-0 max-w-0" : "opacity-100 max-w-full"
                  }`}
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                  }}
                >
                  Trend Snapshot
                </span>
              </Link>
            </li>
          </ul>
        </nav>
      </div>
      <div className="px-1.5">
        <div
          className={`flex items-center text-secondary hover:bg-gray rounded-2xl mb-2 cursor-pointer ${
            isCollapsed
              ? "p-2 bg-primary hover:bg-primary justify-center"
              : "px-4 py-2"
          }`}
          onClick={onToggle}
        >
          <CollapseIcon fill={isCollapsed ? "#ffffff" : "#4d4d4d"} />
          <span
            className={`transition-all duration-500 ease-in-out ${
              isCollapsed ? "opacity-0 max-w-0" : "opacity-100 max-w-full ml-3"
            }`}
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              marginLeft: "16px",
            }}
          >
            Collapse
          </span>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
